import ternAppDetails from './utils/tern-app-details';

export default function LearnMoreTracking() {
  return (
    <>
      <h3>How does commute tracking work?</h3>
      <p>
        Tern works by using the sensors in your phone to automatically detect
        when you are cycling, walking or running.
      </p>

      <p>
        Combining activity recognition with location data means it can
        automatically detect when you actively travel in and out your selected
        office address.
      </p>

      <p>
        Unlike other health & fitness apps you don&apos;t need to start and stop
        the app every time you begin your commute, this would be a bit
        cumbersome for something we do so often.
      </p>

      <h4>Required permissions</h4>
      <p>For Tern to work it requires two permissions to be enabled.</p>
      <p>
        Please make sure you have set the <i>Location</i> permission to{' '}
        <i>Always</i>, and enabled{' '}
        <i>
          {ternAppDetails?.isIOS ? 'Motion & Fitness' : 'Physical activity'}
        </i>
        .
      </p>
      {ternAppDetails && ternAppDetails.isIOS ? (
        <p>
          To update your permissions go to the grey Settings app on your home
          screen, then scroll to Tern at the bottom.
        </p>
      ) : (
        <p>
          To update your permissions, find the Tern app on your home screen,
          press and hold the app icon then click <i>App info</i>.
        </p>
      )}
    </>
  );
}
