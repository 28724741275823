import { Link, useHistory } from 'react-router-dom';
import { round } from 'lodash';
import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import UserThumbnailImage from './UserThumbnailImage';
import styles from './UserRow.module.css';
import { CloudIcon, TreeIcon } from './Icons';
import ActionSheetContext from './context/action-sheet';
import { SORT_DISTANCE_VALUE } from './shared/sort';
import BottomSheetRouter from './BottomSheetRouter';
import useUser from './hooks/useUser';

export default function UserRow({ loading, to, user, position, sort }) {
  const loggedInUser = useUser();
  const setActionSheetContents = useContext(ActionSheetContext);
  const history = useHistory();
  if (loading) {
    return (
      <div className={styles.container}>
        <div className={styles.image}>
          <Skeleton circle height={40} width={40} />
        </div>
        <div className={styles.rightContent}>
          <Skeleton />
          <Skeleton width="200px" />
        </div>
      </div>
    );
  }

  const CO2 = user.commutes.commuteStats.totalCO2;
  const co2String = `${
    CO2 > 10 || CO2 === 0
      ? Math.round(CO2).toLocaleString()
      : round(CO2, 1).toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })
  }kg CO2`;

  const treesString =
    user.commutes.commuteStats.totalTrees &&
    `${Math.round(
      user.commutes.commuteStats.totalTrees
    ).toLocaleString()} trees`;
  const posString = `${position + 1}.`;
  const commuteString = `${user.commutes.commuteStats.totalCommutes.toLocaleString()} trip${
    user.commutes.commuteStats.totalCommutes === 1 ? '' : 's'
  }`;
  const distanceString = `${Math.round(
    user.commutes.commuteStats.totalDistance / 1000
  ).toLocaleString()}km`;

  const isDistanceSort = sort === SORT_DISTANCE_VALUE;

  return (
    <Link
      onClick={(event) => {
        const notInsideBottomSheet = !!setActionSheetContents;
        if (
          window.ReactNativeWebView &&
          notInsideBottomSheet &&
          user.id !== loggedInUser?.id
        ) {
          event.preventDefault();
          setActionSheetContents(
            <BottomSheetRouter
              setActionSheetContents={setActionSheetContents}
              to={to}
              history={history}
            />
          );
        }
      }}
      to={to}
      className={styles.container}
    >
      <UserThumbnailImage
        user={user}
        imageClass={styles.image}
        fallbackClass={styles.fallBackImage}
      />

      <div className={styles.rightContent}>
        <div className={styles.topRight}>
          <div>
            <span className={styles.position}>{posString}</span>
            <span className={styles.name}>{user.name}</span>
          </div>
          <div>
            <span>{isDistanceSort ? distanceString : commuteString}</span>
          </div>
        </div>
        <div className={styles.bottomRight}>
          <div>
            <span className={styles.meta}>
              <CloudIcon className={styles.cloudIcon} />
              <span>{co2String}</span>
            </span>
            {treesString ? (
              <span className={styles.meta}>
                <TreeIcon className={styles.treeIcon} />
                <span>{treesString}</span>
              </span>
            ) : null}
            <span className={styles.meta}>
              {user.commutes.lastCommuteTimeAgo}
            </span>
          </div>
          <div>
            <span>{isDistanceSort ? commuteString : distanceString}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}
