import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { getAuth } from 'firebase/auth';
import { useQuery, gql } from '@apollo/client';

import useIsSignedIn from './useIsSignedIn';

export const USER_QUERY = gql`
  query User($firebaseAuthUid: ID!) {
    user(firebaseAuthUid: $firebaseAuthUid) {
      id
      firstName
      lastName
      name
      privateRouteMaps
      photoURL
      monthlyTripsTarget
      stravaSignInUrl

      office {
        id
        address

        company {
          id
          name
          isPremium
        }
      }
    }
  }
`;

export default function useUser() {
  const isSignedInRaw = useIsSignedIn();

  // this check is because there can be race conditions on sign out in admin,
  // the above hook internal handler might not of fired yet but we're re-running this one
  // due to a component that also uses useIsSignedIn which triggers a re-render
  const isSignedIn = !!(isSignedInRaw && getAuth().currentUser);

  const { loading, error, data } = useQuery(USER_QUERY, {
    skip: !isSignedIn,
    variables: {
      firebaseAuthUid: isSignedIn ? getAuth().currentUser.uid : null,
    },
  });

  useEffect(() => {
    if (data?.user?.id) {
      mixpanel.identify(data.user.id);

      if (['1', '895'].includes(data.user.id)) {
        // to stop us screwing stats
        mixpanel.opt_out_tracking({ delete_user: false });
      }
    }
  }, [data]);

  if (!isSignedIn || loading || error) return null;

  // this can be null if the user does not exist
  return data.user;
}
