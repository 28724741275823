import { gql, useMutation } from '@apollo/client';
import AdminUploadImage from './AdminUploadImage';

export default function AdminCompanyLogo({ name, companyId }) {
  const [updateCompany] = useMutation(
    gql`
      mutation UpdateCompany($company: UpdateCompanyInput!) {
        updateCompany(company: $company) {
          id
        }
      }
    `
  );

  return (
    <>
      <AdminUploadImage
        storageLocation={`web/company_logo/${companyId}`}
        handleDownloadUrl={async (downloadURL) => {
          await updateCompany({
            variables: {
              company: {
                id: companyId,
                logoImageUrl: downloadURL,
              },
            },
          });
          window.location.reload();
        }}
        size={150}
      />
      {name && (
        <a
          target="_blank"
          href={`https://www.google.com/search?q=${name}+linkedin&tbm=isch`}
          rel="noreferrer"
          style={{ display: 'block' }}
        >
          Find an image
        </a>
      )}
    </>
  );
}
