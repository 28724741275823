import { useQuery, gql } from '@apollo/client';
import {
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import styles from './Pricing.module.css';

const LOCALSTORAGE_KEY = 'tern__chosen-currency';

export default function Footer() {
  const [currency, setCurrency] = useState(
    localStorage.getItem(LOCALSTORAGE_KEY) || null
  );

  const { loading, data } = useQuery(
    gql`
      query Prices {
        prices {
          currency
          prices
        }

        commuteStats {
          totalCompanies
        }
      }
    `,
    {
      onCompleted: (responseData) => {
        // first element will be the one
        // AWS Cloudfront country header guesses is where the client is
        if (!currency) setCurrency(responseData.prices[0].currency);
      },
    }
  );

  const abbrTitle =
    'An active user active commutes on Tern at least once a month and an active commute is at least 1km.';
  const activeCommuteTooltip = (
    <Tooltip title={abbrTitle} enterTouchDelay={0} leaveTouchDelay={5000}>
      <span className={styles.toolTip}>active users</span>
    </Tooltip>
  );

  useEffect(() => {
    if (currency) localStorage.setItem(LOCALSTORAGE_KEY, currency);
  }, [currency]);

  const price =
    currency && data
      ? data.prices.find(
          ({ currency: elementCurrency }) => elementCurrency === currency
        )
      : null;

  const createPrice = (cost, maximumFractionDigits = 0) => {
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: price.currency,
      maximumFractionDigits,
    }).format(cost);
  };

  const getStartedLink = (priceOption) => (
    <Link
      to="/admin/signup"
      onClick={() => {
        mixpanel.track('Admin Register Click', {
          source: 'Pricing Page',
          priceOption,
        });
      }}
    >
      Get started now!
    </Link>
  );

  return (
    <div>
      <h1>Enterprise pricing</h1>

      <h2>
        Join {loading ? '...' : data.commuteStats.totalCompanies} companies
        using the Tern app.
      </h2>

      <h3>
        Two simple plans, each with a <b>30-day free trial</b>. No credit card
        required.
      </h3>

      <div className={styles.tiersContainer}>
        <div className={styles.tiers}>
          <div className={styles.child}>
            <h3>Standard</h3>
            <b className={styles.price}>
              {!price ? '...' : createPrice(price.prices[0])} per year
            </b>
            <span className={styles.perUserCopy}>
              {`for `}
              <b>30</b> {activeCommuteTooltip},{' '}
              <b>{!price ? '...' : createPrice(price.prices[2], 2)}</b> per
              additional user per month.
            </span>
            <span className={styles.perOfficeCopy}>
              <b>{!price ? '...' : createPrice(price.prices[3])}</b> per
              additional office location
            </span>
            <ul className={styles.ul}>
              <li>
                Dashboard with real time insights into your employees
                sustainable commutes.
              </li>
              <li>Monthly reporting.</li>
              <li>Set-up and run challenges with rewards.</li>
              <li>Unlimited commutes.</li>
              <li>
                Marketing and creative material for internal launch
                communications.
              </li>
            </ul>
            {getStartedLink('Standard')}
          </div>
          <div className={styles.child}>
            <h3>Plus</h3>
            <b className={styles.price}>
              {!price ? '...' : createPrice(price.prices[1])} per year
            </b>
            <span className={styles.perUserCopy}>
              <b>unlimited </b>
              {activeCommuteTooltip} and office locations
            </span>
            <ul className={styles.ul}>
              <li>Includes standard features.</li>
              <li>Slack channel integration.</li>
              <li>Embeddable Tern widget for your website.</li>
            </ul>
            {getStartedLink('Plus')}
          </div>
        </div>
      </div>

      <div>
        {!loading && currency && (
          <FormControl
            focused={false}
            variant="filled"
            className={styles.formControl}
          >
            <InputLabel className={styles.white} htmlFor="currency-selector">
              Change currency
            </InputLabel>
            <Select
              classes={{
                root: [styles.selectRoot, styles.white],
                icon: styles.white,
              }}
              labelId="currency-selector"
              inputProps={{ id: 'currency-selector' }}
              id="currency-selector-id"
              value={currency}
              onChange={(event) => {
                setCurrency(event.target.value);
              }}
              autoWidth
            >
              {data.prices.map(({ currency: elementCurrency }) => (
                <MenuItem key={elementCurrency} value={elementCurrency}>
                  {elementCurrency}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    </div>
  );
}
