import { useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import useSearchFilters, { timeFrameOptions } from './hooks/useSearchFilters';
import { sortOptions } from './shared/sort';
import styles from './TimeframeAndSort.module.css';
import ActionSheetContext from './context/action-sheet';
import Info from './Info';

const ALL_COUNTRIES = 'all';

const DEFAULT_ALL_COUNTRIES = {
  shortName: ALL_COUNTRIES,
  longName: 'Worldwide',
};

const CACHED_COUNTRIES = [
  {
    longName: 'Australia',
    shortName: 'AU',
  },
  {
    longName: 'United Kingdom',
    shortName: 'GB',
  },
  {
    longName: 'New Zealand',
    shortName: 'NZ',
  },
  {
    longName: 'United States',
    shortName: 'US',
  },
  {
    longName: 'Russia',
    shortName: 'RU',
  },
  {
    longName: 'Latvia',
    shortName: 'LV',
  },
  {
    longName: 'Ireland',
    shortName: 'IE',
  },
  {
    longName: 'Czechia',
    shortName: 'CZ',
  },
  {
    longName: 'Denmark',
    shortName: 'DK',
  },
];

export default function TimeframeAndSort({ noSort, countries }) {
  const location = useLocation();
  const history = useHistory();
  const { sort, timeFrame, country } = useSearchFilters();
  const setActionSheetContents = useContext(ActionSheetContext);

  const newSearchParams = new URLSearchParams(location.search);
  if (sort !== sortOptions[0].value) {
    newSearchParams.delete('sort');
  } else {
    newSearchParams.set('sort', sortOptions[1].value);
  }

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div>
          <span className={styles.timeframe}>Timeframe:</span>
          <select
            className={styles.select}
            onChange={(event) => {
              const inputValue = event.target.value;
              const searchParams = new URLSearchParams(location.search);

              if (inputValue) {
                searchParams.set('timeFrame', inputValue);
              } else {
                searchParams.delete('timeFrame');
              }

              history.push(`${location.pathname}?${searchParams}`);

              const { label } = timeFrameOptions.find(
                ({ value }) => value === inputValue
              );

              mixpanel.track('Timeframe change', { timeframe: label });
            }}
            value={timeFrame}
          >
            {timeFrameOptions.map(({ value: inputValue, label }) => {
              return (
                <option key={inputValue} value={inputValue}>
                  {label}
                </option>
              );
            })}
          </select>
        </div>

        {countries !== undefined ? (
          <select
            className={styles.selectCountries}
            onChange={(event) => {
              const inputValue = event.target.value;
              const searchParams = new URLSearchParams(location.search);

              if (inputValue !== ALL_COUNTRIES) {
                searchParams.set('country', inputValue);
              } else {
                searchParams.delete('country');
              }

              history.push(`${location.pathname}?${searchParams}`);

              const { longName } = [DEFAULT_ALL_COUNTRIES, ...countries].find(
                ({ shortName }) => shortName === inputValue
              );

              mixpanel.track('Country change', { country: longName });
            }}
            value={country || ALL_COUNTRIES}
          >
            <option value={DEFAULT_ALL_COUNTRIES.shortName}>
              {DEFAULT_ALL_COUNTRIES.longName}
            </option>
            {(countries || CACHED_COUNTRIES).map(({ shortName, longName }) => {
              return (
                <option key={shortName} value={shortName}>
                  {longName}
                </option>
              );
            })}
          </select>
        ) : (
          setActionSheetContents && (
            <div>
              <button
                onClick={() => {
                  mixpanel.track('Info clicked');
                  setActionSheetContents(<Info />);
                }}
                className={styles.infoButton}
                type="button"
              >
                info
              </button>
            </div>
          )
        )}
      </div>
      {!noSort && (
        <div className={styles.sortDiv}>
          <span className={styles.listHeading}>Leaderboard</span>
          <div className={styles.holder}>
            <span className={styles.sortBy}>Sort by:</span>

            <Link
              to={`${location.pathname}?${newSearchParams}`}
              className={styles.sortLink}
              onClick={() => {
                mixpanel.track('Sort change', {
                  sort:
                    sort === sortOptions[0].value
                      ? sortOptions[1].label
                      : sortOptions[0].label,
                });
              }}
            >
              {sort === sortOptions[0].value
                ? sortOptions[0].label
                : sortOptions[1].label}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
