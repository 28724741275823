import { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import useUser from './hooks/useUser';
import handleError from './utils/handle-error';

export default function FirebaseCloudMessagingToken() {
  const isSignedUp = useUser();

  const [updateUser] = useMutation(
    gql`
      mutation UpdateUser($user: UpdateUserInput!) {
        updateUser(user: $user) {
          id
        }
      }
    `
  );

  useEffect(() => {
    if (!isSignedUp) return;

    window.Pedal.persistUsersFirebaseCloudMessagingToken = (
      firebaseCloudMessagingToken
    ) => {
      const token = JSON.parse(firebaseCloudMessagingToken);

      updateUser({
        variables: {
          user: {
            firebaseCloudMessagingToken: token,
          },
        },
      });
    };

    if (window.ReactNativeWebView) {
      if (window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ getFirebaseCloudMessagingToken: true })
        );
      } else {
        // seems there is a race condition in RN, does is lazily assign postMessage function?
        handleError({
          error: new Error(
            'window.ReactNativeWebView defined but .postMessage was not'
          ),
          tags: ['ReactNativeWebView'],
        });
      }
    }
  }, [updateUser, isSignedUp]);

  return null;
}
