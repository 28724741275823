import { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Select,
  MenuItem,
  Snackbar,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import accordianOverride from './AccordianOverride.module.css';
import ternAppDetails from './utils/tern-app-details';
import useSearchFilters from './hooks/useSearchFilters';
import { ALL_COMMUTES, USER_STATS } from './User';
import styles from './CreateManualCommute.module.css';
import { activityTypeGraphQLEnumOptions } from './ActivityType';

export default function CreateManualCommute({
  isStrictOnDays,
  userId,
  selectedDay,
  copy,
  setSelectedDay,
  lastLocationRecordedAt,
}) {
  const { activityTypeEnum, commutesAfter, commutesBefore } =
    useSearchFilters();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [distance, updateDistance] = useState(1);
  const [direction, updateDirection] = useState('TO_WORK');
  const [activityType, updateCommuteType] = useState('CYCLING');
  const [dateOfCommute, updateDateOfCommute] = useState(
    moment().startOf('day')
  );

  const [apolloError, setApolloError] = useState(false);
  const [expanded, updateExpanded] = useState(false);

  const activityTypeInputId = 'activity-type';
  const dateInputId = 'date-of-commute';
  const directionInputId = 'direction-of-commute';

  useEffect(() => {
    // no op because we only allow today or yesterday
    if (isStrictOnDays) return;
    if (!selectedDay) return;

    updateDateOfCommute(
      // react-dates sends through midday ISO's
      moment.min(selectedDay.clone().startOf('day'), moment().startOf('day'))
    );
  }, [selectedDay, isStrictOnDays]);

  const [createCommute, { loading }] = useMutation(
    gql`
      mutation CreateManualCommute($commute: ManualCommuteInput!) {
        createManualCommute(commute: $commute) {
          id
        }
      }
    `,
    {
      onError: (err) => {
        setApolloError(err);
      },
      refetchQueries: [
        {
          query: ALL_COMMUTES,
          variables: {
            userId,
          },
        },
        {
          query: USER_STATS,
          variables: {
            userId,
            activityType: activityTypeEnum,
            commutesAfter,
            commutesBefore,
            startOfMonth: moment().startOf('month').toISOString(),
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  return (
    <>
      <Snackbar
        open={!!apolloError}
        autoHideDuration={6000}
        onClose={() => {
          setApolloError(false);
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => {
            setApolloError(false);
          }}
        >
          Sorry something went wrong creating a manual commute.
        </Alert>
      </Snackbar>

      <div className={styles.buttonHolder}>
        <Button
          className={styles.addACommuteButton}
          variant="outlined"
          color="primary"
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          Add a commute manually
        </Button>
      </div>
      <div className={styles.copyAndButton}>{copy}</div>

      <Dialog
        disablePortal
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        classes={{ paper: styles.paper }}
      >
        <DialogTitle>Add a commute manually</DialogTitle>
        <DialogContent>
          <Accordion
            classes={{
              root: [accordianOverride.accordian, styles.accordian].join(' '),
            }}
            expanded={expanded}
            onChange={(_, isExpanded) => {
              updateExpanded(isExpanded);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Why did it not automatically track my commute?
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.reason}>
                <p>
                  We&#39;re sorry about this. There are a few reasons the
                  auto-detection might of missed your commute.
                </p>
                <ul>
                  <li>
                    Your <i>Location</i> and{' '}
                    <i>
                      {ternAppDetails?.isIOS
                        ? 'Motion & Fitness'
                        : 'Physical activity'}
                    </i>{' '}
                    permissions were not set up correctly, please visit{' '}
                    <Link className={styles.link} to="/settings">
                      settings
                    </Link>{' '}
                    to learn more.{' '}
                    {lastLocationRecordedAt
                      ? `The last tracking location logged was on ${new Intl.DateTimeFormat(
                          undefined,
                          { dateStyle: 'full', timeStyle: 'long' }
                        ).format(new Date(lastLocationRecordedAt))}.`
                      : 'Your app has not logged any locations yet.'}
                  </li>
                  <li>
                    {`You travelled outside of your tracking schedule in `}
                    <Link className={styles.link} to="/settings">
                      settings
                    </Link>
                    .
                  </li>
                  <li>
                    Your phone was in your bag or attached to your handlebars
                    while cycling, sometimes Android and iOS thinks your in a
                    vehicle if your phone is static and you&#39;re moving fast
                    like a car. It works better with your phone in your pocket,
                    this is because it uses many sensors to detect activity
                    type, for example the accelerometer and magnetometer.
                  </li>
                  <li>
                    Your phone was low on battery and it restricted background
                    services.
                  </li>
                </ul>
                <p>
                  We&#39;re continuously improving the algorithms around
                  auto-detections, it will get better over time.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>

          {isStrictOnDays ? (
            <FormControl variant="standard" component="fieldset">
              <FormHelperText>When was the commute?</FormHelperText>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={
                  dateOfCommute.isSame(moment(), 'day') ? 'today' : 'yesterday'
                }
                onChange={(event) => {
                  const newDate =
                    event.target.value === 'today'
                      ? moment()
                      : moment().subtract(1, 'day');
                  updateDateOfCommute(newDate);
                }}
              >
                <FormControlLabel
                  value="today"
                  control={<Radio color="primary" />}
                  label="Today"
                />
                <FormControlLabel
                  value="yesterday"
                  control={<Radio color="primary" />}
                  label="Yesterday"
                />
              </RadioGroup>
            </FormControl>
          ) : (
            <MobileDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              margin="normal"
              id={dateInputId}
              label="Date of commute"
              value={dateOfCommute}
              onChange={updateDateOfCommute}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              PopoverProps={{ disablePortal: true }}
              maxDate={moment()}
            />
          )}

          <FormControl variant="standard" className={styles.formControl}>
            <InputLabel htmlFor={activityTypeInputId}>Activity type</InputLabel>
            <Select
              variant="standard"
              required
              id={activityTypeInputId}
              value={activityType}
              onChange={(event) => {
                updateCommuteType(event.target.value);
              }}
              MenuProps={{ disablePortal: true }}
            >
              {activityTypeGraphQLEnumOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" className={styles.formControl}>
            <InputLabel htmlFor={directionInputId}>To or from work</InputLabel>
            <Select
              variant="standard"
              required
              id={directionInputId}
              value={direction}
              onChange={(event) => {
                updateDirection(event.target.value);
              }}
              MenuProps={{ disablePortal: true }}
            >
              <MenuItem value="TO_WORK">To work</MenuItem>
              <MenuItem value="FROM_WORK">From work</MenuItem>
            </Select>
          </FormControl>

          <TextField
            variant="standard"
            className={styles.formControl}
            type="number"
            label="Distance in km"
            inputProps={{
              min: 1,
              max: 100,
              step: 1,
              required: true,
            }}
            id="commute-distance"
            value={distance}
            onChange={(event) => {
              updateDistance(
                // beware of empty input (removing the 1 to type 20 for example)
                event.target.value ? Math.round(event.target.value) : ''
              );
            }}
          />

          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              Close
            </Button>

            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              color="primary"
              className={styles.createButton}
              onClick={async () => {
                await createCommute({
                  variables: {
                    commute: {
                      distance: distance * 1000,
                      toWork: direction === 'TO_WORK',
                      fromWork: direction === 'FROM_WORK',
                      dateOfCommute: dateOfCommute.toISOString(),
                      activityType,
                    },
                  },
                });
                setSelectedDay(dateOfCommute);
                setDialogOpen(false);
              }}
            >
              {loading ? 'Creating...' : 'Create commute'}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
