import { useMutation, gql } from '@apollo/client';

export default function useCreateCompanyOffice({ company, office, officeId }) {
  const [addCompany, { loading: addCompanyLoading, error: addCompanyError }] =
    useMutation(
      gql`
        mutation CreateCompany($company: CompanyInput!) {
          createCompany(company: $company) {
            id
          }
        }
      `
    );

  const [addOffice, { loading: addOfficeLoading, error: addOfficeError }] =
    useMutation(
      gql`
        mutation CreateOffice($office: OfficeInput!) {
          createOffice(office: $office) {
            id
          }
        }
      `
    );

  async function createCompanyOffice() {
    let usersCompanyId;

    if (company.inputValue) {
      let mutationResult;
      try {
        mutationResult = await addCompany({
          variables: {
            company: {
              companyName: company.inputValue,
            },
          },
        });
      } catch (mutationError) {
        return undefined;
      }

      usersCompanyId = mutationResult.data.createCompany.id;
    } else {
      usersCompanyId = company.id;
    }

    let usersOfficeId;
    if (office) {
      const country = office.placeResult.address_components.find(({ types }) =>
        types.includes('country')
      );

      let mutationResult;
      try {
        mutationResult = await addOffice({
          variables: {
            office: {
              companyId: usersCompanyId,
              address: office.description,
              googlePlaceId: office.place_id,
              latitude: office.placeResult.geometry.location.lat(),
              longitude: office.placeResult.geometry.location.lng(),
              countryNameShort: country.short_name,
              countryNameLong: country.long_name,
            },
          },
        });
      } catch (mutationError) {
        return undefined;
      }
      usersOfficeId = mutationResult.data.createOffice.id;
    } else {
      usersOfficeId = officeId;
    }

    return usersOfficeId;
  }

  return {
    createCompanyOffice,
    addCompanyError,
    addCompanyLoading,
    addOfficeLoading,
    addOfficeError,
  };
}
