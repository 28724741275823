import { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { startCase } from 'lodash';
import {
  MenuItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { BiDotsVerticalRounded as DotsVerticalIcon } from 'react-icons/bi';
import { useMutation, gql } from '@apollo/client';
import useSearchFilters from './hooks/useSearchFilters';
import { ALL_COMMUTES, USER_STATS } from './User';
import { WalkingIcon, RunningIcon, CyclingIcon, BlockedIcon } from './Icons';
import CommuteMap from './CommuteMap';
import Card from './Card';
import styles from './CommuteCard.module.css';
import UpdateCommuteActivityType from './UpdateCommuteActivityType';

export default function CommuteCard({
  userId,
  commute,
  isUser,
  isLastCommuteCard,
}) {
  const { activityTypeEnum, commutesAfter, commutesBefore } =
    useSearchFilters();
  const location = useLocation();
  const commuteCardRef = useRef();
  const history = useHistory();
  const isManualCommute = commute.__typename === 'ManualCommute';
  const isPrivateCommute = commute.locations === null;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isChangeActivityTypeModalOpen, setIsChangeActivityTypeModalOpen] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showActivityColours, setShowActivityColours] = useState(false);

  useEffect(() => {
    const sp = new URLSearchParams(location.search);
    const notificationParam = 'scrollToLatestCommuteCard';

    // using search param for this trigger assigning a hash update on the same page
    // does not reload the page
    if (!isLastCommuteCard || !sp.has(notificationParam)) {
      return;
    }

    commuteCardRef.current.scrollIntoView({
      behavior: 'smooth',
    });

    sp.delete(notificationParam);

    history.replace(`${location.pathname}?${sp}`);
  }, [isLastCommuteCard, location, history]);

  const [deleteManualCommute, { loading: deletingManualCommute }] = useMutation(
    gql`
      mutation DeleteManualCommute($id: ID!) {
        deleteManualCommute(id: $id)
      }
    `,
    {
      refetchQueries: [
        {
          query: ALL_COMMUTES,
          variables: {
            userId,
          },
        },
        {
          query: USER_STATS,
          variables: {
            userId,
            activityType: activityTypeEnum,
            commutesAfter,
            commutesBefore,
            startOfMonth: moment().startOf('month').toISOString(),
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [deleteCommute, { loading: deletingCommute }] = useMutation(
    gql`
      mutation DeleteCommute($id: ID!) {
        deleteCommute(id: $id)
      }
    `,
    {
      refetchQueries: [
        {
          query: ALL_COMMUTES,
          variables: {
            userId,
          },
        },
        {
          query: USER_STATS,
          variables: {
            userId,
            activityType: activityTypeEnum,
            commutesAfter,
            commutesBefore,
            startOfMonth: moment().startOf('month').toISOString(),
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    const userConfired = window.confirm(
      'Are you sure you wish to delete the commute?'
    );

    if (!userConfired) {
      handleClose();
      return;
    }

    if (deletingManualCommute || deletingCommute) return;

    if (isManualCommute) {
      await deleteManualCommute({ variables: { id: commute.id } });
    } else {
      await deleteCommute({ variables: { id: commute.id } });
      window.gtag('event', 'delete commute');
      mixpanel.track('Delete Commute');
    }
  };

  let startToFinish;
  let startMoment;
  let endMoment;
  const firstSeg = commute.locations && commute.locations[0];
  const lastSeg =
    commute.locations && commute.locations[commute.locations.length - 1];

  if (
    commute.locations &&
    firstSeg &&
    lastSeg &&
    firstSeg[0].recordedAt &&
    lastSeg[lastSeg.length - 1].recordedAt
  ) {
    startMoment = moment(firstSeg[0].recordedAt);
    endMoment = moment(lastSeg[lastSeg.length - 1].recordedAt);

    // note it can be confusing as the `dateOfCommute` uses a TZ and this will be local time.
    // e.g a UK commute on monday from work in Sydney would say 3am startMoment because it's actually Tuesday.
    startToFinish = `${startMoment.format('h:mm:ssa')} - ${endMoment.format(
      'h:mm:ssa'
    )}`;
  }

  const totalDuration = isManualCommute
    ? undefined
    : moment.duration(commute.duration);
  const paceDuration = isManualCommute
    ? undefined
    : moment.duration(commute.pace);

  let activityTypeIcon;

  switch (commute.activityType) {
    case 'CYCLING':
      activityTypeIcon = (
        <CyclingIcon className={styles.activityIconContainer} />
      );
      break;
    case 'WALKING':
      activityTypeIcon = (
        <WalkingIcon className={styles.activityIconContainer} />
      );
      break;
    case 'RUNNING':
      activityTypeIcon = (
        <RunningIcon className={styles.activityIconContainer} />
      );
      break;
    case 'VEHICLE':
      activityTypeIcon = (
        <BlockedIcon className={styles.activityIconContainer} />
      );
      break;
    default:
      activityTypeIcon = false;
  }

  const isVehicleCommute = commute.activityType === 'VEHICLE';

  const topContent = (
    <>
      {isUser && (
        <UpdateCommuteActivityType
          commute={commute}
          isChangeActivityTypeModalOpen={isChangeActivityTypeModalOpen}
          setIsChangeActivityTypeModalOpen={setIsChangeActivityTypeModalOpen}
        />
      )}
      {!isManualCommute && !isPrivateCommute && (
        <Dialog
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disablePortal
        >
          <DialogTitle id="customized-dialog-title">
            Activity types breakdown
          </DialogTitle>
          <DialogContent>
            <div className={styles.certaintyHolder}>
              <span className={styles.certaintySubtitle}>
                {`${commute.totalLocations} data points`}
              </span>
              {commute.isMultiMode && (
                <p>
                  {`Mix of vehicle and active commuting detected so it has been broke down into ${commute.locations.length} segments.`}
                </p>
              )}
              {Object.entries(commute.commuteCertainty)
                .sort((a, b) => b[1] - a[1])
                .map(
                  ([key, value]) =>
                    key !== '__typename' && (
                      <code key={key} className={styles.certaintyStat}>
                        {`${startCase(key)}: ${Math.round(
                          (value * 100) / commute.totalLocations
                        ).toLocaleString()}% (${value})`}
                      </code>
                    )
                )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setIsModalOpen(false);
              }}
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Menu
        id="commute-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disablePortal
      >
        {isUser && (
          <MenuItem
            onClick={() => {
              setIsChangeActivityTypeModalOpen(true);
              handleClose();
            }}
          >
            {isManualCommute ? 'Change' : 'Override'} commute details
          </MenuItem>
        )}
        {!isManualCommute && (
          <MenuItem
            onClick={() => {
              setIsModalOpen(true);
              handleClose();
            }}
          >
            View detected activity type info
          </MenuItem>
        )}
        {!isManualCommute && (
          <MenuItem
            onClick={() => {
              setShowActivityColours((old) => !old);
              handleClose();
            }}
          >
            {showActivityColours
              ? 'Hide activity type data on map'
              : 'Show activity type data on map'}
          </MenuItem>
        )}
        {isUser && (
          <MenuItem onClick={handleDelete}>
            {deletingManualCommute || deletingCommute ? 'Deleting' : 'Delete'}
          </MenuItem>
        )}
      </Menu>
      <div className={styles.topContentWrapper}>
        <div className={styles.topContent} ref={commuteCardRef}>
          {activityTypeIcon}
          <div className={styles.largeChild}>
            <div className={styles.dateOfCommuteContainer}>
              <span>
                {`${moment(startMoment || commute.dateOfCommute).format(
                  'dddd Do MMMM'
                )} ${commute.toWork ? 'to work' : 'from work'}`}
              </span>
              {(isUser || (!isManualCommute && !isPrivateCommute)) && (
                <button
                  type="button"
                  onClick={handleClick}
                  className={styles.moreInfoDots}
                  aria-label="See more"
                >
                  <DotsVerticalIcon />
                </button>
              )}
            </div>
            <span className={styles.bottomCopy}>
              {startToFinish
                ? `${startToFinish} `
                : isManualCommute
                ? commute.importedFrom === 'STRAVA'
                  ? 'Imported from Strava'
                  : 'Manually added by user'
                : ''}
            </span>
            {commute.locations && commute.isMultiMode && (
              <span className={styles.bottomCopy}>Multi-mode commute</span>
            )}
            {process.env.NODE_ENV === 'production' ? null : (
              <div className={styles.dev}>
                <code>{commute.id}</code>
                <code>{atob(commute.id)}</code>
                <code>
                  {isManualCommute
                    ? `manual`
                    : isPrivateCommute
                    ? 'auto - private'
                    : commute.isMultiMode
                    ? `auto - multi segment`
                    : 'auto - single segment'}
                </code>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/inspect?${new URLSearchParams({
                    userId,
                    fromTime: startMoment
                      ? startMoment.clone().subtract(4, 'hours').toISOString()
                      : moment(commute.dateOfCommute)
                          .startOf('day')
                          .toISOString(),
                    toTime: endMoment
                      ? endMoment.clone().add(4, 'hours').toISOString()
                      : moment(commute.dateOfCommute)
                          .endOf('day')
                          .toISOString(),
                  })}`}
                >
                  View inspect map
                </a>
              </div>
            )}
          </div>
        </div>
        {isVehicleCommute && (
          <p>
            Was this right? We detected a commute but unfortunately the data
            showed it contained too much vehicle parts and not enough active
            travel, Tern therefore did not award any green kilometers. Apologies
            if this was wrong, if so, please delete this commute and add a
            manual one above. Note we do not show this commute to others.
          </p>
        )}
      </div>
    </>
  );

  return (
    <Card
      topContent={topContent}
      stats={
        isVehicleCommute
          ? undefined
          : {
              isManualCommute,
              distance: commute.distance,
              CO2: commute.CO2,
              averageSpeed: commute.averageSpeed,
              totalDuration,
              paceDuration,
            }
      }
      map={
        commute.locations && (
          <CommuteMap
            showActivityColours={showActivityColours}
            commute={commute}
          />
        )
      }
    />
  );
}
