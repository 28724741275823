export {
  MdDirectionsWalk as WalkingIcon,
  MdDirectionsRun as RunningIcon,
  MdBlock as BlockedIcon,
} from 'react-icons/md';
export { GrBike as CyclingIcon } from 'react-icons/gr';
export { HiOutlineCloud as CloudIcon } from 'react-icons/hi';
export { FiExternalLink as ExternalLinkIcon } from 'react-icons/fi';
export function TreeIcon({ className }) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3176 3.73331C11.1759 2.753 10.705 1.85153 9.97272 1.1695C9.16294 0.415344 8.10725 0 7.0001 0C5.89294 0 4.83725 0.415344 4.02744 1.1695C3.29513 1.85153 2.82429 2.753 2.68254 3.73331C1.10519 4.42206 0.0649414 5.99328 0.0649414 7.73259C0.0649414 10.1382 2.02204 12.0953 4.4276 12.0953C5.169 12.0953 5.89154 11.9067 6.53129 11.5544V16H7.46879V11.5544C8.10854 11.9067 8.83107 12.0953 9.57247 12.0953C11.9781 12.0953 13.9351 10.1382 13.9351 7.73259C13.9352 5.99328 12.895 4.42203 11.3176 3.73331ZM9.57254 11.1578C8.80772 11.1578 8.06847 10.9024 7.46885 10.4352V7.568L9.57132 6.46253L9.135 5.63275L7.46885 6.50881V4.66344H6.53135V8.58672L4.86519 7.71066L4.42888 8.54044L6.53135 9.64591V10.4352C5.93172 10.9024 5.19247 11.1578 4.42766 11.1578C2.539 11.1578 1.0025 9.62122 1.0025 7.73259C1.0025 6.2885 1.91582 4.99197 3.27516 4.50631L3.56191 4.40387L3.58485 4.10025C3.64979 3.24184 4.03388 2.44466 4.66641 1.85556C5.30207 1.26353 6.13088 0.9375 7.0001 0.9375C7.86932 0.9375 8.69813 1.26353 9.33382 1.85553C9.96635 2.44462 10.3504 3.24178 10.4154 4.10022L10.4383 4.40384L10.7251 4.50628C12.0844 4.99194 12.9977 6.28847 12.9977 7.73256C12.9977 9.62122 11.4612 11.1578 9.57254 11.1578Z"
        fill="#7E8594"
      />
    </svg>
  );
}
export { FaCheck as CheckIcon } from 'react-icons/fa';

export function AIIcon({ className }) {
  return (
    <svg
      fill="currentColor"
      stroke="currentColor"
      className={className}
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m199.472 300.169c5.036 2.264 10.956.019 13.223-5.017l5.227-11.621h33.326l5.227 11.621c1.667 3.705 5.311 5.9 9.126 5.9 1.371 0 2.765-.284 4.097-.883 5.036-2.266 7.283-8.185 5.018-13.222l-31.01-68.938c-1.615-3.589-5.185-5.897-9.12-5.897s-7.505 2.309-9.12 5.897l-31.011 68.938c-2.267 5.037-.019 10.957 5.017 13.222zm35.113-53.681 7.666 17.043h-15.333z" />
        <path d="m312.615 291.05v-68.938c0-5.523-4.478-10-10-10s-10 4.477-10 10v68.938c0 5.523 4.478 10 10 10s10-4.477 10-10z" />
        <path d="m153.714 368.286h204.572c5.522 0 10-4.477 10-10v-204.572c0-5.523-4.478-10-10-10h-57.4c-5.522 0-10 4.477-10 10s4.478 10 10 10h47.4v184.572h-184.572v-184.572h47.372c5.522 0 10-4.477 10-10s-4.478-10-10-10h-57.372c-5.522 0-10 4.477-10 10v204.572c0 5.523 4.477 10 10 10z" />
        <path d="m502 217.939c5.522 0 10-4.477 10-10s-4.478-10-10-10h-41.363v-28.046h41.363c5.522 0 10-4.477 10-10s-4.478-10-10-10h-41.363v-17.382c0-5.523-4.478-10-10-10h-37.469l-23.707-23.707v-37.47c0-5.523-4.478-10-10-10h-17.383v-41.334c0-5.523-4.478-10-10-10s-10 4.477-10 10v41.334h-28.046v-41.334c0-5.523-4.478-10-10-10s-10 4.477-10 10v41.334h-28.047v-41.334c0-5.523-4.478-10-10-10s-10 4.477-10 10v41.334h-28.046v-41.334c0-5.523-4.478-10-10-10s-10 4.477-10 10v41.334h-28.046v-41.334c0-5.523-4.478-10-10-10s-10 4.477-10 10v41.334h-17.383c-5.522 0-10 4.477-10 10v37.47l-23.707 23.707h-37.47c-5.522 0-10 4.477-10 10v17.382h-41.333c-5.522 0-10 4.477-10 10s4.478 10 10 10h41.334v28.046h-41.334c-5.522 0-10 4.477-10 10s4.478 10 10 10h41.334v28.046h-41.334c-5.522 0-10 4.477-10 10s4.478 10 10 10h41.334v28.046h-41.334c-5.522 0-10 4.477-10 10s4.478 10 10 10h41.334v28.046h-41.334c-5.522 0-10 4.477-10 10s4.478 10 10 10h41.334v17.382c0 5.523 4.478 10 10 10h37.47l23.707 23.707v37.47c0 5.523 4.478 10 10 10h17.383v41.364c0 5.523 4.478 10 10 10s10-4.477 10-10v-41.363h28.046v41.363c0 5.523 4.478 10 10 10s10-4.477 10-10v-41.363h28.046v41.363c0 5.523 4.478 10 10 10s10-4.477 10-10v-41.363h28.047v41.363c0 5.523 4.478 10 10 10s10-4.477 10-10v-41.363h28.046v41.363c0 5.523 4.478 10 10 10s10-4.477 10-10v-41.363h17.383c5.522 0 10-4.477 10-10v-37.47l23.707-23.707h37.469c5.522 0 10-4.477 10-10v-17.382h41.362c5.522 0 10-4.477 10-10s-4.478-10-10-10h-41.363v-28.046h41.363c5.522 0 10-4.477 10-10s-4.478-10-10-10h-41.363v-28.046h41.363c5.522 0 10-4.477 10-10s-4.478-10-10-10h-41.363v-28.046h41.363zm-132.539-146.605v21.612h-226.95v-21.612zm-298.127 71.177h21.612v226.949h-21.612zm41.612-5.858 23.707-23.707h238.665l23.707 23.707v238.665l-23.707 23.707h-238.665l-23.707-23.707zm29.565 303.984v-21.612h226.95v21.612zm298.126-71.177h-21.611v-226.949h21.611z" />
        <path d="m255.985 163.714h.058c5.522 0 9.972-4.477 9.972-10s-4.507-10-10.029-10-10 4.477-10 10 4.477 10 9.999 10z" />
      </g>
    </svg>
  );
}
