import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  EmailAuthProvider,
  getAuth,
} from 'firebase/auth';
import { Redirect, useLocation } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import ternAppDetails from './utils/tern-app-details';
import Loading from './Loading';
import useIsSignedIn from './hooks/useIsSignedIn';
import SignupDetails from './SignupDetails';
import styles from './Signup.module.css';
import handleError from './utils/handle-error';
import LinksToWebsite from './LinksToWebsite';

const uiConfig = {
  signInOptions: [
    {
      provider: 'apple.com',
    },
    // {
    //   provider: TwitterAuthProvider.PROVIDER_ID
    // },
    (ternAppDetails === null ||
      (ternAppDetails.version && ternAppDetails.version >= 1)) && {
      provider: GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        // Forces account selection even when one account
        // is available.
        prompt: 'select_account',
      },
    },
    {
      provider: FacebookAuthProvider.PROVIDER_ID,
      customParameters: {
        // Forces password re-entry.
        auth_type: 'reauthenticate',
      },
    },
    {
      provider: EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
    },
  ].filter(Boolean),
  immediateFederatedRedirect: false,
  credentialHelper: 'none',
  // Popup signin flow rather than redirect flow.
  signInFlow: 'redirect',

  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      console.debug('signInSuccessWithAuthResult');
      console.debug({ authResult, redirectUrl });
      return false;
    },

    signInFailure: (error) => {
      handleError({
        error,
        tags: ['Firebase:signInSuccessWsignInFailureithAuthResult'],
      });
    },
  },
};

function SignUp({
  redirect,
  redirectAfterCreate,
  isAdmin = false,
  isAdminRegister = false,
}) {
  const isSignedIn = useIsSignedIn();

  const { loading, error, data } = useQuery(
    gql`
      query UserCheck($firebaseAuthUid: ID!) {
        user(firebaseAuthUid: $firebaseAuthUid) {
          id
        }
      }
    `,
    {
      skip: !isSignedIn,
      variables: {
        firebaseAuthUid: isSignedIn ? getAuth().currentUser.uid : null,
      },
    }
  );

  const location = useLocation();

  let conditionalContent;
  if (isSignedIn) {
    if (error) {
      conditionalContent = (
        <p>
          {`Error: ${
            process.env.NODE_ENV === 'production'
              ? error.message
              : JSON.stringify(error)
          }`}
        </p>
      );
    } else if (loading) {
      conditionalContent = <Loading />;
    } else if (data && data.user && data.user.id) {
      conditionalContent = <Redirect to={redirect} />;
    } else {
      conditionalContent = (
        <>
          <div>
            <SignupDetails
              redirect={redirectAfterCreate}
              isAdminRegister={isAdminRegister}
            />
          </div>
          <LinksToWebsite />
        </>
      );
    }
  } else {
    const qps = new URLSearchParams(location.search);

    let topParagraph = null;

    if (qps.get('signupType') === 'office') {
      topParagraph = <p>Please sign in to access your office profile.</p>;
    } else if (qps.get('signupType') === 'user') {
      topParagraph = <p>Please sign in to access your profile.</p>;
    } else if (qps.get('signupType') === 'settings') {
      topParagraph = (
        <p>Please sign in to configure your commute tracking settings.</p>
      );
    } else if (qps.get('signupType') === 'rewards') {
      topParagraph = (
        <p>Please sign in to so you can then join the challenge.</p>
      );
    } else if (qps.get('signupType') === 'company-rewards') {
      topParagraph = (
        <p>Please sign in to so you can see your company rewards.</p>
      );
    } else if (qps.get('signupType') === 'my-rewards') {
      topParagraph = <p>Please sign in to view your rewards.</p>;
    } else if (qps.get('signupType') === 'joinOffice') {
      topParagraph = (
        <p>
          Sign in to link yourself to an office and start clocking green
          commutes.
        </p>
      );
    } else if (qps.get('signupType') === 'welcome') {
      topParagraph = (
        <>
          <p>Choose a provider of your choice to sign in with.</p>
          <p>
            Linking to a personal email over a work email makes it easier to
            keep your account when you move jobs.
          </p>
        </>
      );
    }

    conditionalContent = (
      <>
        <div>
          <h1>
            {isAdminRegister
              ? 'Register your business today'
              : 'Sign up or log in'}
          </h1>
          {topParagraph}

          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={getAuth()}
            className={styles.firebaseAuth}
          />
        </div>
        <LinksToWebsite />
      </>
    );
  }

  return (
    <div className={isAdmin ? undefined : styles.mobileAppContainer}>
      {conditionalContent}
    </div>
  );
}

export default SignUp;
