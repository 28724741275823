import ActivityType from './ActivityType';
import TimeframeAndSort from './TimeframeAndSort';
import StatsRow from './StatsRow';
import styles from './Filters.module.css';

export default function Filters({
  noSort = false,
  commutes,
  distance,
  CO2,
  trees,
  countries,
}) {
  return (
    <>
      <ActivityType />
      {commutes !== undefined ||
      distance !== undefined ||
      CO2 !== undefined ||
      trees !== undefined ? (
        <div className={styles.statHolder}>
          <StatsRow
            isFilters
            CO2={CO2}
            distance={distance}
            commutes={commutes}
            trees={trees}
            noSort={noSort}
          />
        </div>
      ) : null}
      <TimeframeAndSort noSort={noSort} countries={countries} />
    </>
  );
}
