import { NavLink } from 'react-router-dom';
import {
  FaBuilding,
  FaUser,
  FaCog,
  FaTicketAlt,
  FaBars as MoreIcon,
} from 'react-icons/fa';
import {
  MdFlag as FaChallenge,
  MdFeedback as FeedbackIcon,
} from 'react-icons/md';
import { IoMdTrophy as LeaderboardIcon } from 'react-icons/io';
import mixpanel from 'mixpanel-browser';
import { RiInstagramFill as InstagramIcon } from 'react-icons/ri';
import {
  List,
  ListItem,
  ListItemText,
  Drawer,
  ListItemIcon,
  BottomNavigation,
  BottomNavigationAction,
  ListItemButton,
} from '@mui/material';
import { useState } from 'react';
import useSearchFilters from './hooks/useSearchFilters';
import styles from './Footer.module.css';
import useUser from './hooks/useUser';

function Footer({ isWidget }) {
  const user = useUser();
  const [isDrawOpen, setIsDrawOpen] = useState(false);

  const { activityType, timeFrame, sort, isDefaultSort, country } =
    useSearchFilters();

  const qpAll = new URLSearchParams();
  if (activityType) qpAll.set('activityType', activityType);
  if (timeFrame) qpAll.set('timeFrame', timeFrame);
  if (!isDefaultSort) qpAll.set('sort', sort);

  if (isWidget)
    return (
      <div className={styles.poweredByContainer}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={styles.poweredByLink}
          href={process.env.PUBLIC_URL}
        >
          {`powered by `}
          <span className={styles.logo}>Tern</span>
        </a>
      </div>
    );

  return (
    <>
      <BottomNavigation showLabels className={styles.container}>
        <BottomNavigationAction
          label="My office"
          icon={
            <span className={styles.sharedIconHeight}>
              <FaBuilding className={styles.officeIcon} />
            </span>
          }
          component={NavLink}
          centerRipple
          className={styles.link}
          exact
          strict
          activeClassName={styles.activeLink}
          isActive={(match, currentLocation) => {
            const urlSp = new URLSearchParams(currentLocation.search);
            return user ? !!match : urlSp.get('signupType') === 'office';
          }}
          to={
            user
              ? `/offices/${user.office.id}?${qpAll}`
              : '/signup?signupType=office'
          }
          onClick={() => {
            window.gtag('event', 'navigation', {
              event_category: 'footer',
              event_label: 'my office',
            });
            mixpanel.track('Bottom Nav Click', { type: 'Office' });
          }}
        />

        <BottomNavigationAction
          label="Challenges"
          icon={
            <span className={styles.sharedIconHeight}>
              <FaChallenge className={styles.challengesIcon} />
            </span>
          }
          component={NavLink}
          centerRipple
          strict
          exact
          className={styles.link}
          activeClassName={styles.activeLink}
          to="/rewards"
          onClick={() => {
            window.gtag('event', 'navigation', {
              event_category: 'footer',
              event_label: 'rewards',
            });
            mixpanel.track('Bottom Nav Click', { type: 'Challenges' });
          }}
        />

        <BottomNavigationAction
          label="Leaderboards"
          icon={
            <span className={styles.sharedIconHeight}>
              <LeaderboardIcon className={styles.leaderboardIcon} />
            </span>
          }
          component={NavLink}
          centerRipple
          className={styles.link}
          exact
          strict
          activeClassName={styles.activeLink}
          to={`/?${qpAll}${country ? `country=${country}` : ''}`}
          onClick={() => {
            window.gtag('event', 'navigation', {
              event_category: 'footer',
              event_label: 'offices',
            });
            mixpanel.track('Bottom Nav Click', { type: 'Leaderboards' });
          }}
          isActive={(match, currentLocation) => {
            return ['/', '/users'].includes(currentLocation.pathname);
          }}
        />

        <BottomNavigationAction
          label="Profile"
          icon={
            <span className={styles.sharedIconHeight}>
              <FaUser className={styles.userIcon} />
            </span>
          }
          component={NavLink}
          centerRipple
          className={styles.link}
          exact
          strict
          activeClassName={styles.activeLink}
          isActive={(match, currentLocation) => {
            const urlSp = new URLSearchParams(currentLocation.search);
            return user ? !!match : urlSp.get('signupType') === 'user';
          }}
          to={user ? `/users/${user.id}?${qpAll}` : '/signup?signupType=user'}
          onClick={() => {
            window.gtag('event', 'navigation', {
              event_category: 'footer',
              event_label: 'me',
            });
            mixpanel.track('Bottom Nav Click', { type: 'Profile' });
          }}
        />

        <BottomNavigationAction
          centerRipple
          label="More"
          icon={
            <span className={styles.sharedIconHeight}>
              <MoreIcon className={styles.moreIcon} />
            </span>
          }
          className={styles.buttonMore}
          onClick={() => {
            setIsDrawOpen(true);
          }}
        />
      </BottomNavigation>
      <Drawer
        anchor="bottom"
        open={isDrawOpen}
        onClose={() => {
          setIsDrawOpen(false);
        }}
      >
        <List classes={{ root: styles.list }}>
          <ListItem disablePadding>
            <ListItemButton
              component={NavLink}
              exact
              strict
              activeClassName={styles.activeLink}
              isActive={(match, currentLocation) => {
                const urlSp = new URLSearchParams(currentLocation.search);
                return user ? !!match : urlSp.get('signupType') === 'settings';
              }}
              to={user ? `/settings` : '/signup?signupType=settings'}
              onClick={() => {
                window.gtag('event', 'navigation', {
                  event_category: 'footer',
                  event_label: 'settings',
                });
                mixpanel.track('Bottom Nav Click', { type: 'Settings' });
                setIsDrawOpen(false);
              }}
            >
              <ListItemIcon>
                <FaCog className={styles.settingsIcon} />
              </ListItemIcon>
              <ListItemText primary="Commute tracking & settings" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              target="_blank"
              rel="noreferrer"
              href="https://tern.canny.io/feature-requests#tern"
              onClick={() => {
                mixpanel.track('Bottom Nav Click', {
                  type: 'Feature requests & feedback',
                });
                setIsDrawOpen(false);
              }}
            >
              <ListItemIcon>
                <FeedbackIcon className={styles.feedbackIcon} />
              </ListItemIcon>
              <ListItemText primary="Feature requests & feedback" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              component={NavLink}
              exact
              strict
              activeClassName={styles.activeLink}
              isActive={(match, currentLocation) => {
                const urlSp = new URLSearchParams(currentLocation.search);
                return user
                  ? !!match
                  : urlSp.get('signupType') === 'my-rewards';
              }}
              to={
                user ? `/rewards/my-rewards` : '/signup?signupType=my-rewards'
              }
              onClick={() => {
                mixpanel.track('Bottom Nav Click', { type: 'My rewards' });
                setIsDrawOpen(false);
              }}
            >
              <ListItemIcon>
                <FaTicketAlt className={styles.myRewardsIcon} />
              </ListItemIcon>
              <ListItemText primary="My rewards" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              href="https://www.instagram.com/tern_app/#tern"
              onClick={() => {
                mixpanel.track('Bottom Nav Click', {
                  type: 'Instagram',
                });
                setIsDrawOpen(false);
              }}
            >
              <ListItemIcon>
                <InstagramIcon className={styles.instagramIcon} />
              </ListItemIcon>
              <ListItemText primary="Tern's Instagram" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

export default Footer;
