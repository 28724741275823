import { Link, useHistory } from 'react-router-dom';
import randomcolor from 'randomcolor';
import { useState, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import ActionSheetContext from './context/action-sheet';
import StatsRow from './StatsRow';
import BottomSheetRouter from './BottomSheetRouter';
import styles from './Card.module.css';

export default function Card({
  loading,
  topContent,
  map,
  title,
  subtitle,
  stats,
  image,
  link,
  position,
  noBottomSheet = false,
}) {
  const [imgLoadingError, updateImgLoadingError] = useState(false);
  const setActionSheetContents = useContext(ActionSheetContext);
  const history = useHistory();
  if (loading) {
    return (
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.topContentContainer}>
            <div className={styles.fallBackImage}>
              <Skeleton height={40} width={40} />
            </div>
            <div
              className={styles.companyDetails}
              style={{ marginLeft: '10px' }}
            >
              <Skeleton width="130px" className={styles.skelTop} />
              <Skeleton />
            </div>
          </div>
          <div className={styles.bottomContentContainer}>
            <Skeleton className={styles.skelTopBottom} />
            <Skeleton />
          </div>
        </div>
      </div>
    );
  }

  const noBottomContent = !stats;
  const contents = (
    <>
      <div className={styles.card}>
        <div
          className={styles.topContentContainer}
          style={noBottomContent ? { paddingBottom: 0 } : undefined}
        >
          {image ? (
            image.url && !imgLoadingError ? (
              <img
                onError={() => {
                  updateImgLoadingError(true);
                }}
                alt="Company logo"
                className={styles.image}
                src={image.url}
              />
            ) : (
              <div
                style={{
                  backgroundColor: randomcolor({
                    seed: image.seed,
                    luminosity: 'light',
                  }),
                }}
                className={styles.fallBackImage}
              >
                {image.char}
              </div>
            )
          ) : null}
          {topContent || (
            <div
              className={styles.companyDetails}
              style={image ? { marginLeft: '10px' } : undefined}
            >
              <span className={styles.companyName}>
                {typeof position !== 'undefined' && (
                  <span className={styles.position}>{`${position + 1}.`}</span>
                )}
                {title}
              </span>
              <span className={styles.companyAddress}>{subtitle}</span>
            </div>
          )}
        </div>
        {stats && (
          <div className={styles.bottomContentContainer}>
            <StatsRow
              CO2={stats.CO2}
              distance={stats.distance}
              commutes={stats.commutes}
              trees={stats.trees}
              // user commute card
              averageSpeed={stats.averageSpeed}
              isManualCommute={stats.isManualCommute}
              totalDuration={stats.totalDuration}
              paceDuration={stats.paceDuration}
            />
          </div>
        )}
      </div>
      {map}
    </>
  );

  return link ? (
    <Link
      onClick={(event) => {
        const notInsideBottomSheet = !!setActionSheetContents;
        if (
          !noBottomSheet &&
          window.ReactNativeWebView &&
          notInsideBottomSheet
        ) {
          event.preventDefault();
          setActionSheetContents(
            <BottomSheetRouter
              setActionSheetContents={setActionSheetContents}
              history={history}
              to={link}
            />
          );
        }
      }}
      className={styles.cardContainer}
      to={link}
    >
      {contents}
    </Link>
  ) : (
    <div className={styles.cardContainer}>{contents}</div>
  );
}
