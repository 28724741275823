import { useQuery, gql, useMutation } from '@apollo/client';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Slide,
  Snackbar,
  Alert,
} from '@mui/material';
import React, { Suspense, useState, lazy, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';
import styles from './Challenge.module.css';
import Loading from './Loading';
import Header from './BackButton';
import useTimeLeft from './hooks/useTimeLeft';
import UserThumbnailImage from './UserThumbnailImage';
import useUser from './hooks/useUser';
import { CheckIcon, ExternalLinkIcon } from './Icons';
import AdminUploadImage from './AdminUploadImage';

const ReactMarkdown = lazy(() =>
  import(/* webpackChunkName: "react-markdown" */ 'react-markdown')
);

const CHALLENGE_QUERY = gql`
  fragment userChallengeFragment on User {
    id
    firstName
    lastName
    photoURL
  }

  query Challenge($id: ID!) {
    challenge(id: $id) {
      id
      title
      challengeLong
      backgroundImage
      url
      endTime
      description

      company {
        name
        logoImageUrl
      }

      country {
        longName
      }

      users {
        ...userChallengeFragment
      }

      winners {
        ...userChallengeFragment
      }
    }
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Challenge({ id }) {
  const user = useUser();
  const [isJoinOpen, setIsJoinOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [errorJoiningChallenge, setErrorJoiningChallenge] = useState(false);

  const [joinChallenge, { loading: loadingJoiningChallenge }] = useMutation(
    gql`
      mutation JoinChallenge($id: ID!) {
        joinChallenge(id: $id)
      }
    `,
    {
      refetchQueries: [
        {
          query: CHALLENGE_QUERY,
          variables: {
            id,
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const { data, error, loading } = useQuery(CHALLENGE_QUERY, {
    variables: { id },
  });

  useEffect(() => {
    if (!data) return;

    mixpanel.track('Challenge Viewed', {
      company: data.challenge.company.name,
      title: data.challenge.title,
    });
  }, [data]);

  const timeLeft = useTimeLeft(data ? data.challenge.endTime : null);

  if (error) {
    return (
      <p>
        {`Error: ${
          process.env.NODE_ENV === 'production'
            ? error.message
            : JSON.stringify(error)
        }`}
      </p>
    );
  }

  if (loading) {
    return <Loading />;
  }

  const userInChallenge =
    user && data.challenge.users.some((userEle) => user.id === userEle.id);

  const userRender = (userEle) => (
    <Link key={userEle.id} to={`/users/${userEle.id}`}>
      <UserThumbnailImage
        user={userEle}
        imageClass={styles.image}
        fallbackClass={styles.fallBackImage}
      />
    </Link>
  );

  const showChallengers =
    data.challenge.users.length >
    ((user && user.id === '1') || process.env.NODE_ENV === 'development'
      ? 0
      : 10);

  return (
    <div>
      <Header />
      <div
        className={styles.hero}
        style={{
          backgroundImage: `url("${data.challenge.backgroundImage}")`,
          backgroundSize: 'cover',
          flexGrow: 1,
        }}
      />
      <div className={styles.topCopyHolder}>
        <img
          className={styles.companyImage}
          src={data.challenge.company.logoImageUrl}
          alt="company logo"
        />
        <div>
          <h1 className={styles.header}>{data.challenge.company.name}</h1>
          <span className={styles.title}>{data.challenge.title}</span>
        </div>
      </div>

      <span className={styles.key}>Challenge</span>
      <span className={styles.value}>{data.challenge.challengeLong}</span>
      {timeLeft && (
        <>
          <span className={styles.key}>Time left</span>
          <span className={styles.value}>{timeLeft}</span>
        </>
      )}
      <span className={styles.key}>
        {timeLeft ? 'Finishes' : 'Challenge finished'}
      </span>
      <span className={styles.value}>
        {moment(data.challenge.endTime).format('MMMM Do YYYY')}
      </span>

      {data.challenge.winners.length ? (
        <>
          <span className={styles.key}>Winners 🎉</span>
          <div className={styles.usersHolder}>
            {data.challenge.winners.map(userRender)}
          </div>
        </>
      ) : null}

      {showChallengers ? (
        <>
          <span className={styles.key}>
            {timeLeft ? "Users who've joined" : 'Users who joined'}
          </span>
          <div className={styles.usersHolder}>
            {data.challenge.users.map(userRender)}
          </div>
        </>
      ) : null}

      {timeLeft &&
        (user ? (
          <button
            onClick={() => {
              setIsJoinOpen(true);
            }}
            className={styles.joinChallengeButton}
            type="button"
            disabled={userInChallenge}
          >
            {userInChallenge ? (
              <>
                <CheckIcon />
                <span>Registered</span>
              </>
            ) : (
              'Join the challenge'
            )}
          </button>
        ) : (
          <Link
            className={styles.joinChallengeButton}
            to="/signup?signupType=rewards"
          >
            Sign in to register
          </Link>
        ))}

      <span className={styles.key}>Company website</span>
      <a
        className={styles.link}
        href={data.challenge.url}
        target="_blank"
        rel="noreferrer"
      >
        {new URL(data.challenge.url).host}
        <ExternalLinkIcon />
      </a>

      <span className={styles.key}>Country</span>
      <span className={styles.value}>
        {data.challenge.country?.longName || 'Worldwide'}
      </span>

      <Suspense fallback={null}>
        <ReactMarkdown className={styles.markdownDiv}>
          {data.challenge.description}
        </ReactMarkdown>
      </Suspense>

      <Dialog
        open={isSuccessOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setIsSuccessOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Share Tern with friends
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            We would really appreciate if you could let your friends know about
            Tern so we can grow the ecosystem and get more people cycling,
            walking and running instead of using the car.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setIsSuccessOpen(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigator.share({
                title: 'Tern - the green commuting app',
                text: 'Automatically track when you cycle, walk and run to work, see your CO2 savings over time and win sustainable rewards!',
                url: 'https://terncommute.com',
              });
              setIsSuccessOpen(false);
            }}
          >
            Share
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isJoinOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setIsJoinOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Join the challenge
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Only commutes after you join will count. Best of luck, and cyclists
            don&apos;t forget to charge those bike lights!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setIsJoinOpen(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              try {
                await joinChallenge({ variables: { id } });
              } catch (mutationError) {
                setErrorJoiningChallenge(true);
              }

              mixpanel.track('Challenge Joined', {
                company: data.challenge.company.name,
                title: data.challenge.title,
              });

              setIsJoinOpen(false);

              if (navigator.share) {
                setIsSuccessOpen(true);
              }
            }}
            color="primary"
            disabled={loadingJoiningChallenge}
          >
            {loadingJoiningChallenge ? 'Joining...' : 'Join'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={errorJoiningChallenge}
        autoHideDuration={6000}
        onClose={() => {
          setErrorJoiningChallenge(false);
        }}
      >
        <Alert
          onClose={() => {
            setErrorJoiningChallenge(false);
          }}
          severity="error"
          elevation={6}
          variant="filled"
        >
          Sorry it failed to join the challenge, please try again.
        </Alert>
      </Snackbar>
      {user && user.id === '1' && (
        <AdminUploadImage
          storageLocation={`web/challenges/${id}`}
          handleDownloadUrl={async (downloadURL) => {
            alert(downloadURL);
          }}
          size={1000}
        />
      )}
    </div>
  );
}
