export default function PrivacyPolicy() {
  return (
    <div>
      <h1>Privacy policy</h1>
      <em>Last updated: Nov 13, 2023</em>
      <p>
        We, the developers of Tern, value your privacy and will uphold the
        highest standards to protect it. By using Tern or by visiting our
        website, you acknowledge that you accept the practices and policies
        outlined in this Privacy Policy.
      </p>
      <h4>Where is my data hosted?</h4>
      Our infrastructure is hosted on a world leading cloud provider{' '}
      <a href="https://www.heroku.com/policy/security">Heroku</a>, the company
      is owned by Salesforce. They continually manage risk and undergo recurring
      assessments to ensure compliance with industry standards, their data
      center operations have been accredited under:
      <ul>
        <li>ISO 27001</li>
        <li>SOC 1 and SOC 2/SSAE 16/ISAE 3402 (Previously SAS 70 Type II)</li>
        <li>PCI Level 1</li>
        <li>FISMA Moderate</li>
        <li>Sarbanes-Oxley (SOX)</li>
      </ul>
      <h4>What information do we collect?</h4>
      <p>
        When you grant location and activity type permissions to the Tern app we
        store your data in our private and highly secure database.
      </p>
      <p>
        We use this data to then calculate when a user performs a commute. Data
        on device type, operating system, and the user experience is
        automatically collected when using our product and solely used to
        optimise and improve the usability of our products and the website.
      </p>
      <h4>What information do we show publicly?</h4>
      <p>
        The users first name, last name and office is shown on their public Tern
        profile with aggregated stats of their commute behavior.
      </p>
      <p>
        The amount of commute data that is shown depends if the user has chosen
        to have private or public route maps in their profile settings.
      </p>
      <h5>Private route maps</h5>
      <p>
        If set to private only the commute meta data is shown. This includes
        date of commute, wether it&#39;s from or to work, time taken, distance,
        average speed and pace.
      </p>
      <h5>Public route maps</h5>
      <p>
        If you have public route maps then the trips latitude and longitude
        points are shown on a map in addition to meta data. We do however
        truncate the start or end of the route that is not the office by a
        random distance to hide sensitive locations like the users home.
      </p>
      <p>
        Note that all time information, including start and end times are always
        private.
      </p>
      <h4>Why do we collect user data?</h4>
      <p>
        We collect data from you to generate your commuting profile, this will
        show your active commuting stats on our app and its leaderboards, such
        as trips completed and distance traveled.
      </p>
      <h4>Penetration testing</h4>
      <p>
        Tern has undergone penetration testing from{' '}
        <a href="https://www.accesshq.com/">AccessHQ</a>, using a third party to
        review our systems helps us make sure we implement best in class
        security practices.
      </p>
      <h4>Email Communications</h4>
      <p>
        If we receive an email from you or a communication via a social network,
        we use that information to best serve you through the channel which you
        contacted us.
      </p>
      <h4>Changes to this Privacy Policy</h4>
      <p>
        We may amend this Privacy Policy from time to time. Use of information
        we collect now is subject to the Privacy Policy in effect at the time
        such information is used. If we make changes in the way we use
        information, we will update this page. Users are bound by any changes to
        the Privacy Policy when he or she uses the product or website after such
        changes have been first posted.
      </p>
      <h4>Questions or Concerns</h4>
      <p>
        If you have any questions or concerns regarding privacy, please send us
        a detailed message to{' '}
        <a href="mailto:info@terncommute.com">info@terncommute.com</a>. We will
        make every effort to resolve your concerns.
      </p>
      <h4 id="delete-account">Requesting data deletion</h4>
      <p>
        You can delete your account and remove all data associated with it by
        visiting your profile page and clicking the <i>Delete my account now</i>{' '}
        button at the bottom.
      </p>
    </div>
  );
}
