import './firebase';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-dates/initialize';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import mixpanel from 'mixpanel-browser';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { SkeletonTheme } from 'react-loading-skeleton';
import reportWebVitals from './reportWebVitals';
import Routes from './Routes';
import client from './client';
import 'moment/locale/en-gb';
import { SnackbarProvider } from './hooks/useSnackbar';

if (window.location.hostname.endsWith('pedal-web.herokuapp.com')) {
  window.location = `${process.env.PUBLIC_URL}${window.location.pathname}${window.location.search}`;
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  track_pageview: false,
  debug: process.env.REACT_APP_DEBUG_TRACKING === 'true',
});
window.mixpanel = mixpanel;

const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
      contrastText: '#fff',
    },
    black: {
      main: '#000',
      light: '#e6e9ed',
      dark: '#7e8594',
      contrastText: '#4caf50',
    },
  },
});

function Index() {
  return (
    <ApolloProvider client={client}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <SkeletonTheme color="#eee" highlightColor="#f5f5f5">
              <SnackbarProvider>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </SnackbarProvider>
            </SkeletonTheme>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
