import { Helmet } from 'react-helmet';
import albert from './albert.jpg';
import mark from './mark.jpeg';
import mabel from './mabel.jpeg';
import styles from './AboutUs.module.css';

const team = [
  {
    profilePic: albert,
    name: 'Albert Still',
    email: 'albert@terncommute.com',
    linkedIn: 'https://linkedin.com/in/albertstill',
    github: 'https://github.com/albertstill',
    bio: `Hi I'm Albert, the founder of Tern.

I've been an active commuter my whole career, and when I moved to
Sydney from London I was surprised to discover how few cyclists were
on the road.

Reading about some governments and businesses rewarding people to
cycle or walk to work, I started wondering if we could use the
technology in our pockets to power a platform that gamifies and
rewards green travel.

In 2020 we launched Tern onto the Android and iOS app stores and it
struck a chord with commuters and workplaces all over the globe.

Just because we invented the combustion engine, it doesn't mean we
need to use it to travel a few kilometres down the road instead of our
legs.

I fundamentally believe the cities of the future will have less cars
and more active commuters, it's better for our planet, our health
& wellbeing and the places we live.`,
  },
  {
    profilePic: mark,
    name: 'Mark Amicucci-Brown',
    email: 'mark@terncommute.com',
    linkedIn: 'https://www.linkedin.com/in/mark-brown-726b603',
    bio: `Hi I'm Mark, the commercial lead at Tern, based in the UK.`,
  },
  {
    profilePic: mabel,
    name: 'Mabel Still',
    email: 'info@terncommute.com',
    linkedIn: 'https://www.linkedin.com/in/mabel-still-37123a220',
    bio: `Hi I'm Mabel, the Tern intern, I'm currently doing a MSc in Transport Engineering and Planning.`,
  },
];

export default function AboutUs() {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>About the team from Tern Commute</title>
        <meta
          name="description"
          content="The team who built the Tern commuting app."
        />
      </Helmet>
      {team.map((user) => (
        <div key={user.name} className={styles.memberContainer}>
          <div>
            <img
              src={user.profilePic}
              className={styles.image}
              alt={`${user.name} profile pic`}
            />
          </div>
          <div className={styles.text}>
            <div>
              <h2 className={styles.name}>{user.name}</h2>
              <a className={styles.profile} href={`mailto:${user.email}`}>
                Email
              </a>
              <span className={styles.dot}> &#8226; </span>
              <a className={styles.profile} href={user.linkedIn}>
                LinkedIn
              </a>
              {user.github && (
                <>
                  <span className={styles.dot}> &#8226; </span>
                  <a
                    className={styles.profile}
                    href="https://github.com/albertstill"
                  >
                    GitHub
                  </a>
                </>
              )}
            </div>
            {user.bio.split('\n\n').map((para) => (
              <p key={Math.random()}>{para}</p>
            ))}
          </div>
        </div>
      ))}
      <section className={styles.businessDetails}>
        <h2>Business details</h2>
        <p>Australian Business Number (ABN): 68 219 924 753</p>
        <p>Registered Business Address: 39 Lamrock Ave, Bondi Beach, 2026</p>
      </section>
    </div>
  );
}
