import { useHistory } from 'react-router-dom';
import { MdKeyboardBackspace } from 'react-icons/md';
import styles from './BackButton.module.css';

export default function BackButton() {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <button
        className={styles.backButton}
        type="button"
        onClick={() => {
          history.goBack();
        }}
        aria-label="Go back"
      >
        <MdKeyboardBackspace />
      </button>
    </div>
  );
}
