import { Link, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import styles from './Home.module.css';
import usePageViews from '../hooks/usePageViews';
import Footer from './Footer';
import Pricing from './Pricing';
import Header from './Header';
import challengesRewards from './challenges-rewards.png';
import AboutUs from './AboutUs';
import PrivacyPolicy from './PrivacyPolicy';
import TotalStats from './TotalStats';
import ContactUs from './ContactUs';
import creLogo from './cre.svg';
import sidespaceLogo from './sidespace.png';
import cyclingElectric from './cycling-electric.png';
import domainLogo from './domain.svg';
import smartCitiesDiveLogo from './smart-cities-dive.svg';
import tfnswLogo from './tfnsw.png';
import AppLinks from './AppLinks';
import UserFeatures from './UserFeatures';
import WorkplaceFeatures from './WorkplaceFeatures';
import GetStartedModel from './GetStartedModel';
import SubscribeToNewsletter from './SubscribeToNewsletter';

export default function Home() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  usePageViews({ section: 'website' });

  const location = useLocation();

  useEffect(() => {
    const currentQps = new URLSearchParams(location.search);

    switch (currentQps.get('alert-message')) {
      case 'strava-success':
        alert(
          'Successfully linked your Strava with Tern, now you can head back to the app.'
        );
        break;
      case 'strava-no-scope':
        alert(
          'Strava linking failed, we need you to allow "View data about your private activities" for the linking to work.'
        );
        break;

      default:
        break;
    }
  }, [location]);

  return (
    <div className={styles.container}>
      <div className={styles.hero}>
        <Header />
        <div className={styles.routeWrapper}>
          <Route strict exact path="/">
            <div className={styles.heroContainer}>
              <div className={styles.contentFlexItem}>
                <h1 className={styles.slogan}>
                  Tern is the active commuting app for workplaces who care about
                  employee wellbeing and achieving their ESG goals.
                </h1>
                <AppLinks />
                <h2 className={styles.subHeading}>For HR teams</h2>
                <p>
                  Tern offers an innovative health and wellbeing benefit,
                  incentivising and rewarding employees for every sustainable
                  active trip to the workplace.
                </p>
                <h2 className={styles.subHeading}>For sustainability teams</h2>
                <p>
                  Tern&apos;s sophisticated tracking helps you measure and
                  reduce Scope 3 carbon emissions, which include employee
                  commuting.
                </p>

                <h2 className={styles.subHeading}>For employees</h2>
                <p>
                  Using the Tern app to track active commutes means earning
                  rewards, improving health and wellbeing whilst reducing carbon
                  emissions.
                </p>
                <h2 className={styles.subHeading}>How the app works</h2>
                <UserFeatures />

                <div className={styles.bottomLinks}>
                  <GetStartedModel
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                  />
                  <button
                    className={styles.getStartedButton}
                    type="button"
                    onClick={() => {
                      mixpanel.track('Get Started Free Click', {
                        location: 'Homepage',
                      });
                      setIsDialogOpen(true);
                    }}
                  >
                    Get started free
                  </button>{' '}
                  or{' '}
                  <Link
                    onClick={() => {
                      mixpanel.track('Learn More Click', {
                        source: 'Homepage',
                      });
                    }}
                    to="/businesses"
                  >
                    learn more!
                  </Link>
                </div>
              </div>
              <div className={styles.phoneHolder}>
                <TotalStats />
                <div className={styles.smartPhone}>
                  <iframe
                    title="the app"
                    className={styles.iframe}
                    src="/app/?timeFrame=this-year"
                  />
                </div>
              </div>
            </div>
          </Route>
          <Route strict exact path="/about-us" component={AboutUs} />
          <Route strict exact path="/businesses">
            <Helmet>
              <title>
                Active commuting app for sustainable workplaces wanting to beat
                their ESG goals
              </title>
              <meta
                name="description"
                content="Promote and reward the active commute and lower your workplaces carbon footprint, helping with the fight against climate change. Watch your stats in real time."
              />
            </Helmet>
            <h1>Tern adds value to your business critical ESG plan</h1>

            <p>
              Tern combines a smart commuting app for employees, with a powerful
              data rich dashboard for employers to measure sustainable commutes
              and reductions in carbon emissions.
            </p>

            <WorkplaceFeatures />

            <section className={styles.sections}>
              <h2>Other benefits</h2>
              <ul>
                <li>
                  Promote, gamify and reward green commuting and create an
                  employee culture of sustainability.
                </li>
                <li>
                  Access to the Tern API to integrate data with your
                  sustainability tools or platforms.
                </li>
                <li>CO2 reporting for annual reports.</li>
                <li>
                  Real time embedable Tern widget for your website showing your
                  CO2 saved.
                </li>
                <li>
                  <a href="https://www.bmj.com/content/357/bmj.j1456">
                    Healthier
                  </a>
                  ,{' '}
                  <a href="https://theconversation.com/walking-and-cycling-to-work-makes-commuters-happier-and-more-productive-117819">
                    happier
                  </a>
                  , and more{' '}
                  <a href="https://journals.humankinetics.com/doi/10.1123/jsep.19.3.249">
                    productive
                  </a>{' '}
                  co-workers.
                </li>
                <li>
                  Reduced parking costs, less traffic and cleaner air in the
                  places we work and live.
                </li>
                <li>
                  Active commuters take less{' '}
                  <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4766368/">
                    sick days
                  </a>
                  .
                </li>
              </ul>
              <p>
                Our free plan costs nothing and you can sign up now by
                downloading the app, please see{' '}
                <Link to="/pricing-and-rewards">
                  enterprise pricing & monthly rewards
                </Link>{' '}
                for more.
              </p>
              <h2>Intro slides</h2>

              <iframe
                title="Google docs intro slides plugin"
                src="https://docs.google.com/presentation/d/e/2PACX-1vQ40tlE20VwUVfdBW6DJVwvIVql1TXnBLMoeNWUcX2Z86d0AI4DsQ6WehJXSGl4_f87MbiwrMAhkW90/embed?start=false&loop=true&delayms=3000"
                frameBorder="0"
                width="100%"
                height="569"
                allowFullScreen
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
              />
            </section>
          </Route>

          <Route strict exact path="/brands">
            <Helmet>
              <title>Green travel app for sustainable brands</title>
              <meta
                name="description"
                content="Run rewards and challenges and have green minded cyclists, walkers and runners engage with your brand."
              />
            </Helmet>
            <section className={styles.sections}>
              <div className={styles.brandFlex}>
                <div>
                  <h1>
                    Create a link between your brand and green minded cyclists,
                    walkers and runners
                  </h1>
                  <p>
                    We speak to a broad and diverse audience who all care about
                    using their bicycle or trainers to go from A to B instead of
                    the car. We let you run your own tailor made rewards and
                    challenges to engage with our users.
                  </p>
                  <ul>
                    <li>
                      Access a large community of green minded cyclists, walkers
                      and runners.
                    </li>
                    <li>Non-intrusive brand content.</li>
                    <li>
                      Your own dedicated profile page on the app, giving you a
                      chance to tell your story and link out to your content.
                    </li>
                    <li>
                      Your brand will be included in our email and notification
                      marketing.
                    </li>
                    <li>Real time data and insights.</li>
                  </ul>
                </div>

                <img
                  className={styles.brandImage}
                  src={challengesRewards}
                  alt="Promo"
                />
              </div>
            </section>
          </Route>

          <Route
            strict
            exact
            path="/pricing-and-rewards"
            render={() => (
              <>
                <Helmet>
                  <title>Enterprise pricing plans</title>
                  <meta name="robots" content="noindex" />
                </Helmet>
                <Pricing />
              </>
            )}
          />

          <Route
            path="/privacy-policy"
            render={() => (
              <>
                <Helmet>
                  <title>Privacy policy</title>
                </Helmet>
                <PrivacyPolicy />
              </>
            )}
            exact
          />
          <Route
            path="/contact-us"
            render={() => (
              <>
                <Helmet>
                  <title>Contact us</title>
                </Helmet>
                <ContactUs />
              </>
            )}
            exact
          />
          <Route
            path="/request-a-demo"
            render={() => (
              <>
                <Helmet>
                  <title>Request a demo</title>
                </Helmet>
                <ContactUs isRequestADemo />
              </>
            )}
            exact
          />
        </div>
      </div>

      <section className={styles.badgeSection}>
        <span className={styles.whosTalking}>
          We&#39;re endorsed by the Australian government, click{' '}
          <a href="https://opendata.transport.nsw.gov.au/get-on-your-bike-tern-commute-virtuous-cycle-and-bike-party">
            here
          </a>{' '}
          to learn more
        </span>
        <div className={styles.logoHolders}>
          <a href="https://opendata.transport.nsw.gov.au/get-on-your-bike-tern-commute-virtuous-cycle-and-bike-party">
            <img src={tfnswLogo} alt="Transport for NSW logo" />
          </a>
        </div>
      </section>

      <section className={styles.badgeSection}>
        <span className={styles.whosTalking}>
          See who&#39;s talking about us
        </span>
        <div className={styles.logoHolders}>
          <a href="https://www.smartcitiesdive.com/news/the-complimentary-benefits-of-active-commuting-during-coronavirus/583146/">
            <img src={smartCitiesDiveLogo} alt="Smart Cities Dive logo" />
          </a>
          <a href="https://www.commercialrealestate.com.au/news/the-new-app-encouraging-employees-to-reduce-their-carbon-footprint-933221">
            <img src={creLogo} alt="Commercial Real Estate logo" />
          </a>
          <a href="https://www.cyclingelectric.com/news/tern-app-active-travel-reward">
            <img
              src={cyclingElectric}
              alt="Cycling Electric logo"
              className={styles.cyclingElectric}
            />
          </a>
          <a href="https://www.sidespace.com.au/media/tern-app-encourages-employees-to-cycle-to-work">
            <img src={sidespaceLogo} alt="Sidespace logo" />
          </a>
          <a href="https://www.commercialrealestate.com.au/news/the-new-app-encouraging-employees-to-reduce-their-carbon-footprint-933221">
            <img src={domainLogo} alt="Commercial Real Estate logo" />
          </a>
        </div>
      </section>

      <section className={styles.badgeSection}>
        <SubscribeToNewsletter />
      </section>

      <Footer />
    </div>
  );
}
