import { useState } from 'react';
import { TextField, Button, Alert } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import styles from './ContactUs.module.css';

const SUBMIT_FORM = gql`
  mutation SubmitForm($input: ContactUsInput!) {
    contactUs(input: $input)
  }
`;

export default function ContactUs({ isRequestADemo = false }) {
  const formFields = [
    {
      name: 'firstName',
      label: 'First name',
      type: 'text',
      multiline: false,
      initialValue: '',
      required: true,
    },
    {
      name: 'lastName',
      label: 'Last name',
      type: 'text',
      multiline: false,
      initialValue: '',
      required: true,
    },
    {
      name: 'organisationName',
      label: 'Organisation name',
      type: 'text',
      multiline: false,
      initialValue: '',
      required: true,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      multiline: false,
      initialValue: '',
      required: true,
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'tel',
      multiline: false,
      initialValue: '',
      required: false,
    },
    {
      name: 'website',
      label: 'Website',
      type: 'text',
      multiline: false,
      initialValue: '',
      required: true,
    },
    !isRequestADemo && {
      name: 'message',
      label: 'Message',
      type: 'text',
      multiline: true,
      initialValue: '',
      required: true,
      rows: 4,
    },
    isRequestADemo && {
      name: 'numberOfEmployees',
      label: 'How many employees work here?',
      type: 'text',
      multiline: false,
      initialValue: '',
      required: true,
    },
  ].filter(Boolean);

  const initialState = formFields.reduce((acc, { name, initialValue }) => {
    acc[name] = initialValue;
    return acc;
  }, {});

  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [submitForm, { data, loading, error }] = useMutation(SUBMIT_FORM);

  return (
    <div className={styles.container}>
      <h1>{isRequestADemo ? 'Request a demo' : 'Contact us'}</h1>
      {isRequestADemo ? (
        <>
          <p>Thanks for reaching out.</p>
          <p>
            Please complete the form below and we&apos;ll get back to you within
            24 hours to schedule a 25-30 minute walkthrough of the Tern
            platform, and answer any questions you have.
          </p>
        </>
      ) : (
        <h4>
          Thanks for reaching out, will aim to get back to you in 24 hours.
        </h4>
      )}
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await submitForm({
            variables: {
              input: {
                ...formData,
                numberOfEmployees: Number(formData.numberOfEmployees),
              },
            },
          });

          setFormData(initialState);
        }}
        className={styles.form}
      >
        {formFields.map((field) => (
          <TextField
            className={styles.textField}
            key={field.name}
            label={field.label}
            name={field.name}
            type={field.type}
            multiline={field.multiline}
            required={field.required}
            rows={field.rows || 1}
            value={formData[field.name]}
            onChange={handleChange}
            fullWidth
          />
        ))}
        {error && (
          <Alert severity="error">
            We failed to submit your enquiry, please contact us on
            info@terncommute.com
          </Alert>
        )}
        {data?.contactUs && (
          <Alert severity="success">
            Your enquiry has been submitted, we will be in touch shortly.
          </Alert>
        )}
        <Button
          className={styles.button}
          disabled={loading}
          variant="contained"
          type="submit"
          color="primary"
        >
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
      </form>
    </div>
  );
}
