import { useState, useEffect, useContext, lazy, Suspense } from 'react';
import { useQuery, gql } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import { LinearProgress } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';
import { toWords } from 'number-to-words';
import { Helmet } from 'react-helmet';
import ActionSheetContext from './context/action-sheet';
import Card from './Card';
import useSearchFilters from './hooks/useSearchFilters';
import useIsSignedIn from './hooks/useIsSignedIn';
import Loading from './Loading';
import Calendar from './Calendar';
import Filters from './Filters';
import ProfileImage from './ProfileImage';
import CommuteCards from './CommuteCards';
import CreateManualCommute from './CreateManualCommute';
import UserDebugTracking from './UserDebugTracking';
import styles from './User.module.css';
import useUser from './hooks/useUser';
import { H_AND_K_COMPANY_ID } from './constants';
import NO_INDEX_USERS from './utils/no-index-users.json';

const ShareImage = lazy(() =>
  import(/* webpackChunkName: "shareimage" */ './ShareImage')
);
const Confetti = lazy(() =>
  import(/* webpackChunkName: "react-confetti" */ 'react-confetti')
);

export const USER_STATS = gql`
  query UsersStats(
    $userId: ID!
    $commutesAfter: String
    $commutesBefore: String
    $activityType: ActivityType!
    $startOfMonth: String!
  ) {
    user(id: $userId) {
      id
      firstName
      lastName
      name
      photoURL
      commutes(
        filters: {
          commutesAfter: $commutesAfter
          commutesBefore: $commutesBefore
          activityType: $activityType
        }
      ) {
        commuteStats {
          totalCommutes
          totalDistance
          totalTrees
          totalCO2
        }
      }

      monthlyTripsTarget
      monthProgress: commutes(filters: { commutesAfter: $startOfMonth }) {
        commuteStats {
          totalCommutes
        }
      }

      office {
        id
        address

        company {
          id
          name
          logoImageUrl
        }
      }
    }
  }
`;

export const ALL_COMMUTES = gql`
  query UserAllCommutes($userId: ID!) {
    user(id: $userId) {
      id
      commutes {
        edges {
          node {
            __typename
            ... on Commute {
              id
              toWork
              fromWork
              dateOfCommute
              activityType
            }

            ... on ManualCommute {
              id
              toWork
              fromWork
              dateOfCommute
              activityType
            }
          }
        }
      }

      lastLocationRecordedAt
    }
  }
`;

function User({ userId }) {
  const setActionSheetContents = useContext(ActionSheetContext);
  const [selectedDay, setSelectedDay] = useState(null);
  const {
    activityType,
    activityTypeEnum,
    commutesAfter,
    commutesBefore,
    timeFrame,
    isDefaultSort,
    sort,
  } = useSearchFilters();

  const location = useLocation();
  const isSignedIn = useIsSignedIn();
  const user = useUser();
  const [openShare, setOpenShare] = useState(false);

  const qp = new URLSearchParams();
  if (activityType) qp.set('activityType', activityType);
  if (timeFrame) qp.set('timeFrame', timeFrame);
  if (!isDefaultSort) qp.set('sort', sort);

  const currentQps = new URLSearchParams(location.search);
  const shouldShowCommutesTo = currentQps.has('showCommutesTo');
  const showDebug =
    currentQps.has('debug') ||
    process.env.NODE_ENV === 'development' ||
    (user && user.id === '1');

  const { error: statsError, data: statsData } = useQuery(USER_STATS, {
    variables: {
      userId,
      activityType: activityTypeEnum,
      commutesAfter,
      commutesBefore,
      startOfMonth: moment().startOf('month').toISOString(),
    },
  });

  const firebaseNotDoneAuthCheck = isSignedIn === null;
  const {
    error: allError,
    data: allCommuteData,
    loading: loadingAllCommutes,
  } = useQuery(ALL_COMMUTES, {
    skip: firebaseNotDoneAuthCheck,
    variables: { userId },
  });

  useEffect(() => {
    if (selectedDay) {
      return;
    }

    if (!firebaseNotDoneAuthCheck && loadingAllCommutes === false) {
      if (
        allCommuteData &&
        allCommuteData.user &&
        allCommuteData.user.commutes.edges.length
      ) {
        setSelectedDay(
          moment(
            allCommuteData.user.commutes.edges[
              allCommuteData.user.commutes.edges.length - 1
            ].node.dateOfCommute
          )
        );
      } else {
        // to match above instantiation
        setSelectedDay(moment().startOf('day'));
      }
    }
  }, [
    firebaseNotDoneAuthCheck,
    selectedDay,
    loadingAllCommutes,
    allCommuteData,
  ]);

  if (allError || statsError) {
    return (
      <p>
        {`Error: ${
          process.env.NODE_ENV === 'production'
            ? (allError || statsError).message
            : JSON.stringify(allError || statsError)
        }`}
      </p>
    );
  }

  if (!statsData) return <Loading />;

  const isCurrentUser = user && userId === user.id;

  const notInsideBottomSheet = !!setActionSheetContents;

  let progressComponent = null;

  if (
    isCurrentUser &&
    statsData &&
    statsData.user.monthlyTripsTarget !== null
  ) {
    const totalTripsThisMonth =
      statsData.user.monthProgress.commuteStats.totalCommutes;

    const value = Math.min(
      Math.round(
        (totalTripsThisMonth / statsData.user.monthlyTripsTarget) * 100
      ),
      100
    );

    const progressCopy =
      value === 100
        ? `target of ${statsData.user.monthlyTripsTarget} reached, you've done ${totalTripsThisMonth}!`
        : `${totalTripsThisMonth} out of ${statsData.user.monthlyTripsTarget} trips`;

    progressComponent = (
      <div className={styles.progressContainer}>
        <p
          className={styles.progressTitle}
        >{`This months progress: ${progressCopy}`}</p>
        <LinearProgress variant="determinate" value={value} />
      </div>
    );
  }

  const now = moment();
  const isDecember = now.month() === 11;
  const isStartOfJan = now.month() === 0 && now.date() <= 14;
  const showYearInReview =
    (showDebug || isCurrentUser) && (isDecember || isStartOfJan);

  let theYearMoment;
  if (showYearInReview) {
    theYearMoment = moment();
    if (isStartOfJan) theYearMoment.subtract(1, 'year');
  }

  const statsDataContent = (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`${process.env.PUBLIC_URL}/app/users/${userId}`}
        />
        <title>{`${statsData.user.name}'s active commuting stats`}</title>
        <meta
          name="description"
          content={`View ${statsData.user.name}'s active commuting stats for their commutes to ${statsData.user.office.company.name}, this includes cycling, running and walking to work.`}
        />
        {NO_INDEX_USERS.includes(statsData.user.id) && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <ProfileImage user={statsData.user} isCurrentUser={isCurrentUser} />
      <h1 className={styles.header}>{statsData.user.name}</h1>

      <Filters
        noSort
        commutes={
          !statsData ? null : statsData.user.commutes.commuteStats.totalCommutes
        }
        distance={
          !statsData ? null : statsData.user.commutes.commuteStats.totalDistance
        }
        CO2={!statsData ? null : statsData.user.commutes.commuteStats.totalCO2}
        trees={
          !statsData ? null : statsData.user.commutes.commuteStats.totalTrees
        }
      />

      {showYearInReview ? (
        <>
          <button
            type="button"
            onClick={() => {
              mixpanel.track('Year In Review Opened');
              setOpenShare(true);
            }}
            className={styles.yearInReview}
          >
            {openShare
              ? 'Opening...'
              : `See your ${theYearMoment.year()} in review!`}
          </button>

          <Suspense>
            <Confetti recycle={false} />
          </Suspense>

          {openShare ? (
            <Suspense>
              <ShareImage
                closeShare={() => {
                  setOpenShare(false);
                }}
                userId={userId}
                theYearMoment={theYearMoment}
              />
            </Suspense>
          ) : null}
        </>
      ) : null}

      {(setActionSheetContents || shouldShowCommutesTo) && (
        <div className={styles.commutesToContainer}>
          <div className={styles.commutesToLinks}>
            <span className={styles.commutesTo}>Commutes to</span>

            {notInsideBottomSheet && (
              <Link className={styles.seeAll} to={`/?${qp}`}>
                See all offices
              </Link>
            )}
          </div>

          <Card
            noBottomSheet
            title={statsData.user.office.company.name}
            subtitle={statsData.user.office.address}
            image={{
              url: statsData.user.office.company.logoImageUrl,
              seed: statsData.user.office.company.id,
              char: statsData.user.office.company.name.charAt(0),
            }}
            link={`/offices/${statsData.user.office.id}?${qp}`}
          />
        </div>
      )}
    </>
  );

  if (!allCommuteData) {
    return (
      <>
        {statsDataContent}
        <Skeleton />
        <Skeleton width="80%" />
        <Skeleton width="60%" />
        <Skeleton />
      </>
    );
  }

  const { edges } = allCommuteData.user.commutes;
  const filteredCommutes = edges.filter(
    ({ node: commute }) =>
      selectedDay && moment(commute.dateOfCommute).isSame(selectedDay, 'day')
  );

  let anotherUsersProfileCopy = null;
  let isCurrentUsersProfileCopy = null;

  if (selectedDay) {
    const localSelectedDate = new Intl.DateTimeFormat(undefined, {
      dateStyle: 'full',
    }).format(moment(selectedDay).toDate());

    const countWord = toWords(filteredCommutes.length);

    anotherUsersProfileCopy = (
      <span className={isCurrentUser ? undefined : styles.notUserCopy}>
        <span className={styles.commuteCount}>
          {/* upper case the first letter */}
          {`${countWord[0].toUpperCase()}${countWord.slice(1)}`}
        </span>
        <span>
          {` commute${
            filteredCommutes.length === 1 ? '' : 's'
          } on ${localSelectedDate}`}
        </span>
        .
      </span>
    );

    isCurrentUsersProfileCopy = (
      <span>
        {filteredCommutes.length ? (
          anotherUsersProfileCopy
        ) : (
          <span>{`No commutes registered on ${localSelectedDate}.`}</span>
        )}
      </span>
    );
  }

  return (
    <>
      {statsDataContent}
      {edges.length ? null : isCurrentUser ? (
        <>
          <h3>No commutes yet...</h3>
          <p>Get active commuting {statsData.user.firstName}! </p>
          <p>
            If you did do an active commute and it didn&apos;t register it,
            please go to{' '}
            <Link className={styles.link} to="/settings">
              settings
            </Link>{' '}
            and review your commute tracking configuration.
          </p>
        </>
      ) : null}

      {progressComponent}

      <Calendar
        commutes={allCommuteData.user.commutes.edges.map(({ node }) => node)}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
      />

      {isCurrentUser ? (
        <CreateManualCommute
          isStrictOnDays={
            user
              ? [H_AND_K_COMPANY_ID].includes(user?.office.company.id)
              : false
          }
          copy={isCurrentUsersProfileCopy}
          selectedDay={selectedDay}
          userId={userId}
          setSelectedDay={setSelectedDay}
          lastLocationRecordedAt={allCommuteData.user.lastLocationRecordedAt}
        />
      ) : (
        anotherUsersProfileCopy
      )}

      {filteredCommutes.length ? (
        <CommuteCards
          ids={filteredCommutes.map(({ node: { id } }) => id)}
          userId={userId}
          isUser={isCurrentUser}
        />
      ) : null}
      {showDebug ? <UserDebugTracking userId={userId} /> : null}
    </>
  );
}

export default User;
