import { useMemo } from 'react';
import { DayPicker } from 'react-dates';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';
import moment from 'moment';
import styles from './Calendar.module.css';

export default function Calendar({ commutes, setSelectedDay, selectedDay }) {
  const values = useMemo(() => {
    return commutes.reduce(
      (map, { activityType, dateOfCommute, toWork, fromWork }) => {
        const dayId = moment(dateOfCommute).format('YYYY-MM-DD');

        if (map.has(dayId)) {
          const obj = map.get(dayId);

          const activeCount =
            obj.activeCount + (activityType !== 'VEHICLE' ? 1 : 0);

          if (toWork) {
            map.set(dayId, {
              ...obj,
              activeCount,
              toWork,
              toWorkActivityType:
                obj.toWorkActivityType !== undefined &&
                obj.toWorkActivityType !== 'VEHICLE'
                  ? obj.toWorkActivityType
                  : activityType,
            });
            return map;
          }

          if (fromWork) {
            map.set(dayId, {
              ...obj,
              activeCount,
              fromWork,
              fromWorkActivityType:
                obj.fromWorkActivityType !== undefined &&
                obj.fromWorkActivityType !== 'VEHICLE'
                  ? obj.fromWorkActivityType
                  : activityType,
            });
            return map;
          }
        } else {
          map.set(dayId, {
            toWork,
            fromWork,
            fromWorkActivityType: fromWork ? activityType : undefined,
            toWorkActivityType: toWork ? activityType : undefined,
            activeCount: activityType !== 'VEHICLE' ? 1 : 0,
          });
        }

        return map;
      },
      new Map()
    );
  }, [commutes]);

  const initialVisibleMonth = () =>
    moment(
      commutes[commutes.length - 1] &&
        commutes[commutes.length - 1].dateOfCommute
    );

  const monthClick = (newMonth) => {
    const commute = commutes
      .slice(0)
      .reverse()
      .find(({ dateOfCommute }) => {
        return moment(dateOfCommute).isSame(newMonth, 'month');
      });

    if (commute) {
      setSelectedDay(moment(commute.dateOfCommute));
    }
  };

  let heatmapStartDate = moment().subtract(12, 'months');

  if (commutes.length) {
    heatmapStartDate = moment.min(
      moment(commutes[0].dateOfCommute),
      heatmapStartDate
    );
  }

  const today = moment();

  const differenceInDays = moment
    .duration(today.diff(heatmapStartDate))
    .asDays();

  return (
    <>
      <div className={styles.heatmapContainer}>
        <div
          className={styles.heatmapContainerInner}
          style={{
            // keeps about 6 months on the screen
            width: `${(differenceInDays / 365) * 700}px`,
          }}
        >
          <CalendarHeatmap
            startDate={heatmapStartDate.toDate()}
            endDate={today.toDate()}
            values={Array.from(values.entries()).map(([key, value]) => ({
              date: key,
              activeCount: value.activeCount,
            }))}
            classForValue={(value) => {
              return value && value.activeCount >= 1
                ? styles.hasACommute
                : styles.heatmapTile;
            }}
          />
        </div>
      </div>
      <div className={styles.container}>
        <DayPicker
          daySize={44}
          horizontalMonthPadding={0}
          initialVisibleMonth={initialVisibleMonth}
          numberOfMonths={1}
          firstDayOfWeek={1}
          hideKeyboardShortcutsPanel
          noBorder
          onPrevMonthClick={monthClick}
          onNextMonthClick={monthClick}
          renderCalendarDay={({ day, key }) => {
            // I believe this is a false positive
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            if (!day) return <td key={key} className={styles.td} />;

            const isSelectedDay = day.isSame(selectedDay, 'day');

            const val = values.get(day.format('YYYY-MM-DD'));

            const handler = () => {
              setSelectedDay(day.clone());
            };

            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <td
                key={key}
                onKeyPress={handler}
                onClick={handler}
                className={[styles.td, isSelectedDay && styles.selectedDay]
                  .filter(Boolean)
                  .join(' ')}
              >
                <span>{day.format('D')}</span>
                <div className={styles.ballHolder}>
                  {!isSelectedDay && val
                    ? [
                        val.toWork &&
                          (val.toWorkActivityType === 'VEHICLE'
                            ? styles.commuteVehicleBall
                            : styles.commuteGreenBall),
                        val.fromWork &&
                          (val.fromWorkActivityType === 'VEHICLE'
                            ? styles.commuteVehicleBall
                            : styles.commuteGreenBall),
                      ]
                        .filter(Boolean)
                        .map((style) => (
                          <span key={Math.random()} className={style} />
                        ))
                    : null}
                </div>
              </td>
            );
          }}
        />
      </div>
    </>
  );
}
