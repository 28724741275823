import moment from 'moment';
import { useEffect, useState } from 'react';

const now = moment();

const handleUpdateTimeLeft = (endTime) => {
  if (!endTime || now.isAfter(endTime)) return null;

  const diff = moment.duration(moment(endTime).diff(moment()));
  const diffString = `${Math.floor(
    diff.asDays()
  )}d ${diff.hours()}h ${diff.minutes()}m ${diff.seconds()}s`;

  return diffString;
};

export default function useTimeLeft(endTime) {
  const [timeLeft, updateTimeLeft] = useState(handleUpdateTimeLeft(endTime));

  useEffect(() => {
    updateTimeLeft(handleUpdateTimeLeft(endTime));
    const interval = setInterval(
      () => updateTimeLeft(handleUpdateTimeLeft(endTime)),
      1000
    );

    return () => {
      clearInterval(interval);
    };
  }, [endTime]);

  return timeLeft;
}
