import { Link } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import styles from './RaffleResults.module.css';

const Confetti = lazy(() =>
  import(/* webpackChunkName: "react-confetti" */ 'react-confetti')
);

export default function RaffleResults() {
  return (
    <div>
      <h1>Domain Group raffles 🎉</h1>
      <div>
        <p>
          A Tern CO2 Raffle works by awarding the commuter a digital raffle
          ticket for every kg of CO2 saved.
        </p>
        <b>April 2021</b>
        <p>Domain funded 3 x $200 Rebel Sports vouchers.</p>
        <p>
          During the
          <Link
            className={styles.companyLink}
            to="/companies/1?timeFrame=last-month"
          >
            {` month `}
          </Link>
          there were 423 trips, 1,678 km traveled and 304kg of CO2 saved over
          four offices.
        </p>
        <p>The three winners are:</p>
        <ul>
          <li>Kate Witton</li>
          <li>Ivan Karajas</li>
          <li>Tim Anido</li>
        </ul>
      </div>
      <Suspense fallback={null}>
        <Confetti recycle={false} />
      </Suspense>
    </div>
  );
}
