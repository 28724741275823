// Note old version of app had no number after Tern/android
function ternVersion() {
  const match = navigator.userAgent.match(/Tern\/(android|ios)\/?(\d*)/);

  // it's a browser
  if (!match) return null;

  return {
    isIOS: match[1] === 'ios',
    isAndroid: match[1] === 'android',
    version: match[2] ? parseInt(match[2], 10) : null,
  };
}

export default ternVersion();
