import { useQuery, gql } from '@apollo/client';
import useSearchFilters from './hooks/useSearchFilters';
import Card from './Card';
import Filters from './Filters';
import { sortFunction } from './shared/sort';

function Offices() {
  const {
    activityType,
    timeFrame,
    activityTypeEnum,
    commutesAfter,
    commutesBefore,
    sort,
    isDefaultSort,
    country,
  } = useSearchFilters();
  const qp = new URLSearchParams();
  if (activityType) qp.set('activityType', activityType);
  if (timeFrame) qp.set('timeFrame', timeFrame);
  if (!isDefaultSort) qp.set('sort', sort);

  const { loading, error, data } = useQuery(
    gql`
      query Offices(
        $commutesAfter: String
        $commutesBefore: String
        $activityType: ActivityType!
        $countryCode: String
      ) {
        countries {
          longName
          shortName
        }

        offices(countryCode: $countryCode) {
          id
          address
          commuteStats(
            filters: {
              commutesAfter: $commutesAfter
              commutesBefore: $commutesBefore
              activityType: $activityType
            }
          ) {
            totalCommutes
            totalDistance
            totalTrees
            totalCO2
          }
          company {
            id
            name
            logoImageUrl
          }
        }
      }
    `,
    {
      variables: {
        commutesAfter,
        commutesBefore,
        activityType: activityTypeEnum,
        countryCode: country,
      },
    }
  );

  if (error) {
    return (
      <p>
        {`Error: ${
          process.env.NODE_ENV === 'production'
            ? error.message
            : JSON.stringify(error)
        }`}
      </p>
    );
  }

  return (
    <>
      <Filters countries={loading ? null : data.countries} />

      {loading
        ? new Array(10)
            .fill(null)
            // eslint-disable-next-line react/no-array-index-key
            .map((_, index) => <Card key={index} loading />)
        : data.offices
            .slice(0)
            .sort((a, b) => sortFunction(a, b, sort))
            .map(({ address, company, id, commuteStats }, index) => {
              return (
                <Card
                  key={id}
                  title={company.name}
                  subtitle={address}
                  image={{
                    url: company.logoImageUrl,
                    seed: company.id,
                    char: company.name.charAt(0),
                  }}
                  link={`/offices/${id}?${qp}`}
                  stats={{
                    commutes: commuteStats.totalCommutes,
                    distance: commuteStats.totalDistance,
                    CO2: commuteStats.totalCO2,
                    trees: commuteStats.totalTrees,
                  }}
                  position={index}
                />
              );
            })}
    </>
  );
}

export default Offices;
