import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { activityTypeOptions } from '../ActivityType';
import { sortOptions } from '../shared/sort';
import isH1 from '../utils/is-h1';

const START_OF_TERN = 2020;

export const timeFrameOptions = [
  {
    value: 'today',
    label: 'Today',
    commutesAfter: moment().startOf('day').toISOString(),
  },
  {
    value: 'yesterday',
    label: 'Yesterday',
    commutesAfter: moment().subtract(1, 'day').startOf('day').toISOString(),
    commutesBefore: moment().subtract(1, 'day').endOf('day').toISOString(),
  },
  {
    value: 'this-week',
    label: 'This week',
    commutesAfter: moment().startOf('isoWeek').toISOString(),
  },
  {
    value: 'last-week',
    label: 'Last week',
    commutesAfter: moment()
      .subtract(1, 'week')
      .startOf('isoWeek')
      .toISOString(),
    commutesBefore: moment().subtract(1, 'week').endOf('isoWeek').toISOString(),
  },
  {
    value: '',
    label: 'This month',
    commutesAfter: moment().startOf('month').toISOString(),
  },
  {
    value: 'last-month',
    label: 'Last month',
    commutesAfter: moment().subtract(1, 'month').startOf('month').toISOString(),
    commutesBefore: moment().subtract(1, 'month').endOf('month').toISOString(),
  },
  {
    value: 'this-year',
    label: 'This year',
    commutesAfter: moment().startOf('year').toISOString(),
  },

  {
    value: 'last-year',
    label: 'Last year',
    commutesAfter: moment().subtract(1, 'year').startOf('year').toISOString(),
    commutesBefore: moment().subtract(1, 'year').endOf('year').toISOString(),
  },

  {
    value: 'this-half',
    label: `This half (${isH1 ? 'H1' : 'H2'})`,
    commutesAfter: isH1
      ? moment().startOf('year').toISOString()
      : moment().startOf('year').add(6, 'months').toISOString(),
  },

  {
    value: 'last-half',
    label: `Last half (${isH1 ? 'H2' : 'H1'})`,
    commutesAfter: isH1
      ? moment().startOf('year').subtract(6, 'months').toISOString()
      : moment().startOf('year').toISOString(),
    commutesBefore: isH1
      ? moment().startOf('year').toISOString()
      : moment().startOf('year').add(6, 'months').toISOString(),
  },

  ...new Array(moment().year() - START_OF_TERN - 1)
    .fill(null)
    .map((_, index) => {
      const year = moment().year() - index - 2;
      const theYearMoment = moment({ year });

      return {
        value: year.toString(),
        label: year.toString(),
        commutesAfter: theYearMoment.startOf('year').toISOString(),
        commutesBefore: theYearMoment.endOf('year').toISOString(),
      };
    }),
  {
    value: 'ever',
    label: 'Ever',
  },
];

const defaultTimeFrame = timeFrameOptions.find(({ value }) => value === '');

export default function useSearchFilters() {
  const location = useLocation();
  const qp = new URLSearchParams(location.search);

  const activityType = qp.has('activityType')
    ? qp.get('activityType')
    : 'mixed';

  const country = qp.get('country');

  const sort = qp.has('sort') ? qp.get('sort') : sortOptions[0].value;
  const isDefaultSort = !qp.has('sort');

  const activityTypeLabel = activityTypeOptions.find(
    ({ value }) =>
      value === activityType || (activityType === 'mixed' && value === '')
  ).label;

  const timeFrame = qp.has('timeFrame')
    ? qp.get('timeFrame')
    : defaultTimeFrame.value;

  const { commutesAfter, commutesBefore } =
    timeFrameOptions.find(({ value }) => value === timeFrame) ||
    defaultTimeFrame;

  return {
    timeFrame,

    activityType: qp.get('activityType'),
    activityTypeEnum: activityType.toUpperCase(),
    activityTypeLabel,

    commutesAfter,
    commutesBefore,

    sort,
    isDefaultSort,

    country,
  };
}
