/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
  FormHelperText,
} from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { IoMdAddCircleOutline as AddIcon } from 'react-icons/io';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Skeleton from 'react-loading-skeleton';
import { useState } from 'react';
import CreateNewOffice from './CreateNewOffice';
import styles from './OfficeSelector.module.css';

export const OFFICE_NOT_LISTED = 'OFFICE_NOT_LISTED';

const filter = createFilterOptions();

function generateUserCount(count) {
  if (typeof count !== 'number') return '';

  if (count === 0) return `No users yet`;

  if (count === 1) return `1 user`;

  return `${count} users`;
}

export default function OfficeSelector({
  company,
  updateCompany,
  officeId,
  updateOfficeID,
  office,
  updateOffice,
  isUpdate = false,
}) {
  const isCreateNewCompany = company && !company.id;

  const isCreateNewOffice = officeId === OFFICE_NOT_LISTED;

  const companiesQuery = gql`
    query AllCompanies {
      companies {
        id
        name
        userCount
      }
    }
  `;
  const { loading: companiesLoading, data: companiesData } =
    useQuery(companiesQuery);

  const officesQuery = gql`
    query AllOffices($companyId: ID!) {
      company(id: $companyId) {
        offices {
          id
          address
          userCount
        }
      }
    }
  `;
  const { loading: officesLoading, data: officesData } = useQuery(
    officesQuery,
    {
      variables: { companyId: company ? company.id : undefined },
      skip: !company || isCreateNewCompany,
    }
  );

  const [companyError, setCompanyError] = useState(false);
  const [officeError, setOfficeError] = useState(false);

  const officeInputId = 'office-select';

  return (
    <>
      {companiesLoading ? (
        <Skeleton width="60%" />
      ) : (
        <FormControl className={styles.formControl}>
          <Autocomplete
            className={styles.companySelect}
            autoHighlight
            value={company}
            onChange={(event, newValue) => {
              updateOfficeID('');
              updateOffice(null);

              // cleared
              if (!newValue) {
                updateCompany(null);
                return;
              }

              updateCompany(newValue);
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (
                params.inputValue !== '' &&
                !options.some(({ name }) => name === params.inputValue)
              ) {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Create "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="company-select"
            options={companiesData.companies.map((item) => ({
              id: item.id,
              name: item.name,
              userCount: item.userCount,
            }))}
            getOptionLabel={(option) => {
              // empty string on initial load
              if (typeof option === 'string') {
                return option;
              }

              // New company
              if (option.inputValue) {
                return option.inputValue;
              }

              // Regular option
              return option.name;
            }}
            renderOption={(props, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...props}>
                <span>{option.name}</span>{' '}
                <span className={styles.companyUserCountRenderOption}>
                  {generateUserCount(option.userCount)}
                </span>
              </li>
            )}
            freeSolo
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                inputProps={{ ...params.inputProps, required: true }}
                label="Company"
                variant="standard"
                error={companyError}
                onBlur={() => {
                  if (!company) setCompanyError(true);
                }}
                onInvalid={() => {
                  setCompanyError(true);
                }}
                helperText={
                  companyError
                    ? 'We need a company to link your profile to.'
                    : [
                        company && company.inputValue
                          ? `Will create a new company and link you to it.`
                          : null,
                        company && company.id && !isUpdate
                          ? `Will link you to the existing company.`
                          : null,
                        !company &&
                          'Search for an existing company or create a new one. Please be careful not to create a new company if it exists already, this will cause duplicates.',
                      ]
                        .filter(Boolean)
                        .join(' ')
                }
              />
            )}
            onOpen={() => {
              setCompanyError(false);
            }}
            isOptionEqualToValue={(option, value) => {
              if (value) {
                if (value.id) {
                  return option.id === value.id;
                }

                if (value.inputValue) {
                  return option.inputValue === value.inputValue;
                }
              }

              return false;
            }}
          />
        </FormControl>
      )}

      {officesLoading ? (
        <Skeleton width="60%" />
      ) : officesData && !isCreateNewCompany ? (
        <FormControl
          variant="standard"
          className={styles.formControl}
          error={officeError}
        >
          <InputLabel htmlFor={officeInputId}>Office</InputLabel>
          <Select
            required
            className={styles.officeSelect}
            id={officeInputId}
            value={officeId}
            onChange={(event) => {
              setOfficeError(false);
              updateOfficeID(event.target.value);
              updateOffice(null);
            }}
            disabled={officesLoading}
            inputProps={{ required: true }}
            onInvalid={() => {
              setOfficeError(true);
            }}
            // went to trigger the error on close when nothing selected
            // but caused a race condition when you selected a choice
            // and the onClose handler still had no reference to an officeId
            autoWidth
            renderValue={(value) => {
              if (value === OFFICE_NOT_LISTED) return 'Create a new offfice';

              const foundOffice = officesData.company.offices.find(
                ({ id }) => value === id
              );

              return foundOffice.address;
            }}
          >
            <MenuItem
              classes={{ root: styles.create }}
              value={OFFICE_NOT_LISTED}
            >
              Create a new office
              <AddIcon className={styles.addIcon} />
            </MenuItem>
            {officesData.company.offices.map((officeOfList) => (
              <MenuItem key={officeOfList.id} value={officeOfList.id}>
                <div>
                  <span>{officeOfList.address}</span>
                  <span className={styles.officeUserCountRenderOption}>
                    {generateUserCount(officeOfList.userCount)}
                  </span>
                </div>
              </MenuItem>
            ))}
          </Select>
          {officeError && (
            <FormHelperText>
              Please select an existing office or select &quot;Create a new
              office&quot;
            </FormHelperText>
          )}
        </FormControl>
      ) : null}

      {(isCreateNewOffice || isCreateNewCompany) && (
        <CreateNewOffice office={office} updateOffice={updateOffice} />
      )}
    </>
  );
}
