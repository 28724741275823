import styles from './LinksToWebsite.module.css';

export default function LinksToWebsite() {
  return (
    <ul className={styles.ul}>
      <li className={styles.li}>
        <a href="https://terncommute.com/about-us#tern">About us</a>
      </li>

      <li className={styles.li}>
        <a href="https://terncommute.com/privacy-policy#tern">Privacy policy</a>
      </li>
    </ul>
  );
}
