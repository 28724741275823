import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import styles from './ActivityType.module.css';
import { WalkingIcon, RunningIcon, CyclingIcon } from './Icons';

export const activityTypeOptions = [
  { value: '', label: 'All' },
  { value: 'cycling', label: 'Cycling' },
  { value: 'walking', label: 'Walking' },
  { value: 'running', label: 'Running' },
];

export const activityTypeGraphQLEnumOptions = [
  { value: 'CYCLING', label: 'Cycling' },
  { value: 'WALKING', label: 'Walking' },
  { value: 'RUNNING', label: 'Running' },
];

function ActivityType() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    const navEle = document.querySelector('.scroll-into-view');

    if (navEle && navEle.scrollIntoViewIfNeeded)
      navEle.scrollIntoViewIfNeeded(false);
  }, []);

  return (
    <div className={styles.scrollableContainer}>
      {activityTypeOptions.map(({ value, label }) => {
        const sp = new URLSearchParams(search);
        if (value) {
          sp.set('activityType', value);
        } else {
          sp.delete('activityType');
        }

        let activityTypeIcon;
        switch (value.toUpperCase()) {
          case 'CYCLING':
            activityTypeIcon = (
              <CyclingIcon className={styles.activityIconCycling} />
            );
            break;
          case 'WALKING':
            activityTypeIcon = <WalkingIcon className={styles.activityIcon} />;
            break;
          case 'RUNNING':
            activityTypeIcon = <RunningIcon className={styles.activityIcon} />;
            break;
          default:
            activityTypeIcon = false;
        }

        return (
          <NavLink
            key={value}
            to={`${pathname}?${sp}`}
            exact
            className={styles.link}
            activeClassName={`${styles.linkChecked} scroll-into-view`}
            isActive={(match, location) => {
              const urlSp = new URLSearchParams(location.search);
              if (!urlSp.get('activityType') && value === '') return true;
              return urlSp.get('activityType') === value;
            }}
            onClick={() => {
              mixpanel.track('Activity type change', { activityType: label });
            }}
          >
            {activityTypeIcon}
            <span>{label}</span>
          </NavLink>
        );
      })}
    </div>
  );
}

export default ActivityType;
