import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

export default function usePageViews(props) {
  const location = useLocation();
  useEffect(() => {
    window.gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, {
      page_path: location.pathname,
      page_title: document.title,
    });

    mixpanel.track('Page View', {
      pathName: window.location.pathname,
      search: window.location.search,
      fullPath: window.location.pathname + window.location.search,
      ...props,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
}
