export const SORT_DISTANCE_VALUE = 'totalDistance';
const SORT_COMMUTES_VALUE = 'totalCommutes';

export const sortOptions = [
  // top is default
  {
    value: SORT_DISTANCE_VALUE,
    label: 'distance',
  },
  {
    value: SORT_COMMUTES_VALUE,
    label: 'trips',
  },
];

export function sortFunction(a, b, sort) {
  const orderedOptions = sortOptions
    .slice(0)
    .sort((x) => {
      return x.value === sort ? -1 : 0;
    })
    .map(({ value }) => value);

  for (const sortOption of orderedOptions) { // eslint-disable-line
    if (b.commuteStats[sortOption] !== a.commuteStats[sortOption]) {
      return b.commuteStats[sortOption] - a.commuteStats[sortOption];
    }
  }

  return 0;
}
