import { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import styles from './WelcomeInstructions.module.css';
import useIsSignedIn from './hooks/useIsSignedIn';

const LOCALSTORAGE_KEY = 'tern__has-shown-welcome-message';

export default function WelcomeInstructions() {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const isSignedIn = useIsSignedIn();

  useEffect(() => {
    if (!window.ReactNativeWebView || isSignedIn === null) {
      return;
    }

    const hasShownAlready = localStorage.getItem(LOCALSTORAGE_KEY);

    const setLocalStorage = () => {
      localStorage.setItem(LOCALSTORAGE_KEY, new Date().getTime());
    };

    if (isSignedIn === true && !hasShownAlready) {
      setLocalStorage();
      return;
    }

    if (!hasShownAlready) {
      mixpanel.track('Welcome Modal Show');
      setLocalStorage();
      setOpen(true);
    }
  }, [isSignedIn]);

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={styles.modal}>
        <div className={styles.top}>
          <h1 className={styles.logo}>Tern</h1>
        </div>
        <div className={styles.bottom}>
          <h2 className={styles.subtitle}>The sustainable commuting app.</h2>
          <p>
            Thanks for downloading, to start tracking your active commutes you
            will need to create an account and tell us the company and office
            address you commute to.
          </p>

          <div className={styles.buttons}>
            <Button
              className={styles.button}
              variant="outlined"
              onClick={() => {
                mixpanel.track('Welcome Modal Skip');
                handleClose();
              }}
            >
              Just browse
            </Button>
            <Button
              className={styles.button}
              variant="contained"
              onClick={() => {
                mixpanel.track('Welcome Modal Sign Up');
                handleClose();
              }}
              component={RouterLink}
              to="/signup?signupType=welcome"
              color="primary"
            >
              Track my commutes
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
