import { MemoryRouter, useHistory } from 'react-router-dom';
import { MdKeyboardBackspace } from 'react-icons/md';
import { useEffect } from 'react';
import styles from './BottomSheetRouter.module.css';
import { BottomSheetRoutes } from './App';

function BackButton({ history }) {
  if (!history.canGo(-1)) return null;

  return (
    <button
      className={styles.backButton}
      type="button"
      onClick={() => {
        history.goBack();
      }}
      aria-label="Go back"
    >
      <MdKeyboardBackspace />
    </button>
  );
}

function Contents({ topLevelSetActionSheetContents, topLevelHistory }) {
  // this is the memory history
  const history = useHistory();

  useEffect(() => {
    const oldHistory = window.Pedal.history;
    window.Pedal.history = history;

    const originalGoBack = history.goBack;

    history.goBack = () => {
      if (!history.canGo(-1)) {
        topLevelSetActionSheetContents(null);
        return;
      }

      originalGoBack();
    };

    return () => {
      window.Pedal.history = oldHistory;
    };
  }, [history, topLevelSetActionSheetContents]);

  return (
    <BottomSheetRoutes
      topLevelSetActionSheetContents={topLevelSetActionSheetContents}
      topLevelHistory={topLevelHistory}
      topContent={<BackButton history={history} />}
    />
  );
}

export default function BottomSheetRouter({
  to,
  setActionSheetContents,
  history,
}) {
  return (
    <MemoryRouter initialEntries={[to]} initialIndex={0}>
      <Contents
        topLevelSetActionSheetContents={setActionSheetContents}
        topLevelHistory={history}
      />
    </MemoryRouter>
  );
}
