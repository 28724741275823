/* eslint-disable no-new */
import { useEffect, useRef } from 'react';
import styles from './CommuteMap.module.css';
import createMap from './utils/google-maps';

const ActivityTypeColors = new Map([
  ['on_foot', 'pink'],
  ['walking', 'pink'],
  ['on_bicycle', 'blue'],
  ['running', 'green'],
  ['in_vehicle', 'red'],
  ['still', 'orange'],
]);

export default function CommuteMap({ commute, showActivityColours = true }) {
  const mapRef = useRef(null);

  useEffect(() => {
    const map = createMap(mapRef.current, {}, showActivityColours);

    const seq = {
      repeat: '50px',
      icon: {
        path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
        scale: 1,
        fillOpacity: 0,
        strokeWeight: 1,
        strokeOpacity: 1,
      },
    };

    if (showActivityColours) {
      commute.locations.forEach((segment) => {
        segment.forEach(({ activityType, latLng: { latitude, longitude } }) => {
          new window.google.maps.Circle({
            fillColor: ActivityTypeColors.get(activityType) || '#ccc',
            fillOpacity: 1,
            map,
            center: {
              lat: latitude,
              lng: longitude,
            },
            radius: 9,
            strokeWeight: 0,
          });
        });
      });
    }

    commute.locations.forEach((segment) => {
      const path = segment.map(({ latLng: { latitude, longitude } }) => ({
        lat: latitude,
        lng: longitude,
      }));

      const isVehicleCommute = commute.activityType === 'VEHICLE';

      let isVehicleSegment = false;

      if (commute.isMultiMode) {
        isVehicleSegment = segment[0].activityType === 'in_vehicle';
      }

      if (isVehicleCommute) {
        isVehicleSegment = true;
      }

      new window.google.maps.Polyline({
        map,
        path,
        geodesic: true,
        strokeColor: isVehicleSegment ? 'grey' : '#F53A08',
        strokeOpacity: 1.0,
        strokeWeight: showActivityColours ? 0.6 : 2,
        icons: [seq],
      });
    });

    // join segments with grey
    for (let i = 0; i < commute.locations.length - 1; i += 1) {
      const firstLatLng = {
        lat: commute.locations[i][commute.locations[i].length - 1].latLng
          .latitude,
        lng: commute.locations[i][commute.locations[i].length - 1].latLng
          .longitude,
      };
      const lastLatLng = {
        lat: commute.locations[i + 1][0].latLng.latitude,
        lng: commute.locations[i + 1][0].latLng.longitude,
      };
      new window.google.maps.Polyline({
        map,
        path: [firstLatLng, lastLatLng],
        geodesic: true,
        strokeColor: 'grey',
        strokeOpacity: 1.0,
        strokeWeight: showActivityColours ? 0.6 : 2,
        icons: [seq],
      });
    }

    const flatLocations = commute.locations.flat();

    new window.google.maps.Circle({
      strokeColor: '#000',
      strokeOpacity: 0.7,
      strokeWeight: 2,
      fillColor: '#000',
      fillOpacity: 0.7,
      map,
      center: {
        lat: flatLocations[0].latLng.latitude,
        lng: flatLocations[0].latLng.longitude,
      },
      radius: 110,
    });

    const lastCoord = flatLocations[flatLocations.length - 1].latLng;

    new window.google.maps.Rectangle({
      strokeColor: '#000',
      strokeOpacity: 0.7,
      strokeWeight: 2,
      fillColor: '#000',
      fillOpacity: 0.7,
      map,
      bounds: {
        north: lastCoord.latitude - 0.0013,
        south: lastCoord.latitude + 0.0013,
        east: lastCoord.longitude + 0.0013,
        west: lastCoord.longitude - 0.0013,
      },
    });

    const llb = flatLocations.reduce(
      (acc, { latLng: { latitude, longitude } }) => {
        acc.extend({
          lat: latitude,
          lng: longitude,
        });
        return acc;
      },
      new window.google.maps.LatLngBounds()
    );

    map.fitBounds(llb, 5);
  }, [
    commute.locations,
    commute.isMultiMode,
    showActivityColours,
    commute.activityType,
  ]);

  return <div className={styles.mapContainer} ref={mapRef} />;
}
