/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { gql, useMutation } from '@apollo/client';
import mixpanel from 'mixpanel-browser';
import imageCompression from 'browser-image-compression';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { IconButton } from '@mui/material';
import UserThumbnailImage from './UserThumbnailImage';
import styles from './ProfileImage.module.css';

import { USER_QUERY } from './hooks/useUser';

export default function ProfileImage({ user, isCurrentUser }) {
  const [isUploadingFirebase, setIsUploadingFirebase] = useState(false);
  const [isUploadingFirebaseError, setIsUploadingFirebaseError] =
    useState(false);
  const [updateUser, { error: isUpdatingUserError, loading: isUpdatingUser }] =
    useMutation(
      gql`
        mutation UpdateUser($user: UpdateUserInput!) {
          updateUser(user: $user) {
            id
          }
        }
      `,
      {
        refetchQueries: () => [
          {
            query: USER_QUERY,
            variables: { firebaseAuthUid: getAuth().currentUser.uid },
          },
        ],
        awaitRefetchQueries: true,
      }
    );

  if (!user.photoURL && !isCurrentUser) {
    return null;
  }

  if (!isCurrentUser)
    return (
      <div className={styles.profileImageHolder}>
        {/* no fallback class means it will render null if no image or fails to load image */}
        <UserThumbnailImage user={user} imageClass={styles.profileImage} />
      </div>
    );

  const iconButtonId = 'icon-button-file';

  return (
    <>
      <div className={styles.profileImageHolder}>
        <UserThumbnailImage
          user={user}
          imageClass={styles.profileImage}
          fallbackClass={styles.fallBackImage}
        />
        <div className={styles.iconContainer}>
          <label htmlFor={iconButtonId}>
            <IconButton
              className={styles.button}
              size="small"
              aria-label="upload picture"
              component="span"
            >
              <PhotoCamera className={styles.icon} />
            </IconButton>
          </label>
          <input
            accept="image/*"
            className={styles.input}
            id={iconButtonId}
            type="file"
            onChange={async (event) => {
              setIsUploadingFirebase(true);
              let downloadURL;
              try {
                const storage = getStorage();
                const profileImagesRef = ref(
                  storage,
                  `web/profile_photos/${user.id}`
                );

                const compressedFile = await imageCompression(
                  event.target.files[0],
                  {
                    maxWidthOrHeight: 150,
                  }
                );

                const uploadTaskSnapshot = await uploadBytes(
                  profileImagesRef,
                  compressedFile,
                  {
                    cacheControl: 'public, max-age=31536000',
                    contentType: compressedFile.type,
                  }
                );

                downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

                console.log('File available at ', downloadURL);
              } catch (error) {
                setIsUploadingFirebaseError(true);
                throw error;
              }

              setIsUploadingFirebase(false);
              await updateUser({
                variables: {
                  user: {
                    photoURL: downloadURL,
                  },
                },
              });

              mixpanel.track('Uploaded Profile Photo');
            }}
          />
        </div>
      </div>
      <div className={styles.messageContainer}>
        {isUpdatingUser || (isUploadingFirebase && <p>Loading...</p>)}
        {isUploadingFirebaseError && isUpdatingUserError && (
          <p>
            Sorry there was an error updating your profile picture, it has been
            reported and we are onto it.
          </p>
        )}
      </div>
    </>
  );
}
