import mixpanel from 'mixpanel-browser';
import { useContext } from 'react';
import { round } from 'lodash';
import styles from './StatsRow.module.css';
import { CloudIcon, TreeIcon } from './Icons';
import ActionSheetContext from './context/action-sheet';
import Info from './Info';
import useUser from './hooks/useUser';
import { H_AND_K_COMPANY_ID } from './constants';

export default function StatsRow({
  isFilters,
  isManualCommute,
  commutes,
  distance,
  CO2,
  trees,
  averageSpeed,
  paceDuration,
  totalDuration,
  noSort,
}) {
  const setActionSheetContents = useContext(ActionSheetContext);
  const isDetailedCommuteCard = !!averageSpeed;

  const user = useUser();
  const isHK = user?.office?.company?.id === H_AND_K_COMPANY_ID;

  return (
    <div
      className={`${styles.container} ${
        isManualCommute ? styles.manualCommuteContainer : ''
      }`}
    >
      {commutes !== undefined && (
        <>
          <div className={styles.child}>
            <span data-testid="trips-count" className={styles.statAmount}>
              {commutes === null ? '-' : commutes.toLocaleString()}
            </span>
            <span className={styles.statTitle}>Trips</span>
          </div>
          {/* noSort means it's the user profile page */}
          {isHK && noSort && (
            <div className={styles.child}>
              <span
                data-testid="time-in-lieu-count"
                className={styles.statAmount}
              >
                {commutes === null
                  ? '-'
                  : ((commutes * (5 / 60)) / 7).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </span>
              <span className={styles.statTitle}>Days</span>
            </div>
          )}
        </>
      )}
      {distance !== undefined && (
        <div className={styles.child}>
          <span data-testid="trips-distance" className={styles.statAmount}>
            {distance === null ? (
              '-'
            ) : (
              <>
                {isDetailedCommuteCard
                  ? `${round(distance / 1000, 2).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : `${Math.round(distance / 1000).toLocaleString()}`}
                <span
                  className={isDetailedCommuteCard ? styles.unit : undefined}
                >
                  km
                </span>
              </>
            )}
          </span>
          <span className={styles.statTitle}>Travelled</span>
        </div>
      )}
      {CO2 !== undefined && (
        <button
          type="button"
          onClick={() => {
            if (setActionSheetContents) {
              mixpanel.track('Stats Row CO2 Clicked');
              setActionSheetContents(<Info />);
            }
          }}
          className={styles.child}
        >
          <span data-testid="co2-amount" className={styles.statAmount}>
            {CO2 === null
              ? '-'
              : `${
                  CO2 > 10 || CO2 === 0
                    ? Math.round(CO2).toLocaleString()
                    : round(CO2, 2).toLocaleString(undefined, {
                        minimumFractionDigits: isFilters ? 1 : 2,
                        maximumFractionDigits: isFilters ? 1 : 2,
                      })
                }kg`}
          </span>
          <span className={styles.statTitle}>
            {isFilters && <CloudIcon className={styles.cloudIcon} />}
            CO2 Saved
          </span>
        </button>
      )}
      {trees !== undefined && (
        <button
          type="button"
          onClick={() => {
            if (setActionSheetContents) {
              mixpanel.track('Stats Row Tree Clicked');
              setActionSheetContents(<Info />);
            }
          }}
          className={styles.child}
        >
          <span className={styles.statAmount}>
            {trees === null ? '-' : Math.round(trees).toLocaleString()}
          </span>
          <span className={styles.statTitle}>
            <TreeIcon className={styles.treeIcon} />
            Trees
          </span>
        </button>
      )}
      {totalDuration !== undefined && (
        <div className={styles.child}>
          <span className={styles.statAmount}>
            {totalDuration === null
              ? '-'
              : `${`${totalDuration.hours()}`}:${`${totalDuration.minutes()}`.padStart(
                  2,
                  '0'
                )}:${`${totalDuration.seconds()}`.padStart(2, '0')}`}
          </span>
          <span className={styles.statTitle}>Duration</span>
        </div>
      )}
      {averageSpeed !== undefined && (
        <div className={styles.child}>
          <span className={styles.statAmount}>
            {averageSpeed === null ? (
              '-'
            ) : (
              <>
                {round(averageSpeed, 2).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                <span className={styles.unit}>km/h</span>
              </>
            )}
          </span>
          <span className={styles.statTitle}>Avg Speed</span>
        </div>
      )}
      {paceDuration !== undefined && (
        <div className={`${styles.child} ${styles.pace}`}>
          <span className={styles.statAmount}>
            {paceDuration === null ? (
              '-'
            ) : (
              <>
                {`${`${paceDuration.minutes()}`.padStart(
                  2,
                  '0'
                )}:${`${paceDuration.seconds()}`.padStart(2, '0')}`}
                <span className={styles.unit}>/km</span>
              </>
            )}
          </span>
          <span className={styles.statTitle}>Pace</span>
        </div>
      )}
    </div>
  );
}
