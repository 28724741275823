import { BottomSheet } from 'react-spring-bottom-sheet';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './App.module.css';
import ActionSheetContext from './context/action-sheet';

function HandleAndroidBack({ setActionSheetContents }) {
  const history = useHistory();

  useEffect(() => {
    const originalBack = history.goBack;

    history.goBack = () => {
      setActionSheetContents(null);
    };

    return () => {
      history.goBack = originalBack;
    };
  }, [history, setActionSheetContents]);

  return null;
}

export default function AppBottomSheetProvider({ children }) {
  const [actionSheetContents, setActionSheetContents] = useState(null);

  return (
    <>
      <ActionSheetContext.Provider value={setActionSheetContents}>
        {children}
      </ActionSheetContext.Provider>
      <BottomSheet
        open={!!actionSheetContents}
        onDismiss={() => {
          setActionSheetContents(null);
        }}
        snapPoints={({ maxHeight }) => maxHeight * 0.9}
        expandOnContentDrag
      >
        <div className={styles.container}>
          <div className={styles.page}>{actionSheetContents}</div>
        </div>
      </BottomSheet>
      {actionSheetContents && (
        <HandleAndroidBack setActionSheetContents={setActionSheetContents} />
      )}
    </>
  );
}
