import { useQuery, gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import useSearchFilters from './hooks/useSearchFilters';
import useUser from './hooks/useUser';
import Card from './Card';
import Filters from './Filters';
import Loading from './Loading';
import { sortFunction } from './shared/sort';
import styles from './Company.module.css';
import AdminCompanyLogo from './AdminCompanyLogo';

function Offices({ companyId, isWidget }) {
  const [imgLoadingError, updateImgLoadingError] = useState(false);
  const {
    activityType,
    timeFrame,
    activityTypeEnum,
    commutesAfter,
    commutesBefore,
    sort,
    isDefaultSort,
  } = useSearchFilters();
  const qp = new URLSearchParams();
  if (activityType) qp.set('activityType', activityType);
  if (timeFrame) qp.set('timeFrame', timeFrame);
  if (!isDefaultSort) qp.set('sort', sort);

  const user = useUser();

  const { loading, error, data } = useQuery(
    gql`
      query Company(
        $commutesAfter: String
        $commutesBefore: String
        $activityType: ActivityType!
        $companyId: ID!
      ) {
        company(id: $companyId) {
          id
          name
          logoImageUrl
          offices {
            id
            address
            commuteStats(
              filters: {
                commutesAfter: $commutesAfter
                commutesBefore: $commutesBefore
                activityType: $activityType
              }
            ) {
              totalCommutes
              totalDistance
              totalTrees
              totalCO2
            }
          }
        }
      }
    `,
    {
      variables: {
        commutesAfter,
        commutesBefore,
        activityType: activityTypeEnum,
        companyId,
      },
    }
  );

  if (loading && !data) return <Loading />;

  if (error) {
    return (
      <p>
        {`Error: ${
          process.env.NODE_ENV === 'production'
            ? error.message
            : JSON.stringify(error)
        }`}
      </p>
    );
  }

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`${process.env.PUBLIC_URL}/app/companies/${companyId}`}
        />
        <title>{`Active commuting leaderboard for all ${data.company.name}'s offices`}</title>
        <meta
          name="description"
          content={`View ${data.company.name}'s active commuting stats for all their offices, this includes cycling, running and walking to work.`}
        />
      </Helmet>

      {data.company.logoImageUrl && !imgLoadingError && (
        <img
          onError={() => {
            updateImgLoadingError(true);
          }}
          alt="Company logo"
          className={styles.logoImageUrl}
          src={data.company.logoImageUrl}
        />
      )}

      {isWidget ? (
        <h1 className={styles.header}>Active commuting leaderboard</h1>
      ) : (
        <h1 className={styles.header}>{data.company.name}</h1>
      )}

      <Filters
        commutes={
          loading
            ? undefined
            : data.company.offices.reduce(
                (acc, office) => acc + office.commuteStats.totalCommutes,
                0
              )
        }
        distance={
          loading
            ? undefined
            : data.company.offices.reduce(
                (acc, office) => acc + office.commuteStats.totalDistance,
                0
              )
        }
        CO2={
          loading
            ? undefined
            : data.company.offices.reduce(
                (acc, office) => acc + office.commuteStats.totalCO2,
                0
              )
        }
        trees={
          !commutesAfter || loading
            ? undefined
            : data.company.offices.reduce(
                (acc, office) => acc + office.commuteStats.totalTrees,
                0
              )
        }
      />

      {data.company.offices
        .slice(0)
        .filter(({ id }) => id !== '135')
        .sort((a, b) => sortFunction(a, b, sort))
        .map(({ address, id, commuteStats }, index) => {
          return (
            <Card
              key={id}
              title={data.company.name}
              subtitle={address}
              link={`/offices/${id}?${qp}`}
              stats={{
                commutes: commuteStats.totalCommutes,
                distance: commuteStats.totalDistance,
                CO2: commuteStats.totalCO2,
                trees: commuteStats.totalTrees,
              }}
              position={index}
            />
          );
        })}

      {user && user.id === '1' && (
        <AdminCompanyLogo
          name={loading ? undefined : data.company.name}
          companyId={companyId}
        />
      )}
    </>
  );
}

export default Offices;
