import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { getAuth } from 'firebase/auth';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import handleError from './utils/handle-error';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((graphError) => {
      handleError({
        error: graphError,
        tags: [
          'GraphQL',
          operation?.operationName,
          operation?.query?.definitions?.[0]?.operation,
        ].filter(Boolean),
      });
    });
  }

  if (networkError) {
    handleError({
      error: networkError,
      tags: ['GraphQL Network'],
    });
  }
});

const httpLink = createHttpLink();

const authLink = setContext(async (_, { headers }) => {
  const { currentUser } = getAuth();
  if (currentUser) {
    // https://firebase.google.com/docs/reference/js/firebase.User.html#getidtoken
    const jwtToken = await currentUser.getIdToken();
    return {
      headers: {
        ...headers,
        authorization: jwtToken,
      },
    };
  }

  return { headers };
});

export default new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  possibleTypes: {
    CommuteResult: ['Commute', 'ManualCommute'],
  },
  cache: new InMemoryCache(),
  name: 'web',
  connectToDevTools: process.env.NODE_ENV === 'development',
});
