import { useQuery, gql } from '@apollo/client';
import Filters from './Filters';
import UserRow from './UserRow';
import useSearchFilters from './hooks/useSearchFilters';
import { sortFunction } from './shared/sort';

function Users() {
  const {
    activityType,
    activityTypeEnum,
    commutesAfter,
    commutesBefore,
    timeFrame,
    sort,
    isDefaultSort,
    country,
  } = useSearchFilters();

  const qp = new URLSearchParams();
  if (activityType) qp.set('activityType', activityType);
  if (timeFrame) qp.set('timeFrame', timeFrame);
  if (!isDefaultSort) qp.set('sort', sort);
  qp.set('showCommutesTo', '1');

  const { loading, error, data } = useQuery(
    gql`
      query AllUsers(
        $commutesAfter: String
        $commutesBefore: String
        $activityType: ActivityType!
        $countryCode: String
      ) {
        countries {
          longName
          shortName
        }

        users(countryCode: $countryCode) {
          id
          name
          firstName
          lastName
          photoURL
          commutes(
            filters: {
              commutesAfter: $commutesAfter
              commutesBefore: $commutesBefore
              activityType: $activityType
            }
          ) {
            commuteStats {
              totalCommutes
              totalDistance
              totalTrees
              totalCO2
            }
            lastCommuteTimeAgo
          }
        }
      }
    `,
    {
      variables: {
        activityType: activityTypeEnum,
        commutesAfter,
        commutesBefore,
        countryCode: country,
      },
    }
  );

  if (error) {
    return (
      <p>
        {`Error: ${
          process.env.NODE_ENV === 'production'
            ? error.message
            : JSON.stringify(error)
        }`}
      </p>
    );
  }

  return (
    <>
      <Filters
        countries={loading ? null : data.countries}
        commutes={
          loading
            ? null
            : data.users.reduce(
                (acc, user) => acc + user.commutes.commuteStats.totalCommutes,
                0
              )
        }
        distance={
          loading
            ? null
            : data.users.reduce(
                (acc, user) => acc + user.commutes.commuteStats.totalDistance,
                0
              )
        }
        CO2={
          loading
            ? null
            : data.users.reduce(
                (acc, user) => acc + user.commutes.commuteStats.totalCO2,
                0
              )
        }
        trees={
          !commutesAfter || loading
            ? null
            : data.users.reduce(
                (acc, user) => acc + user.commutes.commuteStats.totalTrees,
                0
              )
        }
      />

      {loading
        ? new Array(10)
            .fill(null)
            // eslint-disable-next-line react/no-array-index-key
            .map((_, index) => <UserRow key={index} loading />)
        : data.users
            .slice(0)
            .sort((a, b) => sortFunction(a.commutes, b.commutes, sort))
            .map((user, index) => (
              <UserRow
                key={user.id}
                to={`/users/${user.id}?${qp}`}
                user={user}
                position={index}
                sort={sort}
              />
            ))}
    </>
  );
}

export default Users;
