import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyC-kWK28mN4IQEKm6NIwvEPx3I5sS6JHOY',
  authDomain: 'terncommute.com',
  databaseURL: 'https://pedal-1557034190976.firebaseio.com',
  projectId: 'pedal-1557034190976',
  storageBucket: 'gs://pedal-1557034190976.appspot.com',
  messagingSenderId: '906734412477',
  appId: '1:906734412477:web:ad473c2c640c464d',
};

const app = initializeApp(firebaseConfig);

// debugging and Cypress
window.firebaseApp = app;
window.firebaseAuth = getAuth();
