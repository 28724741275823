import { useState } from 'react';
import randomcolor from 'randomcolor';

export default function UserThumbnailImage({
  user,
  imageClass,
  fallbackClass,
}) {
  const [imgLoadingError, updateImgLoadingError] = useState(false);
  const { id, photoURL, firstName, lastName } = user;

  return photoURL && !imgLoadingError ? (
    <img
      onError={() => {
        updateImgLoadingError(true);
      }}
      alt="User profile"
      className={imageClass}
      src={photoURL}
    />
  ) : fallbackClass ? (
    <div
      style={{
        backgroundColor: randomcolor({
          seed: id,
          luminosity: 'dark',
        }),
      }}
      className={fallbackClass}
    >
      {firstName.charAt(0)}
      {lastName.charAt(0)}
    </div>
  ) : null;
}
