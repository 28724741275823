import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Filters from './Filters';
import OfficeMap from './OfficeMap';
import Loading from './Loading';
import UserRow from './UserRow';
import useUser from './hooks/useUser';
import useSearchFilters from './hooks/useSearchFilters';
import { sortFunction } from './shared/sort';
import styles from './Office.module.css';

function Office({ officeId, topLevelSetActionSheetContents, topLevelHistory }) {
  const isLoggedIn = useUser();
  const {
    activityType,
    activityTypeEnum,
    commutesAfter,
    timeFrame,
    commutesBefore,
    sort,
    isDefaultSort,
  } = useSearchFilters();

  const qp = new URLSearchParams();
  if (activityType) qp.set('activityType', activityType);
  if (timeFrame) qp.set('timeFrame', timeFrame);
  if (!isDefaultSort) qp.set('sort', sort);

  const { loading, error, data, previousData } = useQuery(
    gql`
      query Office(
        $officeId: ID!
        $commutesAfter: String
        $commutesBefore: String
        $activityType: ActivityType!
      ) {
        office(id: $officeId) {
          id
          address
          latLng {
            latitude
            longitude
          }
          company {
            name
            id
          }

          commuteStats(
            filters: {
              commutesAfter: $commutesAfter
              commutesBefore: $commutesBefore
              activityType: $activityType
            }
          ) {
            totalCommutes
            totalDistance
            totalTrees
            totalCO2
          }

          users {
            id
            name
            firstName
            lastName
            photoURL
            commutes(
              filters: {
                commutesAfter: $commutesAfter
                commutesBefore: $commutesBefore
                activityType: $activityType
              }
              officeId: $officeId
            ) {
              commuteStats {
                totalCommutes
                totalDistance
                totalTrees
                totalCO2
              }
              lastCommuteTimeAgo
            }
          }
        }
      }
    `,
    {
      variables: {
        officeId,
        activityType: activityTypeEnum,
        commutesAfter,
        commutesBefore,
      },
    }
  );

  if (error) {
    return (
      <p>
        {`Error: ${
          process.env.NODE_ENV === 'production'
            ? error.message
            : JSON.stringify(error)
        }`}
      </p>
    );
  }

  if (loading && !previousData) {
    return <Loading />;
  }

  const isDomain = (data || previousData).office.company.id === '1';

  const topContent = (
    <>
      <OfficeMap latLng={(data || previousData).office.latLng} />
      <Link to={`/companies/${(data || previousData).office.company.id}`}>
        <h1 className={styles.header}>
          {(data || previousData).office.company.name}
        </h1>
      </Link>
      <h3 className={styles.subHeader}>
        {(data || previousData).office.address}
      </h3>
      {isDomain && (
        <div className={styles.fundsRewards}>
          <span>This company funds rewards for their staff,</span>
          <Link
            className={styles.viewRewardsLink}
            to="/raffles/domain-group"
            onClick={(event) => {
              if (topLevelSetActionSheetContents && topLevelHistory) {
                event.preventDefault();
                topLevelSetActionSheetContents(null);
                topLevelHistory.push('/raffles/domain-group');
              }
            }}
          >
            {` view `}
          </Link>
          <span> the CO2 raffle results 🎉🎉🎉</span>
        </div>
      )}
      {!isLoggedIn && (
        <Link
          to="/signup?signupType=joinOffice"
          onClick={(event) => {
            if (topLevelSetActionSheetContents && topLevelHistory) {
              event.preventDefault();
              topLevelSetActionSheetContents(null);
              topLevelHistory.push('/signup?signupType=joinOffice');
            }
          }}
          className={styles.join}
        >
          Join
        </Link>
      )}
      <Filters
        commutes={loading ? null : data.office.commuteStats.totalCommutes}
        distance={loading ? null : data.office.commuteStats.totalDistance}
        CO2={loading ? null : data.office.commuteStats.totalCO2}
        trees={loading ? null : data.office.commuteStats.totalTrees}
      />
    </>
  );

  return (
    <>
      {data && (
        <Helmet>
          <link
            rel="canonical"
            href={`${process.env.PUBLIC_URL}/app/offices/${officeId}`}
          />
          <title>{`${data.office.company.name}'s active commuting leaderboard for their ${data.office.address} office `}</title>
          <meta
            name="description"
            content={`View ${data.office.company.name}'s active commuting stats for their ${data.office.address} office, this includes cycling, running and walking to work.`}
          />
        </Helmet>
      )}

      {topContent}

      {data && !data.office.users.length && (
        <p className={styles.noUsers}>
          There are no users assigned to this office right now, users can assign
          themself to an office in their app settings.
        </p>
      )}

      {loading
        ? new Array(10)
            .fill(null)
            // eslint-disable-next-line react/no-array-index-key
            .map((_, index) => <UserRow key={index} loading />)
        : data.office.users
            .slice(0)
            .sort((a, b) => sortFunction(a.commutes, b.commutes, sort))
            .map((user, index) => (
              <UserRow
                key={user.id}
                to={`/users/${user.id}?${qp}`}
                user={user}
                position={index}
                sort={sort}
              />
            ))}
    </>
  );
}

export default Office;
