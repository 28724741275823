export default function handleError({ error, tags = [] } = {}) {
  console.log('Handle error called with the following error:');
  console.error(error);
  console.log('Error tags:', tags);

  const customAttributes = tags.reduce((acc, tag) => {
    acc[tag] = true;

    return acc;
  }, {});

  console.log('customAttributes:', customAttributes);

  window.newrelic?.noticeError?.(error, customAttributes);
}
