import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import styles from './ChallengeCard.module.css';
import useTimeLeft from './hooks/useTimeLeft';

export default function ChallengeCard({
  id,
  company: { name, logoImageUrl } = {},
  title,
  challengeShort,
  backgroundImage,
  endTime,
  loading,
  country,
}) {
  const timeLeft = useTimeLeft(endTime);

  if (loading) {
    return (
      <div className={styles.container}>
        <Skeleton height={350} />
      </div>
    );
  }

  return (
    <Link
      to={`/rewards/${id}`}
      className={styles.container}
      style={{
        backgroundImage: `url("${backgroundImage}")`,
      }}
    >
      <div className={styles.countryHolder}>
        <div>
          <span className={styles.countryLabel}>
            {country?.longName || 'Worldwide'}
          </span>
        </div>
      </div>
      <div className={styles.top}>
        <img className={styles.image} src={logoImageUrl} alt="company logo" />
        <div className={styles.topCopy}>
          <h3 className={styles.company}>{name}</h3>
          <span>{title}</span>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomChild}>
          <span>{challengeShort}</span>
          <span className={styles.label}>Challenge</span>
        </div>
        {timeLeft && (
          <div className={styles.bottomChild}>
            <span>{timeLeft}</span>
            <span className={styles.label}>Time left</span>
          </div>
        )}
      </div>
    </Link>
  );
}
