import { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import useUser from './hooks/useUser';
import styles from './TrackingBar.module.css';
import { SIGNUP_ROUTE } from './App';

export default function TrackingBar() {
  const [isTracking, updateIsTracking] = useState(null);
  const user = useUser();

  const isSignupPage = useRouteMatch(SIGNUP_ROUTE);

  useEffect(() => {
    window.Pedal.updateIsTracking = (isRNTracking) => {
      updateIsTracking(JSON.parse(isRNTracking));
    };

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ amITracking: true })
      );
    }
  }, []);

  if (isTracking === null) return null;

  /*
    hiding here due to a employee from ABAX getting confused about the "not enabled"
    state and the form submit button being greyed out

    doing path logic in this component vs JSX in the Route's render methods because I only want the effect
    to fire once, else it would get remounted.
  */
  if (isSignupPage) return null;

  return (
    <Link
      className={isTracking ? styles.isTrackingBar : styles.isNotTrackingBar}
      to={user ? `/settings` : '/signup?signupType=settings'}
      onClick={() => {
        mixpanel.track('Tracking Bar Clicked', { isSignedIn: !!user });
      }}
    >
      {isTracking
        ? 'Commute tracking enabled'
        : `Commute tracking ${
            user ? 'not enabled' : 'not setup please sign in'
          }`}
    </Link>
  );
}
