import React, { createContext, useCallback, useContext, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

// Create a context for the Snackbar
const SnackbarContext = createContext();

// Custom SnackbarProvider component
function SnackbarProvider({ children }) {
  const [snackbarMessage, setSnackbarMessage] = useState(null);

  const openSnackbar = useCallback((message, severity = 'info') => {
    setSnackbarMessage({ message, severity });
  }, []);

  // Function to close the Snackbar
  const closeSnackbar = useCallback(() => {
    setSnackbarMessage(null);
  }, []);

  return (
    <SnackbarContext.Provider value={openSnackbar}>
      {children}
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={snackbarMessage?.severity}
          onClose={closeSnackbar}
        >
          {snackbarMessage?.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}

const useSnackbar = () => {
  const snackbar = useContext(SnackbarContext);
  if (!snackbar) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return snackbar;
};

export { SnackbarProvider, useSnackbar };
