import { useEffect, useCallback } from 'react';
import imageCompression from 'browser-image-compression';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export default function AdminUploadImage({
  storageLocation,
  handleDownloadUrl,
  size,
}) {
  const uploadImage = useCallback(
    async function uploadImage(file) {
      const storage = getStorage();
      const profileImagesRef = ref(storage, storageLocation);

      const compressedFile = await imageCompression(file, {
        maxWidthOrHeight: size,
      });

      const uploadTaskSnapshot = await uploadBytes(
        profileImagesRef,
        compressedFile,
        {
          cacheControl: 'public, max-age=31536000',
          contentType: compressedFile.type,
        }
      );
      console.log('uploadTaskSnapshot:', uploadTaskSnapshot);
      const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

      console.log('File available at ', downloadURL);

      handleDownloadUrl(downloadURL);
    },
    [storageLocation, handleDownloadUrl, size]
  );

  useEffect(() => {
    const listener = (evt) => {
      const dT = evt.clipboardData || window.clipboardData;
      const file = dT.files[0];
      uploadImage(file);
    };

    window.addEventListener('paste', listener);

    return () => {
      window.removeEventListener('paste', listener);
    };
  }, [uploadImage]);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label>
      Upload image
      <input
        accept="image/*"
        type="file"
        onChange={async (event) => {
          uploadImage(event.target.files[0]);
        }}
      />
    </label>
  );
}
