import {
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  TextField,
} from '@mui/material';
import { useMutation, gql, useApolloClient } from '@apollo/client';
import { useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { activityTypeGraphQLEnumOptions } from './ActivityType';
import styles from './UpdateCommuteActivityType.module.css';
import { useSnackbar } from './hooks/useSnackbar';

export default function UpdateCommuteActivityType({
  isChangeActivityTypeModalOpen,
  setIsChangeActivityTypeModalOpen,
  commute,
}) {
  const updateActivityId = 'updated-activity-type';
  const updateDistanceId = 'update-distance-id';

  const initialActivityType = activityTypeGraphQLEnumOptions.some(
    ({ value }) => value === commute.activityType
  )
    ? commute.activityType
    : activityTypeGraphQLEnumOptions[0].value;

  const [activityType, setActivityType] = useState(initialActivityType);
  const openSnackbar = useSnackbar();

  const initialDistance = Math.round(commute.distance / 1000);
  const [distance, setDistance] = useState(initialDistance);

  const apolloClient = useApolloClient();
  const [updateCommute, { loading }] = useMutation(
    gql`
      mutation UpdateCommute($commute: UpdateCommuteInput!) {
        updateCommute(commute: $commute) {
          id
          activityType
          distance
          isMultiMode
        }
      }
    `,
    {
      onCompleted: async () => {
        mixpanel.track('Activity Type Updated', {
          toActivityType: activityType,
          fromActivityType: commute.activityType,
        });
        // playing it really safe here, for example if they were on
        // the user leadboard for x activity type, the stats could be out of date now
        // due to the activity type change that just occurred
        await apolloClient.resetStore();

        openSnackbar('Commute updated successfully.', 'success');
      },
      onError: () => {
        openSnackbar('Commute failed to update.', 'error');
      },
    }
  );

  function resetToInitialForm() {
    setActivityType(initialActivityType);
    setDistance(initialDistance);
  }

  return (
    <Dialog
      open={isChangeActivityTypeModalOpen}
      onClose={() => {
        setIsChangeActivityTypeModalOpen(false);

        resetToInitialForm();
      }}
      disablePortal
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          await updateCommute({
            variables: {
              commute: {
                id: commute.id,
                activityType,
                distance: distance * 1000,
              },
            },
          });

          setIsChangeActivityTypeModalOpen(false);
        }}
      >
        <DialogTitle>Override commute details</DialogTitle>
        <DialogContent>
          <FormControl fullWidth className={styles.input}>
            <InputLabel htmlFor={updateActivityId}>Activity type</InputLabel>
            <Select
              label="Activity type"
              variant="outlined"
              required
              id={updateActivityId}
              value={activityType}
              onChange={(event) => {
                setActivityType(event.target.value);
              }}
              MenuProps={{ disablePortal: true }}
            >
              {activityTypeGraphQLEnumOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className={styles.input}>
            <TextField
              variant="outlined"
              type="number"
              label="Distance in km"
              inputProps={{
                min: 1,
                max: 100,
                step: 1,
                required: true,
              }}
              id={updateDistanceId}
              value={distance}
              onChange={(event) => {
                setDistance(
                  // beware of empty input (removing the 1 to type 20 for example)
                  event.target.value ? Math.round(event.target.value) : ''
                );
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsChangeActivityTypeModalOpen(false);
              resetToInitialForm();
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={loading}
            type="submit"
          >
            {loading ? 'Updating' : 'Update'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
