import { Link } from 'react-router-dom';
import {
  RiFacebookBoxLine,
  RiInstagramLine,
  RiLinkedinLine,
} from 'react-icons/ri';
import styles from './Footer.module.css';

const social = [
  { Icon: RiInstagramLine, url: 'https://www.instagram.com/tern_app/' },
  { Icon: RiFacebookBoxLine, url: 'https://www.facebook.com/terncommute/' },
  {
    Icon: RiLinkedinLine,
    url: 'https://www.linkedin.com/company/terncommute',
  },
];

export default function Footer() {
  return (
    <div className={styles.container}>
      <ul className={styles.linksUl}>
        <li className={styles.li}>
          <Link to="/">Home</Link>
        </li>
        <li className={styles.li}>
          <Link to="/businesses">Features</Link>
        </li>
        <li className={styles.li}>
          <Link to="/brands">Brands</Link>
        </li>
        <li className={styles.li}>
          <Link to="/pricing-and-rewards">Enterprise Pricing</Link>
        </li>
        <li className={styles.li}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://medium.com/tern-app"
          >
            Blog
          </a>
        </li>
        <li className={styles.li}>
          <Link to="/about-us">About Us</Link>
        </li>
        <li className={styles.li}>
          <Link to="/contact-us">Contact Us</Link>
        </li>
        <li className={styles.li}>
          <Link to="/request-a-demo">Request a demo</Link>
        </li>
        <li className={styles.li}>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
      </ul>
      <ul className={styles.socialUl}>
        {social.map(({ url, Icon }) => {
          return (
            <li key={url}>
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                aria-label="Link to social media service"
              >
                <Icon />
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
