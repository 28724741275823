/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { Redirect } from 'react-router-dom';
import { useMutation, gql, useApolloClient } from '@apollo/client';
import {
  FormControl,
  Button,
  FormControlLabel,
  Switch,
  FormHelperText,
  TextField,
} from '@mui/material';
import styles from './SignupDetails.module.css';
import { DEFAULT_SCHEDULE } from './Schedule';
import OfficeSelector, { OFFICE_NOT_LISTED } from './OfficeSelector';
import useCreateCompanyOffice from './hooks/useCreateCompanyOffice';

function SignupDetails({ redirect, isAdminRegister }) {
  const apolloClient = useApolloClient();
  const [
    addUser,
    { loading: addUserLoading, data: userData, error: addUserError },
  ] = useMutation(
    gql`
      mutation CreateUser($user: CreateUserInput!) {
        createUser(user: $user) {
          id
        }
      }
    `,
    {
      onCompleted: () => {
        // Note there is a useQuery based redirect
        // in Signup, but the below redirect will happen before this point
        // and thus show the signup page with the `showModal` query param

        // so that when you go back to offices or office it re-queries
        // e.g for countries list or new user in office
        apolloClient.resetStore();
      },
    }
  );

  const firebaseAuth = getAuth();

  const [initialFirstName, ...initialLastNames] = firebaseAuth.currentUser
    .displayName
    ? firebaseAuth.currentUser.displayName.split(' ')
    : [];

  const [firstName, updateFirstName] = useState(initialFirstName || '');
  const [firstNameError, updateFirstNameError] = useState(false);
  const [lastName, updateLastName] = useState(
    initialLastNames ? initialLastNames.join(' ') : ''
  );
  const [lastNameError, updateLastNameError] = useState(false);

  const [company, updateCompany] = useState(null);

  const [officeId, updateOfficeID] = useState('');
  const isCreateNewOffice = officeId === OFFICE_NOT_LISTED;

  const [office, updateOffice] = useState(null);

  const [privateRouteMaps, updatePrivateRouteMaps] = useState(true);

  const {
    createCompanyOffice,
    addCompanyError,
    addCompanyLoading,
    addOfficeLoading,
    addOfficeError,
  } = useCreateCompanyOffice({ company, office, officeId });

  if (userData && userData.createUser) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          userId: userData.createUser.id,
          scheduleArray: DEFAULT_SCHEDULE,
        })
      );
    }

    window.gtag('event', 'sign_up', {
      event_category: privateRouteMaps
        ? 'private route maps'
        : 'public route maps',
    });

    return <Redirect to={redirect} />;
  }

  const publicRouteMapInputId = 'public-route-maps';

  const mutationsInflight =
    addUserLoading || addCompanyLoading || addOfficeLoading;
  return (
    <>
      <h1>One last step</h1>
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          const isIncompleteForm = !(
            company &&
            ((officeId && !isCreateNewOffice) || office) &&
            firstName &&
            lastName
          );

          // this shouldn't happen due to HTML5 validations
          // guarding against the company/office data taking ages
          // to load
          if (isIncompleteForm) {
            alert(
              'Please wait for the whole form to load and fill in all inputs, not enough details to signup.'
            );
            return;
          }

          const usersOfficeId = await createCompanyOffice();

          if (!usersOfficeId) {
            return;
          }

          await addUser({
            variables: {
              user: {
                firstName,
                lastName,
                firebaseAuthId: firebaseAuth.currentUser.uid,
                email: firebaseAuth.currentUser.email,
                photoURL: firebaseAuth.currentUser.photoURL,
                officeId: usersOfficeId,
                privateRouteMaps,
                // undefined will use schema default
                signupSource: isAdminRegister ? 'WEB_ADMIN' : undefined,
              },
            },
          });
        }}
      >
        <TextField
          id="first-name"
          label="First name"
          // required prop makes an asterisk
          inputProps={{ required: true }}
          variant="standard"
          className={styles.firstLastNames}
          value={firstName}
          onChange={(event) => {
            updateFirstNameError(false);
            updateFirstName(event.target.value);
          }}
          error={firstNameError}
          helperText={firstNameError ? 'Please enter a first name' : undefined}
          onBlur={() => {
            if (!firstName) updateFirstNameError(true);
          }}
          onInvalid={() => {
            updateFirstNameError(true);
          }}
          fullWidth
        />

        <TextField
          id="last-name"
          label="Last name"
          inputProps={{ required: true }}
          variant="standard"
          className={styles.firstLastNames}
          value={lastName}
          onChange={(event) => {
            updateLastNameError(false);
            updateLastName(event.target.value);
          }}
          error={lastNameError}
          helperText={lastNameError ? 'Please enter a last name' : undefined}
          onBlur={() => {
            if (!lastName) updateLastNameError(true);
          }}
          onInvalid={() => {
            updateLastNameError(true);
          }}
          fullWidth
        />

        <OfficeSelector
          company={company}
          updateCompany={updateCompany}
          officeId={officeId}
          updateOfficeID={updateOfficeID}
          office={office}
          updateOffice={updateOffice}
        />

        <FormControl variant="standard" className={styles.routeMaps}>
          <FormControlLabel
            control={
              <Switch
                id={publicRouteMapInputId}
                checked={privateRouteMaps}
                onChange={(event) => {
                  updatePrivateRouteMaps(event.target.checked);
                }}
                color="primary"
              />
            }
            label="Private route maps"
          />
          <FormHelperText>
            {isAdminRegister ? 'This is for your personal Tern profile. ' : ''}
            When your route maps are public people can see your commute on a
            map, but the start and end of your commute that is not your office
            is cropped by a randomised distance, and all time information is
            private. But if you would like to completely hide route maps please
            check above.
          </FormHelperText>
        </FormControl>

        <div className={styles.buttons}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={mutationsInflight}
            className={styles.button}
            data-testid="create-account"
          >
            {mutationsInflight ? 'Creating...' : 'Create account'}
          </Button>
          {addUserError && <p>Sorry an error occurred creating the user.</p>}
          {addCompanyError && (
            <p>Sorry an error occurred creating the company.</p>
          )}
          {addOfficeError && (
            <p>Sorry an error occurred creating the office in our system.</p>
          )}
          <Button
            type="button"
            onClick={() => {
              firebaseAuth.signOut();
            }}
            className={styles.button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
}

export default SignupDetails;
