import { useQuery, useMutation, gql } from '@apollo/client';
import React, { Suspense, lazy, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { Button, Snackbar, Alert } from '@mui/material';
import copy from 'copy-to-clipboard';
import { Link } from 'react-router-dom';
import useUser from './hooks/useUser';
import Loading from './Loading';
import styles from './MyRewards.module.css';

const ReactMarkdown = lazy(() =>
  import(/* webpackChunkName: "react-markdown" */ 'react-markdown')
);

const MY_REWARDS_QUERY = gql`
  query UserRewards($userId: ID!) {
    user(id: $userId) {
      id
      rewards {
        code
        wonAt

        challenge {
          id
          myRewardTitle
          redeemInstructions

          company {
            logoImageUrl
          }
        }
      }
    }
  }
`;

function CopyToClipboardButton({ code }) {
  const [copied, setCopied] = useState(false);
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => {
        const result = copy(code);

        if (result) {
          setCopied(true);
        }
      }}
    >
      {copied ? 'Copied!' : 'Copy'}
    </Button>
  );
}

export default function MyRewards() {
  const user = useUser();

  const { data, error, loading } = useQuery(MY_REWARDS_QUERY, {
    variables: { userId: user?.id },
    skip: !user,
  });

  const [fetchedCodeError, setFetchedCodeError] = useState(false);
  const [fetchedCode, setFetchedCode] = useState(null);

  const [requestChallengeVoucherCode, { loading: requestingCode }] =
    useMutation(
      gql`
        mutation RequestChallengeVoucherCode($challengeId: ID!) {
          requestChallengeVoucherCode(challengeId: $challengeId)
        }
      `
    );

  if (error) {
    return (
      <p>
        {`Error: ${
          process.env.NODE_ENV === 'production'
            ? error.message
            : JSON.stringify(error)
        }`}
      </p>
    );
  }

  if (!data || loading) {
    return <Loading />;
  }

  return (
    <>
      <h1>My rewards</h1>
      {!data.user.rewards.length && (
        <p className={styles.noReward}>
          No rewards yet, please visit the&nbsp;
          <Link to="/rewards">rewards</Link>
          &nbsp;section to join challenges and earn rewards.
        </p>
      )}
      {data.user.rewards.map((reward) => {
        const completed = `completed ${moment(reward.wonAt).fromNow()}`;
        const maybeCode = reward.code || fetchedCode;
        return (
          <div key={reward.challenge.id} className={styles.cardContainer}>
            <div className={styles.titleContainer}>
              <img
                className={styles.companyImage}
                src={reward.challenge.company.logoImageUrl}
                alt="company logo"
              />
              <div>
                <span className={styles.topTitle}>
                  {reward.challenge.myRewardTitle}
                </span>
                <span className={styles.completed}>{completed}</span>
              </div>
            </div>
            <h4 className={styles.howToTitle}>How to redeem</h4>

            <Suspense
              fallback={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <>
                  <Skeleton />
                  <Skeleton width="80%" />
                  <Skeleton width="60%" />
                  <Skeleton />
                </>
              }
            >
              <ReactMarkdown className={styles.markdownHolder}>
                {reward.challenge.redeemInstructions}
              </ReactMarkdown>
            </Suspense>
            {maybeCode ? (
              <div className={styles.codeHolder}>
                <code className={styles.theCode}>{maybeCode}</code>
                <CopyToClipboardButton code={maybeCode} />
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={requestingCode}
                onClick={async () => {
                  let result;
                  try {
                    result = await requestChallengeVoucherCode({
                      variables: { challengeId: reward.challenge.id },
                    });
                  } catch (mutationError) {
                    setFetchedCodeError(true);

                    return;
                  }
                  console.log('result:', result);
                  setFetchedCode(result.data.requestChallengeVoucherCode);
                }}
              >
                {requestingCode ? 'Loading...' : 'Request code'}
              </Button>
            )}
          </div>
        );
      })}

      <Snackbar
        open={fetchedCodeError}
        autoHideDuration={6000}
        onClose={() => {
          setFetchedCodeError(false);
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => {
            setFetchedCodeError(false);
          }}
          severity="error"
        >
          Sorry it failed to get the voucher code, an error has been logged and
          we will look into it asap.
        </Alert>
      </Snackbar>
    </>
  );
}
