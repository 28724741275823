import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import App from './App';
import Home from './website/Home';
import ScrollToTop from './ScrollToTop';
import Loading from './Loading';

const Admin = lazy(() => import(/* webpackChunkName: "admin" */ './admin'));
const Inspect = lazy(() =>
  import(/* webpackChunkName: "website-inspect" */ './website/Inspect')
);
const Graph = lazy(() =>
  import(/* webpackChunkName: "website-graph" */ './website/Graph')
);

export default function Routes() {
  return (
    <Switch>
      <Route path="/app">
        <App />
      </Route>
      <Route path="/admin">
        <Suspense fallback={<Loading />}>
          <Admin />
        </Suspense>
      </Route>

      {/* Development */}
      <Route
        path="/inspect"
        render={() => (
          <Suspense fallback={<Loading />}>
            <Inspect />
          </Suspense>
        )}
        exact
      />
      <Route
        path="/graph"
        render={() => (
          <Suspense fallback={<Loading />}>
            <Graph />
          </Suspense>
        )}
        exact
      />
      {/* End of development */}

      <Route>
        <SkeletonTheme color="#4caf50" highlightColor="#ffffff9e">
          <ScrollToTop />
          <Home />
        </SkeletonTheme>
      </Route>
    </Switch>
  );
}
