import React from 'react';
import { FaGift as GiftIcon } from 'react-icons/fa';
import { ImArrowUpRight2 as LeaderboardIcon } from 'react-icons/im';
import styles from './UserFeatures.module.css';
import { AIIcon } from '../Icons';

export default function UserFeatures() {
  return (
    <section className={styles.keyFeatures}>
      <div className={styles.feature}>
        <div>
          <AIIcon className={styles.aiIcon} />
        </div>
        <div>
          <h3>Automatic trip detection</h3>
          <p>
            Like a step counter, the app automatically tracks when you walk or
            cycle to the office, and covers mixed modes such as when you walk
            then hop on a bus or train, then walk again.
          </p>
          <p>
            Tern uses your phone&apos;s sensors and AI without you having to
            remember to open the app. We also support integration with bike
            computers and fitness watches, for those keen runners or cyclists!
          </p>
        </div>
      </div>
      <div className={styles.feature}>
        <div>
          <LeaderboardIcon className={styles.icon} />
        </div>
        <div>
          <h3>Track progress and positive impact</h3>
          <p>
            Every active sustainable commute will show in your personal
            dashboard. You&apos;ll see your stats, including distance and CO2
            saved. That&apos;s a good feeling! You&apos;ll be able to view the
            progress of your workplace and colleagues, as you collectively make
            a positive impact towards achieving your ESG goals.
          </p>
        </div>
      </div>
      <div className={styles.feature}>
        <div>
          <GiftIcon className={styles.icon} />
        </div>
        <div>
          <h3>Challenges & rewards</h3>
          <p>
            Complete challenges and earn rewards funded by your employer or
            sustainable brands that partner with us.
          </p>
        </div>
      </div>
    </section>
  );
}
