import { useEffect, useRef, useState } from 'react';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import {
  FormControl,
  Button,
  Switch,
  FormHelperText,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { getAuth, updateEmail, updatePassword } from 'firebase/auth';
import { isEqual } from 'lodash';
import styles from './UpdateUser.module.css';
import OfficeSelector, { OFFICE_NOT_LISTED } from './OfficeSelector';
import useCreateCompanyOffice from './hooks/useCreateCompanyOffice';
import handleError from './utils/handle-error';
import { useSnackbar } from './hooks/useSnackbar';

export default function UpdateUser({ user }) {
  const { currentUser } = getAuth();
  const history = useHistory();
  const openSnackbar = useSnackbar();

  const hasEmailLogin = currentUser.providerData?.some(
    ({ providerId }) => providerId === 'password'
  );

  const initialUserDetails = {
    firstName: user.firstName,
    lastName: user.lastName,
    privateRouteMaps: user.privateRouteMaps,
    monthlyTripsTarget: user.monthlyTripsTarget,
    email: currentUser.email || '',
    password: '',
    passwordConfirm: '',
    company: {
      id: user.office.company.id,
      name: user.office.company.name,
    },
    officeId: user.office.id,
    office: null,
  };
  const [userDetails, setUserDetails] = useState(initialUserDetails);

  const [firstNameError, updateFirstNameError] = useState(false);
  const [lastNameError, updateLastNameError] = useState(false);
  const [emailError, updateEmailError] = useState(false);
  const [passwordError, updatePasswordError] = useState(false);
  const [passwordConfirmError, updatePasswordConfirmError] = useState(false);

  const passwordConfirmRef = useRef(null);

  const {
    firstName,
    lastName,
    privateRouteMaps,
    company,
    officeId,
    monthlyTripsTarget,
    email,
    password,
    passwordConfirm,
  } = userDetails;

  const isCreateNewOffice = officeId === OFFICE_NOT_LISTED;

  const [office, updateOffice] = useState(null);

  const publicRouteMapInputId = 'public-route-maps';
  const apolloClient = useApolloClient();
  const [updateUser, { error: updateUserError, loading }] = useMutation(
    gql`
      mutation UpdateUser($user: UpdateUserInput!) {
        updateUser(user: $user) {
          id

          office {
            id
            company {
              id
              name
            }
          }
        }
      }
    `,
    {
      onCompleted: async ({ updateUser: latestUser }) => {
        // refetches companies, offices and user for footer link

        // Also so that when you go back to offices or office it re-queries
        // e.g for countries list or new user in office

        await apolloClient.resetStore();

        // maybe make a separate component so
        // you can reset by un-mounting?
        setUserDetails((state) => ({
          ...state,
          company: {
            id: latestUser.office.company.id,
            name: latestUser.office.company.name,
          },
          officeId: latestUser.office.id,
          office: null,
        }));

        openSnackbar('Your profile has been updated.', 'success');
      },
      onError: () => {
        openSnackbar(
          'Sorry, something went wrong updating your profile details.',
          'error'
        );
      },
    }
  );

  const {
    createCompanyOffice,
    addCompanyError,
    addCompanyLoading,
    addOfficeLoading,
    addOfficeError,
  } = useCreateCompanyOffice({ company, office, officeId });

  const loginMethods = currentUser.providerData.map(({ providerId }) => {
    const providerToHuman = new Map([
      ['password', 'your email & password'],
      ['facebook.com', 'your Facebook account'],
      ['google.com', 'your Google account'],
      ['apple.com', 'your Apple account'],
    ]);

    return providerToHuman.get(providerId);
  });

  const humanStringOfLogins =
    loginMethods.length === 1
      ? `${loginMethods[0]}.`
      : `${loginMethods.slice(0, -1).join(', ')} and ${loginMethods.at(-1)}.`;

  function passwordConfirmIssue() {
    return password && password !== passwordConfirm;
  }
  function passwordIssue() {
    return passwordConfirm && !password;
  }

  useEffect(() => {
    passwordConfirmRef.current?.setCustomValidity(
      passwordConfirmError ? "Passwords don't match" : ''
    );
  }, [passwordConfirmRef, passwordConfirmError]);

  return (
    <form
      className={styles.form}
      onSubmit={async (event) => {
        event.preventDefault();

        if (passwordIssue()) {
          updatePasswordError(true);
          return;
        }

        if (passwordConfirmIssue()) {
          updatePasswordConfirmError(true);
          return;
        }

        const isIncompleteForm = !(
          company &&
          ((officeId && !isCreateNewOffice) || office) &&
          firstName &&
          lastName
        );

        // this shouldn't happen due to HTML5 validations
        if (isIncompleteForm) {
          openSnackbar(
            'Please wait for the whole form to load and fill in all inputs, not enough details to update your profile.',
            'error'
          );
          return;
        }

        const usersOfficeId = await createCompanyOffice();

        if (!usersOfficeId) {
          return;
        }

        const emailHasChanged = email !== currentUser.email;

        async function handleRequiresSignInError(field) {
          await getAuth().signOut();
          await apolloClient.resetStore();
          history.push('/signup?signupType=settings');
          setTimeout(() => {
            openSnackbar(
              `You need to have recently signed-in to update your ${field}.`,
              'info'
            );
          }, 1);
        }

        if (emailHasChanged) {
          try {
            await updateEmail(currentUser, email);
          } catch (error) {
            if (error.code === 'auth/requires-recent-login') {
              await handleRequiresSignInError('email');
              return;
            }
            if (error.code === 'auth/email-already-in-use') {
              openSnackbar('Sorry that email is already in use.', 'error');
              return;
            }

            handleError({ error });
            openSnackbar(
              `Sorry, something went wrong updating your email. ${error.code}`,
              'error'
            );
            return;
          }
        }

        if (password) {
          try {
            await updatePassword(currentUser, password);
          } catch (error) {
            if (error.code === 'auth/requires-recent-login') {
              await handleRequiresSignInError('password');
              return;
            }
            if (error.code === 'auth/weak-password') {
              openSnackbar('Sorry that password is too weak.', 'error');
              return;
            }

            handleError({ error });
            openSnackbar(
              `Sorry, something went wrong updating your password. ${error.code}`,
              'error'
            );
            return;
          }
        }

        setUserDetails((state) => ({
          ...state,
          password: '',
          passwordConfirm: '',
        }));

        await updateUser({
          variables: {
            user: {
              firstName,
              lastName,
              privateRouteMaps,
              officeId: usersOfficeId,
              monthlyTripsTarget,
              email: emailHasChanged ? email : undefined,
            },
          },
        });
      }}
    >
      <h4 className={styles.settingsHeading}>Your goals</h4>
      <TextField
        label="Monthly trip count goal"
        type="number"
        inputProps={{ min: '1' }}
        id="monthly-trips-target"
        variant="standard"
        className={styles.formControl}
        value={monthlyTripsTarget || ''}
        onChange={(event) => {
          setUserDetails((state) => ({
            ...state,
            monthlyTripsTarget:
              // keyboard can make a 0 it just doesn't appear in input
              event.target.value && event.target.value !== '0'
                ? parseInt(event.target.value, 10)
                : null,
          }));
        }}
        helperText="Tell us how many trips you aim to complete each month and we will show you a simple progress bar on your profile screen."
      />
      <h4 className={styles.settingsHeading}>Your office</h4>
      <OfficeSelector
        company={company}
        updateCompany={(newCompany) => {
          setUserDetails((state) => ({
            ...state,
            company: newCompany,
          }));
        }}
        officeId={officeId}
        updateOfficeID={(newOfficeId) => {
          setUserDetails((state) => ({
            ...state,
            officeId: newOfficeId,
          }));
        }}
        office={office}
        updateOffice={updateOffice}
        isUpdate
      />

      <h4 className={styles.settingsHeading}>Your details</h4>
      <TextField
        id="first-name"
        label="First name"
        inputProps={{ required: true }}
        variant="standard"
        className={styles.formControl}
        value={firstName}
        onChange={(event) => {
          updateFirstNameError(false);
          setUserDetails((state) => ({
            ...state,
            firstName: event.target.value,
          }));
        }}
        error={firstNameError}
        helperText={firstNameError ? 'Please enter a first name' : undefined}
        onBlur={() => {
          if (!firstName) updateFirstNameError(true);
        }}
        onInvalid={() => {
          updateFirstNameError(true);
        }}
      />

      <TextField
        id="last-name"
        label="Last name"
        inputProps={{ required: true }}
        variant="standard"
        className={styles.formControl}
        value={lastName}
        onChange={(event) => {
          updateLastNameError(false);
          setUserDetails((state) => ({
            ...state,
            lastName: event.target.value,
          }));
        }}
        error={lastNameError}
        helperText={lastNameError ? 'Please enter a last name' : undefined}
        onBlur={() => {
          if (!lastName) updateLastNameError(true);
        }}
        onInvalid={() => {
          updateLastNameError(true);
        }}
      />

      <TextField
        id="email"
        label="Email"
        type="email"
        fullWidth
        variant="standard"
        inputProps={{ required: true }}
        className={styles.formControl}
        value={email}
        onChange={(event) => {
          updateEmailError(false);
          setUserDetails((state) => ({
            ...state,
            email: event.target.value,
          }));
        }}
        error={emailError}
        helperText={emailError ? 'Please enter an email' : undefined}
        onInvalid={() => {
          updateEmailError(true);
        }}
        onBlur={() => {
          if (!email) updateEmailError(true);
        }}
      />

      <h4 className={styles.settingsHeading}>Your login details</h4>

      <p>You have logged in using {humanStringOfLogins}</p>

      {hasEmailLogin && (
        <>
          <TextField
            id="password"
            label="Create new password"
            type="password"
            fullWidth
            variant="standard"
            inputProps={{
              autoComplete: 'off',
              required: !!passwordConfirm,
              minLength: 6,
            }}
            className={styles.formControl}
            value={password}
            onChange={(event) => {
              updatePasswordError(false);
              updatePasswordConfirmError(false);
              setUserDetails((state) => ({
                ...state,
                password: event.target.value,
              }));
            }}
            error={passwordError}
            helperText={passwordError ? 'Please enter a password' : undefined}
            onBlur={() => {
              if (passwordIssue()) {
                updatePasswordError(true);
              }
            }}
          />
          <TextField
            id="passwordConfirm"
            label="Confirm your new password"
            type="password"
            fullWidth
            variant="standard"
            inputProps={{
              autoComplete: 'off',
              ref: passwordConfirmRef,
              required: !!password,
              minLength: 6,
            }}
            className={styles.formControl}
            value={passwordConfirm}
            onChange={(event) => {
              updatePasswordConfirmError(false);
              setUserDetails((state) => ({
                ...state,
                passwordConfirm: event.target.value,
              }));
            }}
            error={passwordConfirmError}
            helperText={
              passwordConfirmError ? 'Your passwords must match' : undefined
            }
            onBlur={() => {
              if (passwordConfirmIssue()) {
                updatePasswordConfirmError(true);
              }
              if (passwordIssue()) {
                updatePasswordError(true);
              }
            }}
          />
        </>
      )}

      <h4 className={styles.settingsHeading}>Privacy</h4>
      <FormControl variant="standard" className={styles.formControl}>
        <FormControlLabel
          control={
            <Switch
              id={publicRouteMapInputId}
              checked={privateRouteMaps}
              onChange={(event) => {
                setUserDetails((state) => ({
                  ...state,
                  privateRouteMaps: event.target.checked,
                }));
              }}
              color="primary"
            />
          }
          label="Private route maps"
        />
        <FormHelperText>
          When your route maps are public people can see your commute on a map,
          but the start and end of your commute that is not your office is
          cropped by a randomised distance, and all time information is private.
          But if you would like to completely hide route maps please check
          above.
        </FormHelperText>
      </FormControl>

      <div className={styles.buttons}>
        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
        >
          {loading || addOfficeLoading || addCompanyLoading
            ? 'Updating...'
            : 'Update'}
        </Button>
        {!isEqual(userDetails, initialUserDetails) && (
          <Button
            onClick={() => {
              setUserDetails(initialUserDetails);
              updateOffice(null);

              updateFirstNameError(false);
              updateLastNameError(false);
              updateEmailError(false);
              updatePasswordError(false);
              updatePasswordConfirmError(false);
            }}
            className={styles.cancelButton}
          >
            Cancel
          </Button>
        )}
      </div>
      {updateUserError && <p>Sorry an error occurred creating the user.</p>}
      {addCompanyError && <p>Sorry an error occurred creating the company.</p>}
      {addOfficeError && (
        <p>Sorry an error occurred creating the office in our system.</p>
      )}
    </form>
  );
}
