import { useEffect, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useApolloClient, gql } from '@apollo/client';
import { getAuth } from 'firebase/auth';
import mixpanel from 'mixpanel-browser';
import ternAppDetails from './utils/tern-app-details';
import Schedule from './Schedule';
import Loading from './Loading';
import UpdateUser from './UpdateUser';
import accordianOverride from './AccordianOverride.module.css';
import ProfileImage from './ProfileImage';
import styles from './Settings.module.css';
import useUser from './hooks/useUser';
import LinksToWebsite from './LinksToWebsite';

function Settings() {
  const history = useHistory();
  const location = useLocation();
  const apolloClient = useApolloClient();
  const user = useUser();
  const [expanded, updateExpanded] = useState(false);
  const [deleteAccountError, setDeleteAccountError] = useState(false);

  const currentQps = new URLSearchParams(location.search);
  const showModal = currentQps.has('showModal');

  const handleCloseModal = useCallback(() => {
    history.push('/settings');
  }, [history]);

  useEffect(() => {
    if (!showModal && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ requestPermission: true })
      );
    }
  }, [showModal]);

  if (!user) return <Loading />;

  function disableTracking() {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ signingOut: true })
      );
    }
  }

  return (
    <div className={styles.container}>
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Permission request</DialogTitle>
        <DialogContent>
          {ternAppDetails && ternAppDetails.isAndroid ? (
            <DialogContentText id="alert-dialog-description">
              Tern collects location data to enable it to automatically detect
              when you cycle, walk or run to work even when the app is closed or
              not in use.
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              We are about to ask for location data permissions so we can
              automatically detect and verify when you cycle, walk or run to
              work without you having to remember to open the app. We do not
              share this data with any third parties or use it for commercial
              gain.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <ProfileImage user={user} isCurrentUser />
      {user ? <h3>{`Hi ${user.firstName}!`}</h3> : null}

      <h4 className={styles.settingsHeading}>Commute tracking</h4>

      <Schedule userId={user && user.id} />

      <div>
        <h4 className={styles.settingsHeading}>Strava integration</h4>
        <p>
          If you&#39;re someone who routinely records your active commute using
          Strava and doesn&#39;t need passive detection, you can link your
          account so we can automatically register the activities in and out the
          office geofence as a commute.
        </p>
        <span className={styles.stravaSignIn}>
          <a
            href={user?.stravaSignInUrl}
            onClick={() => {
              mixpanel.track('Strava Link Click');
            }}
          >
            Link my Strava account
          </a>
          <OpenInNewIcon className={styles.openInNewIcon} fontSize="inherit" />
        </span>
      </div>

      {user && <UpdateUser user={user} />}

      <button
        className={styles.logout}
        type="button"
        onClick={async () => {
          disableTracking();
          await getAuth().signOut();
          await apolloClient.resetStore();
          history.push('/');
        }}
      >
        Log Out
      </button>

      <Snackbar
        open={!!deleteAccountError}
        autoHideDuration={10000}
        onClose={() => {
          setDeleteAccountError(false);
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => {
            setDeleteAccountError(false);
          }}
          severity="error"
        >
          Sorry something went wrong trying to delete your account, we will look
          into it.
        </Alert>
      </Snackbar>
      <div className={styles.accordianHolder}>
        <Accordion
          classes={{ root: accordianOverride.accordian }}
          expanded={expanded}
          onChange={(_, isExpanded) => {
            updateExpanded(isExpanded);
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Account deletion
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className={styles.sorryToHear}>
                Sorry to hear you go, we would love to hear your{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={styles.feedback}
                  href="https://tern.canny.io/feature-requests#tern"
                >
                  feedback
                </a>{' '}
                to understand why, to help us build a better app.
              </p>
              <button
                className={styles.deleteAccount}
                type="button"
                onClick={async () => {
                  const userConfirmed = window.confirm(
                    `Are you sure? This will delete all your account data for ${
                      getAuth().currentUser.email
                    }.`
                  );

                  if (!userConfirmed) return;

                  disableTracking();

                  try {
                    await apolloClient.mutate({
                      mutation: gql`
                        mutation DeleteCurrentUser {
                          deleteUser
                        }
                      `,
                    });
                  } catch (error) {
                    setDeleteAccountError(true);

                    return;
                  }
                  mixpanel.track('User Deleted');

                  try {
                    // trying to delete the Firebase user
                    // errors if they have not recently signed in
                    await getAuth().currentUser.delete();
                  } catch (err) {
                    console.error('Firebase delete failed: ', err);
                    await getAuth().signOut();
                  }

                  await apolloClient.resetStore();

                  history.push('/');
                }}
              >
                Delete my account now
              </button>
            </div>
          </AccordionDetails>
        </Accordion>
        <LinksToWebsite />
      </div>
    </div>
  );
}

export default Settings;
