import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import mixpanel from 'mixpanel-browser';
import AppLinks from './AppLinks';
import styles from './GetStartedModel.module.css';

export default function GetStartedModel({ isDialogOpen, setIsDialogOpen }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const trackingSource = 'Get Started Modal';

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
    >
      <DialogTitle>What kind of user are you?</DialogTitle>
      <DialogContent>
        <h4>I want to track my commutes</h4>
        <DialogContentText>
          If you&apos;re an active commuter wanting to track your commutes, you
          can visit an app store below and you&apos;ll have all you need to get
          going. In the app you can either join an existing company or create a
          new one, Tern is completely free for commuters to use.
        </DialogContentText>
        <AppLinks
          trackingSource={trackingSource}
          fillColor="grey"
          className={styles.appLinks}
        />
        <h4>I want to run a sustainable commuting program for my workplace</h4>
        <DialogContentText>
          If you want to set up an admin account to run an active travel
          campaign internally, perhaps you work in HR or sustainability, please
          click below and register your workplace, no payment needed.
        </DialogContentText>
        <Button
          variant="outlined"
          onClick={() => {
            mixpanel.track('Admin Register Click', {
              source: trackingSource,
            });
          }}
          component={RouterLink}
          to="/admin/signup"
          color="primary"
          className={styles.adminRegisterButton}
          size="large"
        >
          Start your program
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
