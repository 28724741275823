import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { BiChevronDown as ChevronDown } from 'react-icons/bi';
import { Helmet } from 'react-helmet';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import User from './User';
import Footer from './Footer';
import Office from './Office';
import TrackingBar from './TrackingBar';
import Signup from './Signup';
import Challenges from './Challenges';
import MyRewards from './MyRewards';
import RaffleResults from './RaffleResults';
import Company from './Company';
import Settings from './Settings';
import FirebaseCloudMessaging from './FirebaseCloudMessagingToken';
import Users from './Users';
import Offices from './Offices';
import Challenge from './Challenge';
import usePageViews from './hooks/usePageViews';
import styles from './App.module.css';
import AppBottomSheetProvider from './AppBottomSheetProvider';
import WelcomeInstructions from './WelcomeInstructions';

export const SIGNUP_ROUTE = '/signup';

function PageViewComponent(props) {
  usePageViews({ section: 'app', ...props });

  return null;
}

export function BottomSheetRoutes({
  topContent,
  isWidget,
  topLevelSetActionSheetContents,
  topLevelHistory,
}) {
  return [
    <Route
      key="1"
      path="/companies/:companyId"
      render={({ match }) => (
        <>
          <PageViewComponent page="Company" />
          {topContent}
          <Company isWidget={isWidget} companyId={match.params.companyId} />
        </>
      )}
      exact
    />,
    <Route
      key="2"
      path="/offices/:officeId"
      render={({ match }) => (
        <>
          <PageViewComponent page="Office" />
          {topContent}
          <Office
            officeId={match.params.officeId}
            topLevelSetActionSheetContents={topLevelSetActionSheetContents}
            topLevelHistory={topLevelHistory}
          />
        </>
      )}
      exact
    />,
    <Route
      key="3"
      path="/users/:userId"
      render={({ match }) => (
        <>
          <PageViewComponent page="User" />
          {topContent}
          <User userId={match.params.userId} />
        </>
      )}
      exact
    />,
  ];
}

function LeaderboardSelect({ selected }) {
  const history = useHistory();
  const location = useLocation();
  const currentQps = new URLSearchParams(location.search);
  const isUsers = selected === '/users';

  return (
    <div
      className={[
        styles.leaderboardSelectContainer,
        isUsers ? styles.usersSelected : styles.officesSelected,
      ].join(' ')}
    >
      <select
        onChange={(event) => {
          mixpanel.track('Leaderboard type change', {
            type: isUsers ? 'Users' : 'Offices',
          });
          history.push(`${event.target.value}?${currentQps}`);
        }}
        value={selected}
        className={styles.leaderboardSelect}
      >
        <option value="/">Offices</option>
        <option value="/users">Users</option>
      </select>
      <ChevronDown
        className={isUsers ? styles.usersChevron : styles.officesChevron}
      />
    </div>
  );
}

function AddHistoryForAndroidBack() {
  const history = useHistory();

  useEffect(() => {
    window.Pedal.history = history;

    return () => {
      delete window.Pedal.history;
    };
  }, [history]);

  return null;
}

export default function App() {
  const currentQps = new URLSearchParams(window.location.search);
  const isWidget = currentQps.has('widget');

  return (
    <BrowserRouter basename="/app">
      <AddHistoryForAndroidBack />
      <AppBottomSheetProvider>
        <FirebaseCloudMessaging />
        <WelcomeInstructions />
        <TrackingBar />
        <div className={styles.container}>
          <div className={styles.page}>
            <Switch>
              {BottomSheetRoutes({ isWidget })}

              <Route
                path="/users"
                render={() => (
                  <>
                    <Helmet>
                      <link
                        rel="canonical"
                        href={`${process.env.PUBLIC_URL}/app/users`}
                      />
                      <title>
                        All users active commuting leaderboard - Tern
                      </title>
                      <meta
                        name="description"
                        content="View active commuting stats for all users on Tern, this includes cycling, running and walking to work."
                      />
                    </Helmet>
                    <PageViewComponent page="Users" />
                    <LeaderboardSelect selected="/users" />
                    <Users />
                  </>
                )}
                exact
              />
              <Route
                path="/"
                render={() => (
                  <>
                    <Helmet>
                      <link
                        rel="canonical"
                        href={`${process.env.PUBLIC_URL}/app`}
                      />
                      <title>
                        All offices active commuting leaderboard - Tern
                      </title>
                      <meta
                        name="description"
                        content="View active commuting stats for all offices on Tern, this includes cycling, running and walking to work."
                      />
                    </Helmet>
                    <PageViewComponent page="Offices" />
                    <LeaderboardSelect selected="/" />
                    <Offices />
                  </>
                )}
                exact
              />

              <Route
                exact
                path={SIGNUP_ROUTE}
                render={() => (
                  <>
                    <Helmet>
                      <title>
                        Sign up to Tern today and start clocking green commutes
                        for your office!
                      </title>
                      <meta
                        name="description"
                        content="Sign up and link yourself to your office today, Tern app will automatically detect active commutes to your workplace."
                      />
                    </Helmet>
                    <PageViewComponent page="Signup" />
                    <Signup
                      redirect="/settings"
                      redirectAfterCreate="/settings?showModal"
                    />
                  </>
                )}
              />
              <Route
                path="/settings"
                render={() => (
                  <>
                    <Helmet>
                      <title>Settings</title>
                    </Helmet>
                    <PageViewComponent page="Settings" />
                    <Settings />
                  </>
                )}
                exact
              />
              <Route
                path="/rewards"
                render={() => (
                  <>
                    <PageViewComponent page="Rewards" />
                    <Challenges />
                  </>
                )}
                exact
              />

              <Route
                path="/rewards/my-rewards"
                render={() => (
                  <>
                    <PageViewComponent page="My rewards" />
                    <MyRewards />
                  </>
                )}
                exact
              />

              <Route
                path="/rewards/:id"
                render={({ match }) => (
                  <>
                    <PageViewComponent page="Rewards" />
                    <Challenge id={match.params.id} />
                  </>
                )}
                exact
              />

              <Route
                path="/raffles/domain-group"
                render={() => (
                  <>
                    <PageViewComponent page="Rewards Domain" />
                    <RaffleResults />
                  </>
                )}
                exact
              />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </div>
          <Footer isWidget={isWidget} />
        </div>
      </AppBottomSheetProvider>
    </BrowserRouter>
  );
}
