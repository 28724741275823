import { NavLink, useLocation, Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import moment from 'moment';
import ChallengeCard from './ChallengeCard';
import styles from './Challenges.module.css';
import useIsSignedIn from './hooks/useIsSignedIn';
import useUser from './hooks/useUser';

export default function Challenges() {
  const user = useUser();
  const isSignedIn = useIsSignedIn();
  const location = useLocation();
  const currentQps = new URLSearchParams(location.search);

  const isCompany = currentQps.get('category');
  const previewId = currentQps.get('preview');
  const previewAll = currentQps.has('previewAll');

  const { data, error, loading } = useQuery(
    gql`
      query Challenges {
        currentCountry {
          longName
        }

        challenges {
          id
          title
          challengeShort
          backgroundImage
          endTime
          isLive

          company {
            name
            logoImageUrl
          }

          users {
            id
          }

          country {
            longName
          }
        }
      }
    `,
    { skip: isCompany }
  );

  if (error) {
    return (
      <p>
        {`Error: ${
          process.env.NODE_ENV === 'production'
            ? error.message
            : JSON.stringify(error)
        }`}
      </p>
    );
  }

  const registeredChallenges =
    user && data
      ? data.challenges.filter((challenge) => {
          return (
            moment().isBefore(challenge.endTime) &&
            challenge.users.some((obj) => obj.id === user.id)
          );
        })
      : [];

  const nonRegisteredChallenges = data
    ? data.challenges
        .filter(({ id, isLive, endTime }) => {
          if (moment().isAfter(endTime)) return false;

          if (previewId) {
            return id === previewId || isLive;
          }

          if (previewAll) {
            return true;
          }

          return isLive;
        })
        .filter(
          (obj) =>
            registeredChallenges.length === 0 ||
            !registeredChallenges.some((object) => object.id === obj.id)
        )
    : [];

  const finishedChallenges = data
    ? data.challenges.filter(({ isLive, endTime }) => {
        return isLive && moment().isAfter(endTime);
      })
    : [];

  const liveChallenges = data
    ? data.challenges.filter(({ isLive }) => isLive)
    : [];

  const generalChallenges = (
    <>
      <p className={styles.introCopy}>
        Join green commuting challenges and win products from sustainable brands
        picked by us.
      </p>

      {data && !liveChallenges.length && (
        <p className={styles.introCopy}>
          Sorry we do not have any challenges to join in{' '}
          {data.currentCountry?.longName || 'your country'} right now, please
          check back soon.
        </p>
      )}

      {loading &&
        new Array(3)
          .fill(null)
          .map(() => <ChallengeCard key={Math.random()} loading />)}

      {registeredChallenges.length ? (
        <>
          <p className={styles.register}>
            {`Registered (${registeredChallenges.length})`}
          </p>
          {registeredChallenges.map((obj) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <ChallengeCard key={obj.id} {...obj} />
          ))}
        </>
      ) : null}

      {nonRegisteredChallenges.length ? (
        <>
          <p className={styles.register}>Still time to register</p>
          {nonRegisteredChallenges.map((obj) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <ChallengeCard key={obj.id} {...obj} />
          ))}
        </>
      ) : null}

      {finishedChallenges.length ? (
        <>
          <p className={styles.register}>Previous challenges</p>
          {finishedChallenges.map((obj) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <ChallengeCard key={obj.id} {...obj} />
          ))}
        </>
      ) : null}

      <div className={styles.moreComing}>
        <h3>Your brand here?</h3>
        <p>
          {`Please visit our `}
          <a
            target="_blank"
            className={styles.mailto}
            href="https://terncommute.com/brands#tern"
            rel="noreferrer"
          >
            brand page
          </a>
          {` or reach out to `}
          <a className={styles.mailto} href="mailto:info@terncommute.com">
            info@terncommute.com
          </a>
          {` to learn more.`}
        </p>
      </div>
    </>
  );

  const companyChallenges = isSignedIn ? (
    <p>
      {`Unfortunately your company does not fund rewards yet, please visit our `}
      <a
        target="_blank"
        className={styles.mailto}
        href="https://terncommute.com/businesses#tern"
        rel="noreferrer"
      >
        Tern for workplaces
      </a>{' '}
      page on our website to learn more, we would love to discuss how we can
      help boost green travel to your office and create a culture of
      sustainability.
    </p>
  ) : (
    <p>
      <span>Please </span>
      <Link
        className={styles.signInCompany}
        to="/signup?signupType=company-rewards"
      >
        sign in
      </Link>
      <span> to see company rewards.</span>
    </p>
  );

  return (
    <div>
      <h1>Challenges & rewards</h1>
      <NavLink
        activeClassName={styles.linkChecked}
        className={styles.link}
        to={`${location.pathname}`}
        isActive={(match, loc) => {
          const urlSp = new URLSearchParams(loc.search);
          return !urlSp.has('category');
        }}
      >
        For everyone
      </NavLink>
      <NavLink
        activeClassName={styles.linkChecked}
        className={styles.link}
        to={`${location.pathname}?category=company`}
        isActive={(match, loc) => {
          const urlSp = new URLSearchParams(loc.search);
          return urlSp.get('category') === 'company';
        }}
      >
        For your workplace
      </NavLink>

      {!isCompany && generalChallenges}

      {isCompany && companyChallenges}
    </div>
  );
}
