import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';

export default function useIsSignedIn() {
  const [isSignedIn, updateIsSignedIn] = useState(null);
  useEffect(() => {
    const unregisterAuthObserver = getAuth().onAuthStateChanged((user) => {
      updateIsSignedIn(!!user);
    });

    return () => {
      unregisterAuthObserver();
    };
  }, []);

  return isSignedIn;
}
