import mixpanel from 'mixpanel-browser';
import ternAppDetails from './utils/tern-app-details';

export default function Info() {
  // opening links with anchor supported in version 1
  const linkStyle = {
    fontWeight:
      ternAppDetails !== null && !ternAppDetails.version ? 'normal' : 'bold',
  };

  return (
    <>
      <h3>How is the CO2 calculated?</h3>
      <p>
        The British government&apos;s Department for Business, Energy &
        Industrial Strategy have done
        <span> </span>
        <a
          style={linkStyle}
          target="_blank"
          rel="noreferrer"
          href="https://www.gov.uk/government/publications/greenhouse-gas-reporting-conversion-factors-2017#tern"
          onClick={() => {
            mixpanel.track('Government CO2 Study Click');
          }}
        >
          studies
        </a>
        <span> </span>
        on how much CO2 an average car on average fuel emits into the atmosphere
        for every kilometer driven. The extensive studies tell us a very
        important number, that for every kilometer driven 0.18109kg of CO2 is
        emitted, it&apos;s this number Tern uses to calculate CO2 savings when a
        user walks, cycles or runs to work.
      </p>
      <h3>How are trees calculated?</h3>
      <p>
        Planting trees are an effective way to take CO2 out the atmosphere,
        knowing that an average Ecosia
        <span> </span>
        <a
          style={linkStyle}
          target="_blank"
          rel="noreferrer"
          href="https://ecosia.zendesk.com/hc/en-us/articles/201531072-How-does-Ecosia-neutralize-a-search-s-CO2-emissions-#tern"
          onClick={() => {
            mixpanel.track('Ecosia Tree Study Click');
          }}
        >
          tree
        </a>
        <span> </span>
        absorbs 50kg of CO2 over a 15 year lifetime we know how much a CO2 is
        absorbed over a certain timeframe per tree. For example if it shows a
        user has saved 14.85kg of CO2 on a weekly leaderboard, we know 232 trees
        would be needed from the start of the week until the end to absorb the
        same amount.
      </p>
    </>
  );
}
