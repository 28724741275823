import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import styles from './WorkplaceFeatures.module.css';
import phoneScreenshots from './phone-screenshots.png';
import adminDashboard from './admin-dashboard.gif';
import challengesRewards from './challenges-rewards.png';
import imageOfBondiCommute from './image-of-bondi-commute.png';

const copy = [
  [
    `Easy access to important insight

The Tern platform gives employers real time data from every sustainable commute to your office or site. 

See how many employees are commuting sustainably, and measure the positive impact of your efforts to increase green commutes.

View and download reports to share with key ESG stakeholders.`,
    adminDashboard,
  ],

  [
    `Engage with all your employees

Tern tracks all types of sustainable commute. Walking, cycling, running and mixed modes like walk - bus - walk. 

Tern can help as many employees as possible to record their sustainable commute, and share this with colleagues.

And just like a step counter Tern can automatically track a commute. Which makes it easy to use, and maximises engagement and insight for employers.`,
    phoneScreenshots,
  ],

  [
    `Reward & incentivise sustainable choices

With Tern employers can develop innovative ways to incentivise and reward employees for their sustainable commutes. 

As well as gamifying commutes with leaderboards, employers using Tern can run challenges or offer rewards which increase engagement. 

Offer time in lieu for every commute. A monthly challenge for the most commutes logged. Some friendly competition between teams or offices. You choose what works best for your organisation.`,
    challengesRewards,
  ],

  [
    `We've got data security sorted

Our infrastructure is hosted on a world leading cloud provider that is ISO 27001 certified and GDPR compliant.

For commute data, we let users control the amount of data they would like to share, accomdating everyones requirements.`,
    imageOfBondiCommute,
  ],
];

function WorkplaceFeatures() {
  return (
    <div>
      {copy.map(([string, imageUrl], index) => {
        const [title, ...paragraphs] = string.split('\n\n');
        const imageIsLeft = index % 2 === 0;
        return (
          <div className={styles.contentBlock} key={Math.random()}>
            <div className={styles.copyChild}>
              <h2 className={styles.title}>{title}</h2>
              {paragraphs.map((para) => (
                <p>{para}</p>
              ))}
            </div>
            <img
              className={[styles.imageChild, imageIsLeft && styles.imageIsLeft]
                .filter(Boolean)
                .join(' ')}
              src={imageUrl}
              alt="Promo"
            />
          </div>
        );
      })}

      <h2 className={styles.title}>Ready to go?</h2>
      <p>
        Any employer can offer Tern, anywhere. It&apos;s quick to sign-up so you
        can be supporting your sustainability goals today.
      </p>
      <Button
        variant="outlined"
        onClick={() => {
          mixpanel.track('Admin Register Click', {
            source: 'Workplace Features',
          });
        }}
        component={RouterLink}
        to="/admin/signup"
        className={styles.adminRegisterButton}
        size="large"
      >
        Get started for free
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          mixpanel.track('Request Demo Click', {
            source: 'Workplace Features',
          });
        }}
        component={RouterLink}
        to="/request-a-demo"
        className={styles.adminRegisterButton}
        size="large"
      >
        Get a demo
      </Button>

      <h2 className={styles.title}>
        Improve your ESG insight and transparency
      </h2>
      <p>
        Without clear visibility into their ESG practices, organisations face
        growing scrutiny and potential backlash from investors, customers,
        employees, and regulators.
      </p>

      <p>
        Investors representing trillions are demanding ESG data, while customer
        loyalty increasingly hinges on responsible practices. Employees are
        flocking to companies committed to social good, and governments are
        tightening regulations on environmental impact.
      </p>

      <p>
        Organisations embracing ESG transparency unlock unparalleled
        opportunities to attract and retain talent, secure investments, build
        customer trust, and navigate evolving regulations with confidence.
      </p>

      <p>
        In today&apos;s dynamic landscape, ESG transparency is no longer a
        luxury, but a strategic imperative. It&apos;s time to shine a light on
        your social and environmental impact and unlock the full potential of
        responsible business practices.
      </p>
    </div>
  );
}

export default WorkplaceFeatures;
