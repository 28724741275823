import { useQuery, gql } from '@apollo/client';
import moment from 'moment';
import styles from './UserDebugTracking.module.css';

const statsQuery = gql`
  query UsersDebugQuery($userId: ID!) {
    user(id: $userId) {
      id
      lastLocationReceived
      totalLocationsReceived
      activityTypesReceived
      hasFirebaseCloudMessagingToken
      recentLocationTimes
    }
  }
`;

export default function UserDebugTracking({ userId }) {
  const { data, loading, error } = useQuery(statsQuery, {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  if (loading) return <p>loading...</p>;
  if (error) return <p>error loading debug data</p>;

  const {
    lastLocationReceived,
    totalLocationsReceived,
    activityTypesReceived,
    hasFirebaseCloudMessagingToken,
  } = data.user;

  if (!lastLocationReceived)
    return (
      <>
        <hr />
        <code>No location received</code>
      </>
    );

  const parsedLocation = JSON.parse(lastLocationReceived);

  const lastComment = moment(parsedLocation.recordedAt);

  return (
    <code className={styles.codeHolder}>
      <hr />
      <p>
        {`Last location ${lastComment.fromNow()} on ${lastComment.format(
          'dddd, MMMM Do YYYY, h:mm:ssa'
        )}.`}
      </p>
      <p>{`Total locations ${totalLocationsReceived}.`}</p>
      <p>{`Activity types ${activityTypesReceived.join(', ')}.`}</p>
      <p>{`Firebase Cloud Messaging token: ${hasFirebaseCloudMessagingToken}`}</p>
      <table className={styles.table}>
        {Object.entries(parsedLocation).map(([key, value]) => (
          <tr key={key}>
            <td>{key}</td>
            <td>{value}</td>
          </tr>
        ))}
      </table>
      <table className={styles.table}>
        {data.user.recentLocationTimes.map((time) => {
          const formatter = new Intl.DateTimeFormat('en-US', {
            dateStyle: 'full',
            timeStyle: 'short',
          });

          return (
            <tr>
              <td key={time}>{`${formatter.format(new Date(time))}`}</td>
              <td>{moment(time).fromNow()}</td>
            </tr>
          );
        })}
      </table>
    </code>
  );
}
