import Loader from 'react-loader-spinner';
import styles from './Loading.module.css';

export default function Loading({ fullScreen = true }) {
  return (
    <div
      className={
        fullScreen ? styles.loadingContainerFullScreen : styles.loadingContainer
      }
    >
      <Loader type="TailSpin" color="#4caf50" height={50} width={50} />
    </div>
  );
}
