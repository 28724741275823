/* eslint-disable no-new */
import { useEffect, useRef } from 'react';
import styles from './OfficeMap.module.css';
import createMap from './utils/google-maps';

function OfficeMap({ latLng }) {
  const mapRef = useRef(null);

  useEffect(() => {
    const center = {
      lat: latLng.latitude,
      lng: latLng.longitude,
    };

    const map = createMap(mapRef.current, {
      zoom: 14,
      center,
    });

    new window.google.maps.Marker({ position: center, map });
  }, [latLng]);

  return <div ref={mapRef} className={styles.mapContainer} />;
}

export default OfficeMap;
