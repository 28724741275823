import React, { useEffect, useState } from 'react';
import { TextField, Typography, Grid, Autocomplete } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import styles from './CreateNewOffice.module.css';

const autocompleteService =
  window.google && new window.google.maps.places.AutocompleteService();
const fetch = throttle((request, callback) => {
  autocompleteService.getPlacePredictions(request, callback);
}, 200);

const placesService =
  window.google &&
  new window.google.maps.places.PlacesService(document.createElement('div'));

export default function CreateNewOffice({ office, updateOffice }) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(office ? [office] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (office) {
          newOptions = [office];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        newOptions = newOptions.filter(
          (suggest) =>
            suggest.types &&
            // https://developers.google.com/places/supported_types#table3
            !suggest.types.some((type) =>
              [
                'locality',
                'sublocality',
                'postal_code',
                'country',
                'administrative_area_level_1',
                'administrative_area_level_2',
                'administrative_area_level_3',

                // additions to table3
                'route',
              ].includes(type)
            )
        );

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [office, inputValue]);

  return (
    <Autocomplete
      id="create-new-office"
      className={styles.autocomplete}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      filterSelectedOptions
      value={office}
      autoHighlight
      onChange={(event, newValue) => {
        if (!newValue) {
          updateOffice(null);
          return;
        }

        placesService.getDetails(
          { placeId: newValue.place_id },
          (placeResult, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              // eslint-disable-next-line no-param-reassign
              newValue.placeResult = placeResult;

              setOptions(newValue ? [newValue, ...options] : options);
              updateOffice(newValue);
            }
          }
        );
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      noOptionsText="Office address not found"
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          inputProps={{ ...params.inputProps, required: true }}
          label="Enter office address"
          variant="standard"
          error={inputError}
          onBlur={() => {
            if (!office) setInputError(true);
          }}
          onInvalid={() => {
            setInputError(true);
          }}
          helperText={
            inputError
              ? 'We need an office to link to your profile.'
              : 'Please enter the destination address of your commute, you can search by either street address or company name.'
          }
        />
      )}
      onOpen={() => {
        setInputError(false);
      }}
      freeSolo
      clearOnBlur
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props}>
            <Grid data-testid="new-office-option" container alignItems="center">
              <Grid item>
                <LocationOnIcon className={styles.locationIcon} />
              </Grid>
              <Grid item xs>
                {parts.map((part) => (
                  <span
                    key={Math.random()}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
