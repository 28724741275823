import { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@mui/material';
import { BiRefresh as SwitchIcon } from 'react-icons/bi';
import { MobileTimePicker } from '@mui/x-date-pickers';
import ActionSheetContext from './context/action-sheet';
import ternAppDetails from './utils/tern-app-details';
import Loading from './Loading';
import LearnMoreTracking from './LearnMoreTracking';
import styles from './Schedule.module.css';

const OLD_ALWAYS_TIME_LIST = ['00:01-00:00', '00:01-23:59'];
const ALWAYS_TIME = '00:00-23:59';
export const DEFAULT_SCHEDULE = new Array(7)
  .fill(null)
  .map((_, index) => `${index + 1} ${ALWAYS_TIME}`);

const ADVANCED_DEFAULT_SCHEDULE = new Array(5)
  .fill(null)
  .map((_, index) => `${index + 2} 07:00-19:00`);

const days = [
  ['Monday', 'M', 2],
  ['Tuesday', 'Tu', 3],
  ['Wednesday', 'W', 4],
  ['Thursday', 'Th', 5],
  ['Friday', 'F', 6],
  ['Saturday', 'Sa', 7],
  ['Sunday', 'Su', 1],
];

function Schedule({ userId }) {
  const [scheduleArray, setScheduleArray] = useState(null);
  const [advancedSchedule, setAdvancedSchedule] = useState(false);

  const hasCheckedIfAdvanced = useRef(false);

  const setActionSheetContents = useContext(ActionSheetContext);

  useEffect(() => {
    if (!scheduleArray || hasCheckedIfAdvanced.current) return;

    if (
      scheduleArray.some(
        ({ radioValue }) => !['always', 'never'].includes(radioValue)
      )
    ) {
      setAdvancedSchedule(true);
    }

    hasCheckedIfAdvanced.current = true;
  }, [scheduleArray, setAdvancedSchedule]);

  useEffect(() => {
    window.Pedal.updateSchedule = (stringifiedArray) => {
      let nativeArray;
      if (stringifiedArray) {
        nativeArray = JSON.parse(stringifiedArray);
      } else {
        nativeArray = DEFAULT_SCHEDULE;
      }

      const state = days.map(
        ([dayOfWeekLong, dayOfWeekShort, dayOfWeekNumber]) => {
          const dayString = nativeArray.find((str) =>
            str.startsWith(dayOfWeekNumber)
          );
          const timeString = dayString ? dayString.slice(2).trim() : null;

          const radioValue =
            timeString === null
              ? 'never'
              : [...OLD_ALWAYS_TIME_LIST, ALWAYS_TIME].includes(timeString)
              ? 'always'
              : 'custom';

          let fromHours = 7;
          let fromMinutes = 0;
          let toHours = 20;
          let toMinutes = 0;
          if (dayString) {
            const [fromTime, toTime] = timeString.split('-');
            [fromHours, fromMinutes] = fromTime.split(':');
            [toHours, toMinutes] = toTime.split(':');
          }

          return {
            dayOfWeekLong,
            dayOfWeekShort,
            dayOfWeekNumber,
            radioValue,
            fromTime: moment({ hours: fromHours, minutes: fromMinutes }),
            toTime: moment({ hours: toHours, minutes: toMinutes }),
          };
        }
      );

      setScheduleArray(state);
    };

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ whatsMySchedule: true })
      );
    }
  }, []);

  useEffect(() => {
    if (!scheduleArray || !userId) {
      return;
    }

    const arrayTimeStrings = scheduleArray
      .map(({ dayOfWeekNumber, radioValue, fromTime, toTime }) => {
        if (radioValue === 'always') {
          return `${dayOfWeekNumber} ${ALWAYS_TIME}`;
        }
        if (radioValue === 'never') {
          return null;
        }
        return `${dayOfWeekNumber} ${fromTime.format('HH:mm')}-${toTime.format(
          'HH:mm'
        )}`;
      })
      .filter(Boolean);

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        scheduleArray: arrayTimeStrings,
        userId,
      })
    );
  }, [userId, scheduleArray]);

  if (!scheduleArray) {
    return <Loading fullScreen={false} />;
  }

  const basicSelection = (
    <div className={styles.basicContainer}>
      {scheduleArray.map(({ radioValue, dayOfWeekShort }, index) => {
        const checked = radioValue === 'always';

        return (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label
            key={dayOfWeekShort}
            className={[
              styles.ballInputLabel,
              checked && styles.ballInputLabelChecked,
            ]
              .filter(Boolean)
              .join(' ')}
          >
            <input
              className={styles.ballInputCheckbox}
              type="checkbox"
              checked={checked}
              onChange={(event) => {
                const newArray = scheduleArray.slice();
                newArray[index].radioValue = event.target.checked
                  ? 'always'
                  : 'never';
                setScheduleArray(newArray);
              }}
            />
            <span>{dayOfWeekShort}</span>
          </label>
        );
      })}
    </div>
  );

  const advancedSelection = scheduleArray.map(
    ({ radioValue, fromTime, toTime, dayOfWeekLong }, index) => {
      return (
        <div key={dayOfWeekLong} className={styles.fieldset}>
          <FormControl variant="standard" component="fieldset">
            <FormLabel component="legend">{dayOfWeekLong}</FormLabel>
            <RadioGroup
              aria-label={dayOfWeekLong}
              name={dayOfWeekLong}
              value={radioValue}
              onChange={(event) => {
                const newArray = scheduleArray.slice();
                newArray[index].radioValue = event.target.value;
                setScheduleArray(newArray);
              }}
              row
            >
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Start/end"
                labelPlacement="start"
              />
              <FormControlLabel
                value="always"
                control={<Radio />}
                label="All day"
                labelPlacement="start"
              />
              <FormControlLabel
                value="never"
                control={<Radio />}
                label="Off"
                labelPlacement="start"
              />
            </RadioGroup>
            {radioValue === 'custom' && (
              <div className={styles.timePickerHolder}>
                <MobileTimePicker
                  label="Start time"
                  value={fromTime}
                  onChange={(newFromTime) => {
                    const newArray = scheduleArray.slice();
                    newArray[index].fromTime = newFromTime;
                    setScheduleArray(newArray);
                  }}
                />
                <MobileTimePicker
                  label="End time"
                  value={toTime}
                  onChange={(newToTime) => {
                    const newArray = scheduleArray.slice();
                    newArray[index].toTime = newToTime;
                    setScheduleArray(newArray);
                  }}
                />
              </div>
            )}
          </FormControl>
        </div>
      );
    }
  );

  return (
    <>
      <p>
        Select the days you want the app to run in the background and
        automatically record your commute.
      </p>
      {!advancedSchedule && <p>Which days do you work?</p>}
      {advancedSchedule && (
        <>
          <p>What times should the app monitor your activity?</p>
          <FormHelperText>
            This configuration lets your choose specific start and end times for
            each day of the week you want Tern to monitor your movements for
            active commutes.
          </FormHelperText>
        </>
      )}
      {advancedSchedule ? advancedSelection : basicSelection}
      {advancedSchedule ? (
        <button
          className={styles.switchType}
          type="button"
          onClick={() => {
            // updates state to default schedule then posts the new schedule back
            window.Pedal.updateSchedule();
            setAdvancedSchedule(false);
          }}
        >
          <SwitchIcon className={styles.switchIcon} /> switch to basic
          configuration
        </button>
      ) : (
        <button
          className={styles.switchType}
          type="button"
          onClick={() => {
            // updates state then posts the new schedule back
            window.Pedal.updateSchedule(
              JSON.stringify(ADVANCED_DEFAULT_SCHEDULE)
            );
            setAdvancedSchedule(true);
          }}
        >
          <SwitchIcon className={styles.switchIcon} /> switch to advanced
          configuration
        </button>
      )}
      <p>
        Make sure you have have set the <i>Location</i> permission to{' '}
        <i>Always</i>, and enabled{' '}
        <i>
          {ternAppDetails?.isIOS ? 'Motion & Fitness' : 'Physical activity'}
        </i>{' '}
        for Tern to work.
      </p>
      <button
        className={styles.learnMore}
        onClick={() => {
          setActionSheetContents(<LearnMoreTracking />);
        }}
        type="button"
      >
        Learn more about commute tracking
      </button>
    </>
  );
}

export default Schedule;
