import { Link, NavLink } from 'react-router-dom';
import { List, ListItem, Drawer } from '@mui/material';
import { useState } from 'react';
import { getAuth } from 'firebase/auth';
import mixpanel from 'mixpanel-browser';
import styles from './Header.module.css';
import GetStartedModel from './GetStartedModel';

export default function Header({ isAdmin }) {
  const [isDrawOpen, setIsDrawOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const homeNavLinks = [
    [
      '/admin/signup',
      'Get started free',
      {
        className: styles.ctaLink,
        onClick: (e) => {
          e.preventDefault();
          mixpanel.track('Get Started Free Click', { location: 'Header' });
          setIsDialogOpen(true);
        },
      },
    ],
    ['/businesses', 'Features'],
    ['/pricing-and-rewards', 'Pricing'],
    ['/request-a-demo', 'Get a demo'],
    ['/admin/signin', 'Admin', { target: '_blank', rel: 'noreferrer' }],
    ['/about-us', 'About Us'],
  ];
  const adminNavLinks = [
    ['/', 'Stats'],
    ['/users', 'Users'],
    ['/rewards', 'Rewards'],
    ['/assets', 'Assets'],
  ];

  const navLinks = (isAdmin ? adminNavLinks : homeNavLinks).map(
    ([to, text, attributes]) => {
      const isOutbound = to.includes('https');

      if (isOutbound) {
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <a className={styles.link} href={to} {...attributes}>
            {text}
          </a>
        );
      }

      return (
        <NavLink
          onClick={() => {
            setIsDrawOpen(false);
          }}
          exact
          className={styles.link}
          activeClassName={styles.activeLink}
          to={to}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attributes}
        >
          {text}
        </NavLink>
      );
    }
  );

  if (isAdmin) {
    navLinks.push(
      <button
        className={styles.link}
        type="button"
        onClick={async (event) => {
          event.preventDefault();
          await getAuth().signOut();
          window.location = '/';
        }}
      >
        Sign out
      </button>
    );
  }

  return (
    <div
      className={[
        styles.header,
        isAdmin ? styles.themeAdmin : styles.themeWebsite,
      ].join(' ')}
    >
      <div>
        <Link to="/" className={styles.heading}>
          Tern
        </Link>
        {isAdmin && <span className={styles.adminSubcopy}>Admin</span>}
      </div>

      <ul className={styles.ul}>
        {navLinks.map((link) => {
          return (
            <li key={Math.random()} className={styles.li}>
              {link}
            </li>
          );
        })}
      </ul>

      <button
        className={styles.menu}
        onClick={() => {
          setIsDrawOpen(true);
        }}
        type="button"
      >
        Menu
      </button>

      <Drawer
        anchor="right"
        open={isDrawOpen}
        onClose={() => {
          setIsDrawOpen(false);
        }}
      >
        <List classes={{ root: styles.list }}>
          {navLinks.map((link) => {
            return <ListItem key={Math.random()}>{link}</ListItem>;
          })}
        </List>
      </Drawer>

      <GetStartedModel
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </div>
  );
}
