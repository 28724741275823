import { TextField, Button, Alert } from '@mui/material';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import styles from './SubscribeToNewsletter.module.css';

const SUBSCRIBE_QUERY = gql`
  mutation SubscribeToNewsletter($input: SubscribeToNewsletterInput!) {
    subscribeToNewsletter(input: $input)
  }
`;

export default function SubscribeToNewsletter() {
  const [email, setEmail] = useState('');
  const [submit, { data, loading, error }] = useMutation(SUBSCRIBE_QUERY);

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();

        try {
          await submit({
            variables: {
              input: {
                email,
              },
            },
          });
        } catch (e) {
          console.log('Error subscribing to newsletter');
        }

        setEmail('');
      }}
    >
      <div className={styles.flex}>
        <span>Subscribe to our newsletter</span>
        <TextField
          fullWidth
          variant="filled"
          color="black"
          className={styles.emailInput}
          inputProps={{ required: true }}
          type="email"
          label="Email address"
          id="newsetter-email"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          disabled={loading}
        />
        {error && <Alert severity="error">Sorry, something went wrong</Alert>}
        <Button type="submit" variant="outlined" color="black">
          {loading
            ? 'Submitting...'
            : data?.subscribeToNewsletter
            ? 'Subscribed!'
            : 'Subscribe'}
        </Button>
      </div>
    </form>
  );
}
