import mixpanel from 'mixpanel-browser';
import styles from './AppLinks.module.css';
import GooglePlay from './GooglePlay';
import AppleAppStore from './AppleAppStore';
import { ANDROID_LINK, IOS_LINK } from './constants';

export default function AppLinks({
  className,
  fillColor = 'white',
  trackingSource,
}) {
  return (
    <div className={className}>
      {' '}
      <a
        className={styles.appLogo}
        href={IOS_LINK}
        onClick={() => {
          window.gtag('event', 'app store click', {
            event_label: 'ios',
          });
          mixpanel.track(
            'App Store Click',
            {
              platform: 'iOS',
              source: trackingSource,
            },
            { transport: 'sendBeacon', send_immediately: true }
          );
        }}
        aria-label="Go to Apple App Store"
      >
        <AppleAppStore fillColor={fillColor} />
      </a>
      <a
        className={styles.appLogo}
        href={ANDROID_LINK}
        onClick={() => {
          window.gtag('event', 'app store click', {
            event_label: 'android',
          });
          console.log('clicked', trackingSource);
          mixpanel.track(
            'App Store Click',
            {
              platform: 'Android',
              source: trackingSource,
            },
            // MP are shit, must use send_immediately with sendBeacon
            { transport: 'sendBeacon', send_immediately: true }
          );
        }}
        aria-label="Go to Google Play app store"
      >
        <GooglePlay fillColor={fillColor} />
      </a>
    </div>
  );
}
