import { useQuery, gql } from '@apollo/client';
import Card from './Card';
import CommuteCard from './CommuteCard';

export default function CommuteCards({ userId, isUser, ids }) {
  const { loading, error, data } = useQuery(
    gql`
      query RefetchCommutes($ids: [ID!]!) {
        nodes(ids: $ids) {
          __typename
          ... on Commute {
            id
            activityType
            totalLocations
            commuteCertainty {
              walking
              onFoot
              onBicycle
              running
              inVehicle
              still
              unknown
            }
            distance
            CO2
            duration
            pace
            averageSpeed
            toWork
            fromWork
            dateOfCommute
            locations {
              latLng {
                latitude
                longitude
              }
              recordedAt
              activityType
            }
            isMultiMode
          }

          ... on ManualCommute {
            id
            activityType
            distance
            CO2
            toWork
            fromWork
            dateOfCommute
            importedFrom
          }
        }
      }
    `,
    { variables: { ids } }
  );

  if (loading) return ids.map((id) => <Card loading key={id} />);

  if (error) {
    return (
      <p>
        {`Error: ${
          process.env.NODE_ENV === 'production'
            ? error.message
            : JSON.stringify(error)
        }`}
      </p>
    );
  }

  return data.nodes.map((commute, index) => {
    return (
      <CommuteCard
        key={commute.id}
        commute={commute}
        userId={userId}
        isUser={isUser}
        isLastCommuteCard={index === data.nodes.length - 1}
      />
    );
  });
}
